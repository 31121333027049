<template>
  <div>
    <div class="app_update_button_container">
      <button class="update-alert" v-if="updateAvailable" @click="refreshApp">
        <md-icon>update</md-icon>
        New update available! Click to update.
      </button>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      refreshing: false,
      registration: null,
      updateAvailable: false,
      updateExists: false,
    };
  },
  created() {
    document.addEventListener("swUpdate", this.showUIUpdate, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        // To prevent infinite loop refresh
        if (this.refreshing) {
          return;
        }

        this.refreshing = true;
        window.location.reload();
      });
    }
  },
  methods: {
    showUIUpdate(e) {
      console.log(e)
      console.log('UI has been activated and should show alert')
      this.registration = e.detail;
      this.updateAvailable = true;
      Swal.fire({
        title: "New Update!",
        text:
          "New update available! Click Update to refresh. Remember to save any changes you made before continuing",
        type: "info",
        showCancelButton: true,
        confirmButtonText: "UPDATE",
        cancelButtonText: "CANCEL",
      }).then((result) => {
        if (result.value) {
          this.refreshApp();
        } else if (result.isDismissed) {
          if (this.updateExists)
            setTimeout(() => {
              this.showUIUpdate(e);
            }, 300000);
        }
      });
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
  },
};
</script>
<style scoped>
.app_update_button_container {
  width: 100%;
  display: flex !important;
  justify-content: flex-end !important;
}
.update-alert {
  width: 100%;
  background-color: #25bf6e;
  border: none;
  padding: 10px;
  color: white;
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  font-family: "Roboto";
  z-index: 10;
  justify-content: center;
}

.update-alert img {
  height: 30px;
}

.update-alert p {
  margin: 0 0 0 20px;
  font-size: 18px;
  font-family: AzoSans;
}
</style>

<style src="../public/css/main.css"></style>
