<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card class="light">
        <md-card-content>
          <md-field class="search-bar">
            <div class="search-options">
              <label style="color: black !important" for="">Search For: </label>
              <v-select
                class="md-input"
                :options="searchOptions"
                v-model="selectedSearchOption"
                label="name"
                :clearable="false"
                :appendToBody="true"
              >
              </v-select>
            </div>
            <div class="search-input">
              <md-input
                type="search"
                clearable
                placeholder="Search records here"
                v-model="filterBy.search"
                @input="upperCaseText"
                @keypress="evokeFilter($event)"
              ></md-input>
            </div>
          </md-field>
          <div class="filters">
            <div class="filter">
              <p>Start From:</p>
              <md-datepicker
                class="datepicker"
                @input="validateDateRange('startDate')"
                v-model="filterBy.startDate"
              />
            </div>
            <div class="filter">
              <p>End To:</p>
              <md-datepicker
                class="datepicker"
                @input="validateDateRange('endDate')"
                v-model="filterBy.endDate"
              />
            </div>

            <!-- <div class="filter">
              <p>Filter By Client</p>
              <v-select
                :appendToBody="true"
                :deselectFromDropdown="true"
                v-model="filterBy.companies"
                :options="companies"
                label="name"
                :reduce="(p) => p.id"
                :closeOnSelect="false"
              >
              </v-select>
            </div> -->

            <div class="filter">
              <p>Filter By Customer</p>
              <v-select
                :appendToBody="true"
                :deselectFromDropdown="true"
                v-model="filterBy.customer"
                :options="customers"
                label="name"
                :reduce="(p) => p.id"
                :closeOnSelect="false"
              >
              </v-select>
            </div>

            <div class="filter">
              <p>Filter By Style</p>
              <v-select
                :appendToBody="true"
                :deselectFromDropdown="true"
                v-model="filterBy.style"
                :options="jewelry_type"
                :closeOnSelect="false"
              >
              </v-select>
            </div>

            <div
              class="flex flex-row justify-content-between"
              style="width: 300px; bottom: 5px; position: absolute; right: 0"
            >
              <md-button
                style="background-color: green !important"
                class="text-btn"
                @click="handleFilter"
                >Filters</md-button
              >
              <md-button
                style="background-color: red !important"
                class="text-btn"
                @click="clearFilters"
                >Clear Filters</md-button
              >
            </div>
          </div>
        </md-card-content>
      </md-card>

      <md-card>
        <md-card-content>
          <div class="btn-row stats_holder">
            <md-button class="md-primary md-round md-small" @click="exportCSV"
              >Export</md-button
            >

            <md-button
              class="md-primary md-round md-small"
              @click="showPopup = true"
              >Add New Shipping Log</md-button
            >
          </div>
          <md-table
            md-fixed-header
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
            id="shipping-table"
          >
            <md-table-toolbar :style="{ marginBottom: '20px' }">
              <md-field>
                <label for="shipping">Per page</label>
                <md-select
                  @change="fetchData('per-page')"
                  v-model="pagination.perPage"
                  name="shipping"
                >
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                    >{{ item }}</md-option
                  >
                </md-select>
              </md-field>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Intake" md-sort-by="created">{{
                item.created | format_date_time
              }}</md-table-cell>

              <md-table-cell md-label="Shipped On" md-sort-by="ShippedOn">{{
                item.ShippedOn | format_date_time
              }}</md-table-cell>

              <md-table-cell md-label="Client">{{
                item.Company.name
              }}</md-table-cell>

              <md-table-cell md-label="Customer">{{
                item.Customer
              }}</md-table-cell>

              <md-table-cell md-label="Order Number" md-sort-by="OrderNum"
                >{{ item.OrderNum }} - {{ item.LineNum }}</md-table-cell
              >

              <md-table-cell
                md-label="Purchase Order"
                md-sort-by="PurchaseOrder"
                >{{ item.PurchaseOrder }}</md-table-cell
              >

              <md-table-cell md-label="Item Type" md-sort-by="JewelryType">{{
                item.JewelryType
              }}</md-table-cell>

              <md-table-cell md-label="Client SKU" md-sort-by="ClientSKU">{{
                item.ClientSKU || "N/A"
              }}</md-table-cell>

              <md-table-cell md-label="Certificate #" md-sort-by="CertNum">{{
                item.CertNum || item.StockNum || "N/A"
              }}</md-table-cell>

              <md-table-cell md-label="Processed By">
                {{ item.ProcessedBy }}
              </md-table-cell>
              <md-table-cell> </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class>
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <Pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
            @update-table="fetchData"
            :allowClick="Boolean(tableData.length + searchedData.length)"
          ></Pagination>
        </md-card-actions>
      </md-card>
    </div>

    <!-- Modal for Adding New Shipping Log -->
    <modal v-if="showPopup" @close="closeModal">
      <template slot="header">
        <h3>New Shipping Log</h3>
      </template>
      <template slot="body">
        <NewShippingLog @close="closeModal"></NewShippingLog>
      </template>
    </modal>
  </div>
</template>
  
  <script>
import { Pagination, Modal } from "@/components";
import { db, batch } from "@/config/firebaseInit";
import Swal from "sweetalert2";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import moment from "moment";

import NewShippingLog from "./components/NewShippingLog.vue";

export default {
  components: {
    Pagination,
    Modal,
    NewShippingLog,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData.slice(this.from, this.to);
      }
      return result;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableSize.shipped_items_total;
    },
  },
  data() {
    return {
      showPopup: false,
      showPreviewModal: false,
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 100, 250, 500],
        total: 0,
      },
      selectedSearchOption: {
        name: "Order #",
        key: "OrderNum",
      },
      searchOptions: [
        {
          name: "Order #",
          key: "OrderNum",
        },
        {
          name: "Cert #",
          key: "CertNum",
        },
        {
          name: "Item #",
          key: "ItemNum",
        },
        {
          name: "PO #",
          key: "PurchaseOrder",
        },
        {
          name: "Tracking #",
          key: "TrackingNumber",
        },
      ],
      tableData: [],
      unfilteredItems: [],
      searchedData: [],
      companies: [],
      customers: [],
      originPath: null,
      selectedItem: {},
      tableSize: 0,
      searchQuery: "",
      searchVal: "",
      filterBy: {
        style: "",
        templates: "",
        companies: "",
        customer: "",
        startDate: null,
        endDate: null,
        search: "",
      },
      showItemsWithVideo: false,
      showItemsWithoutVideo: false,
      jewelry_type: [
        "Pendant",
        "Necklace",
        "Bracelet",
        "Ring",
        "Rings",
        "Earring",
        "Watch",
        "Solitaire",
        "Brooch",
        "Studs",
        "Watch Bezel",
        "Wedding Set",
      ],

      fuseSearch: null,
      certificateRef: null,
    };
  },
  firestore() {
    return {
      // companies: db.collection("companies"),
      tableSize: db.doc("analytics/jewelry"),
    };
  },

  async created() {
    this.certificateRef = db
      .collection("jewelry")
      .where("HasBeenShipped", "==", true);
    this.fetchData("start");
    this.originPath = window.location.origin;
  },

  methods: {
    closeModal() {
      this.selectedItem = {};
      this.showPopup = false;
    },
    handleModal(item) {
      this.selectedItem = item;
      this.showPreviewModal = true;
    },
    upperCaseText() {
      const matched = this.filterBy.search.match(/\S+/g);
      console.log(matched);
      this.filterBy.search = matched ? matched.join("") : this.filterBy.search;
    },
    evokeFilter(e) {
      if (e.keyCode == 13) {
        this.handleFilter();
      }
    },
    async handleFilter() {
      let ref = db.collection("jewelry").where("HasBeenShipped", "==", true);
      let timeFilter = false;
      if (
        !this.filterBy.search &&
        !this.filterBy.style &&
        !this.filterBy.companies &&
        !this.filterBy.customer &&
        !this.filterBy.startDate &&
        !this.filterBy.endDate
      ) {
        return false;
      }

      if (this.filterBy.startDate && this.filterBy.endDate) {
        timeFilter = true;
        let startDate = new Date(this.filterBy.startDate).getTime();
        let endDate = new Date(this.filterBy.endDate).setHours(24, 0, 0, 0);
        ref = ref.orderBy("ShippedOn", "asc").startAt(startDate).endAt(endDate);
      }

      for (const key in this.filterBy) {
        if (Object.hasOwnProperty.call(this.filterBy, key)) {
          if (key != "startDate" && key != "endDate") {
            let input = this.filterBy[key];
            if (input) {
              if (key == "search" && input.includes("-")) {
                input = input.split("-");
                ref = db
                  .collection("jewelry")
                  .where("HasBeenShipped", "==", true)
                  .where("OrderNum", "==", input[0])
                  .where("LineNum", "==", input[1]);
              } else {
                ref = db
                  .collection("jewelry")
                  .where("HasBeenShipped", "==", true)
                  .where(this.selectedSearchOption.key, "==", input);
              }
            }
          }
        }
      }

      let container = timeFilter
        ? await ref.get()
        : await ref.orderBy("ShippedOn", "desc").get();

      if (!container.docs.length) {
        return Swal.fire("No Data From Filter, Try Adding From Diamond Track");
      }
      this.searchedData = container.docs.map((d) =>
        Object.assign({ id: d.id }, d.data())
      );
    },
    handleSearch() {
      if (this.searchVal) {
        const query = db
          .collection("jewelry")
          .where("HasBeenShipped", "==", false)
          .where(this.selectedSearchOption.key, "==", this.searchVal);
        this.$bind("searchedData", query).then((data) => {
          if (data.length) {
            this.showSelectableTable = true;
            this.searchVal = null;
            this.selectedItems = [];
          } else {
            // Swal.fire("No Results", "No Results Found", "info");
            this.searchVal = null;
          }
        });
      }
    },
    resetTable() {
      this.pagination.currentPage = 1;
      this.fetchData("start");
    },
    async fetchData(action) {
      if (this.searchedData.length) return false;
      if (action == "start") {
        try {
          const querySnapshot = this.certificateRef
            .orderBy("ShippedOn", "desc") // Change to your sorting criteria
            .endAt(0)
            .limit(this.pagination.perPage);

          this.$bind("certificates", querySnapshot).then((docs) => {
            this.tableData = docs;
          });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else if (action == "next") {
        this.fetchNextData();
      } else if (action == "prev") {
        this.fetchPreviousData();
      } else if (action == "per-page") {
        let firstDoc = this.tableData[0];
        firstDoc = await this.certificateRef.doc(firstDoc.id).get();
        const querySnapshot = await this.certificateRef
          .orderBy("created", "desc") // Change to your sorting criteria
          .startAt(firstDoc)
          .limit(this.pagination.perPage)
          .get();

        this.tableData = querySnapshot.docs.map((doc) =>
          Object.assign({ id: doc.id }, doc.data())
        );
      }
    },
    async fetchNextData() {
      let lastDoc = this.tableData[this.tableData.length - 1];
      lastDoc = await this.certificateRef.doc(lastDoc.id).get();
      this.tableData = [];
      try {
        const querySnapshot = await this.certificateRef
          // .orderBy("created")
          .orderBy("created", "desc") // Change to your sorting criteria
          .startAfter(lastDoc)
          .limit(this.pagination.perPage)
          .get();

        this.tableData = querySnapshot.docs.map((doc) =>
          Object.assign({ id: doc.id }, doc.data())
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async fetchPreviousData() {
      let firstDoc = this.tableData[0];
      firstDoc = await this.certificateRef.doc(firstDoc.id).get();
      this.tableData = [];
      try {
        const querySnapshot = await this.certificateRef
          // .orderBy("created")
          .orderBy("created", "asc") // Change to your sorting criteria
          .startAfter(firstDoc)
          .limit(this.pagination.perPage)
          .get();

        this.tableData = querySnapshot.docs
          .map((doc) => Object.assign({ id: doc.id }, doc.data()))
          .reverse();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    clearFilters() {
      this.searchedData = [];
      for (const key in this.filterBy) {
        if (key === "startDate" || key === "endDate") {
          this.filterBy[key] = null;
        } else {
          this.filterBy[key] = "";
        }
      }
      this.resetTable();
    },
    validateDateRange(key) {
      if (moment(this.filterBy.startDate).isAfter(this.filterBy.endDate)) {
        return Swal.fire(
          "Warning",
          "End date cannot be before start date.",
          "warning"
        ).then(() => {
          this.filterBy.startDate = null;
          this.filterBy.endDate = null;
        });
      }
      if (key === "startDate") {
        this.filterBy.endDate = this.filterBy.startDate;
      }
    },
    handleSearch(e) {
      const value = e;
      if (!value) {
        return (this.tableData = this.unfilteredItems);
      }
      this.tableData = this.unfilteredItems.filter((i) => {
        let cert = i.CertNum;
        let sku = i.ClientSKU;
        return cert.includes(value) || sku.includes(value);
      });
    },
    openLink(item) {
      window.open(
        "https://admin.diamondservicesusa.com/jewelry/" + item.CertNum,
        "_blank"
      );
    },
    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },

    exportCSV() {
      var wb = XLSX.utils.table_to_book(
        document.getElementById("shipping-table"),
        {
          sheet: "Shipping Log",
        }
      );

      for (let key in wb.Sheets["Shipping Log"]) {
        if (key.includes("L") || key.includes("M")) {
          delete wb.Sheets["Shipping Log"][key];
        }
      }

      var wbExport = XLSX.write(wb, {
        sheetType: "xlsx",
        bookSST: true,
        type: "binary",
      });

      var excelBlob = new Blob(
        [this.s2ab(wbExport)],
        { type: "application/vnd.ms-excel;charset=utf-8" },
        "Shipping Log.xlsx"
      );

      saveAs(excelBlob, "Shipping Log.xlsx");
    },

    importCSV(oEvent) {
      var oFile = oEvent.target.files[0];
      var sFilename = oFile.name;

      var reader = new FileReader();
      var result = {};
      let sheetNames;

      reader.onload = (e) => {
        var data = e.target.result;
        data = new Uint8Array(data);
        var workbook = XLSX.read(data, { type: "array" });
        var result = {};
        workbook.SheetNames.forEach(function (sheetName) {
          sheetNames = sheetName;
          var roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
            header: 1,
          });
          if (roa.length) result[sheetName] = roa;
        });

        // see the result, caution: it works after reader event is done.
        let spredSheetHeader = result[sheetNames][0];
        result[sheetNames].splice(0, 1);
        let spredSheetData = result[sheetNames]
          .filter((dataArr) => dataArr.length > 0)
          .map((dataArr, i) => {
            let object = {};
            dataArr.forEach((data, i) => {
              if (data == "Yes") {
                data = true;
              } else if (data == "No") {
                data = false;
              }

              object[spredSheetHeader[i].toLowerCase()] = data;
            });
            return object;
          });

        this.ImportDataToDB(spredSheetData);
      };
      reader.readAsArrayBuffer(oFile);
    },

    async ImportDataToDB(spredSheetData) {
      for (let i = 0; i < spredSheetData.length; i++) {
        let ref = await db.collection("jewelry").doc();
        let data = spredSheetData[i];
        data.contact = {
          name: null,
          email: null,
          phone: null,
        };
        await batch.set(ref, data);
      }
      await batch.commit().then(() => {
        return Swal.fire(
          "Success",
          "Rows in red are imported data that needs further information",
          "success"
        );
      });
    },
    promptFileUpload() {
      document.getElementById("inputFile").click();
    },

    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].toString().localeCompare(b[sortBy].toString());
        }
        return b[sortBy].toString().localeCompare(a[sortBy].toString());
      });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.search-options {
  width: 15rem;
  text-align: initial;
  margin-bottom: 5px;
}

.search-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: end;
}

.search-input {
  display: flex;
  justify-content: end;
  align-items: end;
  width: 100%;
  height: auto;
}
</style>
  