<template>
  <div>
    <label>{{ label }} <span v-if="required" class="required">*</span></label>
    <div class="upload_holder">
      <transition name="fade">
        <div
          class="upload_click drop-area"
          @click="$refs.upload.click()"
          @drop="handleDrop"
          @dragover.prevent
        >
          <md-icon>present_to_all</md-icon>
          <p>Drag and Drop or Click here to upload media.</p>
        </div>
      </transition>

      <input
        ref="upload"
        multiple
        type="file"
        :accept="'media/*'"
        class="hide_upload"
        @change="uploadFiles"
      />
    </div>
    <md-radio v-if="files.length" v-model="unCheckedValue" :value="true"
      >Select All</md-radio
    >
    <md-radio v-if="files.length" v-model="unCheckedValue" :value="false"
      >Deselect All</md-radio
    >
    <div class="uploaded_media_wrapper">
      <transition name="fade">
        <div v-if="load" class="loading">
          <md-icon class="rotate">autorenew</md-icon>
          <p>loading...</p>
        </div>
      </transition>
      <transition name="fade">
        <div v-if="files.length" class="image_success">
          <div class="image_wrapper" v-for="(media, i) in files" :key="i">
            <div v-if="media.type.includes('video')">
              <md-checkbox v-model="media.globalSku">Use As SKU </md-checkbox>
              <video controls autoplay class="video" :src="media.url"></video>
              <div class="inputs">
                <input type="text" v-model="media.name" name="" id="" />
              </div>
            </div>
            <img v-else :src="media.url" :alt="media.name" />
            <p @click="$emit('removeFile', i, media)" class="text_btn">
              Remove
            </p>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { db, storage } from "@/config/firebaseInit";

export default {
  props: {
    label: { type: String, default: "" },
    files: { type: Array, default: [] },
    useCustomPath: { type: Boolean, default: false },
    path: { type: String, default: "" },
    required: { type: Boolean, default: false },
  },
  data() {
    return {
      load: false,
      fileURLs: [],
      unCheckedValue: true,
    };
  },
  methods: {
    handleDrop(event) {
      event.preventDefault();
      this.uploadFiles({ target: { files: event.dataTransfer.files } });
    },

    async uploadFiles(e) {
      this.upload = null;
      this.load = null;
      setTimeout(() => {
        this.load = true;
      }, 500);

      const files = Object.values(e.target.files);
      files.map(async (media, index) => {
        let reader = new FileReader();
        reader.readAsDataURL(media);
        reader.onload = async (e) => {
          let name = media.name.split(".")[0];
          if (name.includes("_")) {
            name = name.split("_")[0];
          }

          const refStr = this.useCustomPath
            ? this.path
            : `${this.path}/${name}/${Date.now()}`;
          let storageRef = storage.ref(refStr);
          let uploadTask = storageRef.put(media);

          await uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (error) => {
              console.log(error);
              alert("could not upload images, please try again.");
            },
            (snapshot) => {
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                let name = media.name.split(".")[0];
                if (name.includes("_")) {
                  name = name.split("_")[0];
                }
                let file = {
                  url: downloadURL,
                  name,
                  type: media.type,
                  ref: refStr,
                  globalSku: true,
                };
                this.fileURLs.push(file);
                if (index == files.length - 1) {
                  this.load = false;

                  this.$emit("uploadedFiles", this.fileURLs);
                }
              });
            }
          );
        };
      });
    },
  },
  watch: {
    unCheckedValue(value) {
      console.log(value);
      this.fileURLs.forEach((file) => {
        file.globalSku = value;
      });
      this.$emit("uploadedFiles", this.fileURLs);
    },
  },
};
</script>

<style lang="css" scoped>
.video {
  height: 356px;
}
.upload_holder {
  width: 100%;
  padding: 30px;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploaded_media_wrapper {
  width: 100%;
  padding: 30px;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 5px #00ff34 dashed;
}
.image_wrapper {
  width: auto;
}
.text_btn {
  color: white;
  margin: 0;
  color: red;
  text-decoration: underline;
  cursor: pointer;
  padding: 5px 10px;
  width: 162px;
  text-align: center;
}
.hide_upload {
  position: absolute;
  z-index: -2;
}

.upload_click {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loading {
  position: absolute;
  z-index: 100;
  text-align: center;
}

.upload_click .md-icon {
  font-size: 100px !important;
  opacity: 0.5;
}

.upload_click p {
  /* line-height: 1.3; */
  max-width: 300px;
  text-align: center;
  opacity: 0.5;
  margin-top: 10px;
  margin-bottom: 0;
}

.rotate {
  font-size: 40px !important;
  -webkit-animation: loading 1s infinite linear;
}
.success {
  width: 25%;
}
.success p {
  text-align: center;
  margin-bottom: 0;
}

.success img {
  width: 100%;
  max-height: 100%;
}

.image_success {
  width: 100%;
  overflow: auto;
  max-height: 500px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.drop-area {
  width: 100%;
  border: 1px dashed green;
  height: 150px;
}

@-webkit-keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
