<template>
  <div class="loginreg_holder">
    <!-- :style="{backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/dash-of-clash.appspot.com/o/img4.jpg?alt=media&token=cea72306-a6e2-48bb-ac3c-44a0ee6f4dfa)'}"
 -->
    <div class="diamond_bg">
      <img src="../../../.././public/img/login-background.jpg" />
    </div>
    <div class="left_side">
      <img
        class="logo"
        src="../../../.././public/img/logo-diamond.png"
        width="100%"
      />
      <div>
        <form @submit.prevent="logIn">
          <div>
            <label for="email">Email:</label>
            <input name="email" type="email" v-model="administrator.email" />
          </div>
          <div>
            <label for="password">Password:</label>
            <input
              name="password"
              type="password"
              v-model="administrator.password"
            />
          </div>
          <button class="btn" @click="logIn">Log In</button>
          <router-link to="/forgot-password">Forgot Password?</router-link>
          <transition name="slide">
            <p class="error" v-if="feedback">{{ feedback }}</p>
          </transition>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      administrator: {
        email: null,
        password: null,
      },
      feedback: null,
    };
  },
  methods: {
    logIn() {
      if (this.administrator.email && this.administrator.password) {
        this.feedback = null;
        firebase
          .auth()
          .signInWithEmailAndPassword(
            this.administrator.email,
            this.administrator.password
          )
          .then(() => {
            this.$router.push({ name: "Home" });
          })
          .catch((err) => {
            console.error(err);
            this.feedback = err.message;
          });
      } else {
        this.feedback = "Please enter both email and password.";
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    // if (to.params.bypass_code == "D!S1504") {
    //   return next();
    // } else {
    //   fetch("https://api.ipify.org?format=json")
    //     .then((response) => response.json())
    //     .then((data) => {
    //       if (data.ip == process.env.VUE_APP_APP_IP) next();
    //       else next({ name: "Error404" });
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // }
    next()
  },
};
</script>

<style scoped>
/*============================ LOGIN REGISTER  =====================================*/
.diamond_bg img {
  /* height: 100%; */
  width: 100%;
  /* margin-left: 29%; */
  position: fixed;
  object-fit: cover;
}
.loginreg_holder {
  background-color: #000000;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  min-height: 600px;
  background-size: cover;
  background-position: center center;
  z-index: 99999;
  top: 0;
}

.loginreg_holder .left_side {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 400px;
  background-color: white;
  padding: 50px;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px grey;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loginreg_holder .left_side .logo {
  margin-bottom: 50px;
  width: 100%;
  max-width: 300px;
}

.loginreg_holder .left_side form {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.loginreg_holder .left_side form > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 15px;
  position: relative;
}

input {
  padding: 10px 15px;
  border: none;
  background-color: #eaeaea !important;
  outline: none;
  font-size: 16px;
}

.loginreg_holder .left_side form .btn {
  width: 100%;
}

.loginreg_holder .left_side a {
  color: #00314b;
  font-weight: bold;
  text-decoration: underline;
  text-underline-position: under;
  text-align: center;
  font-size: 14px;
  width: 100%;
  margin-top: 10px;
  opacity: 0.5;
  transition: opacity 0.3s;
}

.loginreg_holder .left_side a:hover {
  opacity: 1;
}

.error {
  position: relative;
  color: #fa5019;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  transition: max-height 1s;
}

.md-toolbar-row.md-offset {
  display: none !important;
}

.btn {
  padding: 10px 30px;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  border: none;
  color: white;
  background-color: black;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.inner_nav {
  overflow: hidden;
  overflow-x: auto;
}

.inner_nav .btn {
  background-color: #efefef;
  color: rgba(0, 49, 75, 0.48);
}

.inner_nav .active_btn .btn,
.inner_nav .router-link-active .btn {
  background-color: #bf3d91;
  color: white;
}

.top_btn {
  position: fixed;
  top: 64px;
  right: 50px;
  z-index: 4;
}

.inner_btn_holder {
  text-align: right;
}

.back_btn {
  right: auto;
  left: 250px;
  background-color: transparent;
  color: #bf3d91;
}

.txt_btn,
.delete_btn {
  background-color: transparent;
  color: #bf3d91;
}

.delete_btn {
  color: #fa3019;
}

button.btn:hover {
  background-color: white;
  border: 2px solid black;
  color: black;
}

button.txt_btn:hover,
.back_btn:hover {
  color: #9d3378;
  background-color: transparent !important;
}

button.sml_btn {
  font-size: 14px;
}

button.delete_btn:hover {
  background-color: transparent;
  color: #e82d17;
}

.inner_nav button.btn:hover {
  color: white;
}

label {
  font-size: 15px;
  color: black;
}
</style>

