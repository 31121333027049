<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-button @click="$router.go(-1)" class="md-primary">back</md-button>
      <md-card>
        <md-card-content>
          <md-field>
            <md-checkbox
              :disabled="company.emailVerified"
              v-model="initRequest"
            >
              DIS-Ad Portal Access
            </md-checkbox>
            <md-field
              v-if="initRequest && !company.emailVerified"
              class="account-creation"
            >
              <label for="">Email To Send Request To</label>
              <md-input
                required
                type="text"
                v-model="emailToSendRequestTo"
              ></md-input>
              <md-button
                :style="{ 'background-color': 'green !important' }"
                @click="sendEmailRequest"
                >{{
                  company.adPortalInvitationSent ? "Resend" : "Send"
                }}</md-button
              >
            </md-field>
          </md-field>

          <md-field>
            <label>Company Name</label>
            <md-input required type="text" v-model="company.name"></md-input>
          </md-field>
          <md-field>
            <label>Representative Name</label>
            <md-input
              required
              type="text"
              v-model="company.rep_name"
            ></md-input>
          </md-field>
          <md-field>
            <label>Phone</label>
            <md-input type="phone" v-model="company.phone"></md-input>
          </md-field>
          <md-field>
            <label>Email</label>
            <md-input required type="email" v-model="company.email"></md-input>
          </md-field>
          <md-divider />

          <div class="field-inputs">
            <Upload
              @uploadedFile="uploadedImage"
              @removeFile="removeImage('images')"
              label="Upload Company Logo"
              :media="company.images"
              :path="`clients/${$route.params.company_id}/logo`"
              media_type="image"
              :useCustomPath="true"
            />
            <Upload
              v-if="
                (initRequest && company.hasAdPortalAccess) ||
                (company.hasAdPortalAccess && company.adPortalBgImage)
              "
              @uploadedFile="uploadBGImage"
              @removeFile="removeImage('adPortalBgImage')"
              label="Client's Ad Portal Background Image"
              :media="company.adPortalBgImage"
              :path="`clients/${$route.params.company_id}/ad_portal_bg`"
              :useCustomPath="true"
              media_type="image"
            />
          </div>
        </md-card-content>

        <md-card-actions md-alignment="space-between">
          <md-button class="md-primary md-round md-small" @click="validate"
            >Save</md-button
          >
          <md-button class="md-danger md-round md-small" @click="handleDelete"
            >Delete</md-button
          >
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import CKEditor from "@/components/CKEditor.vue";
import Upload from "@/components/Inputs/Upload.vue";
import { db, functions, auth, storage } from "@/config/firebaseInit.js";
import Swal from "sweetalert2";

//import vSelect from "vue-select";

export default {
  components: {
    CKEditor,
    Upload,
  },
  data() {
    return {
      addUser: functions.httpsCallable("addUser"),
      initRequest: false,
      useOnFile: false,
      emailToSendRequestTo: "",
      clientRef: {},
      company: {
        slug: null,
        name: null,
        email: null,
        rep_name: null,
        images: {
          name: "",
          type: "",
          url: "",
          ref: "",
        },
        adPortalBgImage: {
          name: "",
          type: "",
          url: "",
          ref: "",
        },
        total_skus: 0,
        hasAdPortalAccess: false,
        color: "",
      },
    };
  },

  async created() {
    await this.getClientData();
  },

  methods: {
    async getClientData() {
      this.clientRef = db.doc(`companies/${this.$route.params.company_id}`);
      let client = await this.clientRef.get();
      if (client.exists) {
        this.company = client.data();
        if (this.company.hasAdPortalAccess) this.initRequest = true;
      }
    },
    handleDelete() {
      return Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.clientRef.delete();
          Swal.fire("Deleted!", "The data has been deleted.", "success").then(
            this.$router.go(-1)
          );
        }
      });
    },
    sendEmailRequest() {
      if (!this.emailToSendRequestTo) {
        this.displayError({
          code: "404",
          message: "A valid email must be entered to invite client",
        });
        return false;
      }
      let loader = this.$loading.show({
        container: this.$refs.loader,
        canCancel: false,
      });

      const email = this.emailToSendRequestTo.trim();
      const randomPass = Math.random().toString(36).slice(-8);

      this.addUser({
        uid: this.$route.params.company_id,
        email: email,
        password: randomPass,
        displayName: this.company.name,
      })
        .then((res) => {
          return auth
            .sendSignInLinkToEmail(email, {
              url: `https://www.ad-portal.diamondservicesusa.com/registration/${this.$route.params.company_id}`,
              //url: `https://ad-portal-staging.web.app/registration/${this.$route.params.company_id}`,
              handleCodeInApp: true,
            })
            .then(() => {
              return this.clientRef
                .update({
                  adPortalInvitationSent: true,
                  emailVerified: false,
                  adPortalData: {
                    password: randomPass,
                    email,
                  },
                })
                .then(() => {
                  this.getClientData();
                  loader.hide();
                  return Swal.fire(
                    "Email Sent!",
                    `The email to client has been sent. They can immediately log in to use DIS Ad-Portal`,
                    "success"
                  );
                });
            })
            .catch((error) => {
              loader.hide();
              this.displayError(error);
            });
        })
        .catch((error) => {
          loader.hide();
          this.displayError(error);
        });
    },
    displayError(error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log({ errorCode, errorMessage });
      return Swal.fire(
        "Error!",
        `${errorMessage} - code: ${errorCode}. Please try again or contact support.`,
        "error"
      );
    },
    updateColorValue(color) {
      this.company.client_color = color;
    },
    capitalizeFirstLetter(str) {
      return str[0].toUpperCase() + str.slice(1);
    },
    updateData(payload) {
      this.company.description = payload;
    },
    validate() {
      let valid = true;
      let errors = [];
      for (let k in this.company) {
        if (k == "name" || k == "email" || k == "rep_name") {
          if (!this.company[k]) {
            errors.push(this.capitalizeFirstLetter(k.replace(/_/g, " ")));
            valid = false;
          }
        }
      }

      if (valid) {
        this.save();
      } else {
        Swal.fire("Missing Required Fields", `${errors}`, "warning");
      }
    },

    uploadedImage(payload) {
      this.company.logo = payload.media;
      this.company.images = payload.media;
    },

    uploadBGImage(payload) {
      this.company.adPortalBgImage = payload.media;
    },

    removeImage(key) {
      if (!this.company[key].ref) {
        this.company[key] = {
          name: "",
          type: "",
          url: "",
          ref: "",
        };
        return;
      }
      const imageRef = storage.ref(this.company[key].ref);
      // Delete the file
      imageRef
        .delete()
        .then(() => {
          this.company[key] = {
            name: "",
            type: "",
            url: "",
            ref: "",
          };
        })
        .catch((error) => {
          this.displayError(error);
        });
    },
    save() {
      if (!this.company.created) {
        this.company.created = Date.now();
        this.company.clicks = 0;
      }
      // this.formatDescription(this.company.description);

      return this.clientRef
        .set(this.company, { merge: true })
        .then(() => {
          return Swal.fire("Success", "Client Data Saved!", "success").then(
            this.$router.go(-1)
          );
        })
        .catch((error) => {
          this.displayError(error);
        });
    },

    formatDescription(description) {
      let str = description.toString();
      str = str.replace(/<[^>]*>/g, "");
      this.company.description = str;
      return str;
    },
  },
  watch: {
    useOnFile(val) {
      if (val) this.emailToSendRequestTo = this.company.email;
      else this.emailToSendRequestTo = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.md-divider {
  margin: 20px 0 20px 0;
}

.account-creation {
  width: 600px;
}
.field-inputs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .md-field {
    width: 48%;
  }
  .switch {
    width: 48%;
    display: flex;
    .switch-flex {
      margin-right: 50px;
    }
  }
}
.upload-description {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.upload-parent {
  width: 48%;
}
</style>
