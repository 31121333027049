<template>
  <div class="md-layout">
    <md-card class="light">
      <md-card-content>
        <md-field class="search-bar">
          <md-input
            type="search"
            clearable
            placeholder="Search records by Cert# or Client SKU"
            v-model="searchVal"
            @input="handleSearch($event)"
          ></md-input>
        </md-field>
        <div class="filters">
          <div class="filter">
            <p>Start From:</p>
            <md-datepicker
              class="datepicker"
              @input="validateDateRange('startDate')"
              v-model="filterBy.startDate"
            />
          </div>
          <div class="filter">
            <p>End To:</p>
            <md-datepicker
              class="datepicker"
              @input="validateDateRange('endDate')"
              v-model="filterBy.endDate"
            />
          </div>
          <div class="filter">
            <p>Filter By Client</p>
            <v-select
              :appendToBody="true"
              :deselectFromDropdown="true"
              v-model="filterBy.companies"
              :options="companies"
              multiple
              label="name"
              :reduce="(p) => p.id"
              :closeOnSelect="false"
            >
              <template v-slot:option="option">
                <input
                  type="checkbox"
                  :value="option.id"
                  v-model="filterBy.companies"
                />
                {{ option.name }}
              </template>
            </v-select>
          </div>
          <div
            class="filter filter-btn"
            style="width: 200px; bottom: 5px; position: absolute; right: 0"
          >
            <md-button
              style="background-color: red !important"
              class="text-btn"
              @click="clearFilters"
              >Clear Filters</md-button
            >
          </div>
        </div>
      </md-card-content>
    </md-card>

    <div class="md-layout-item">
      <md-card>
        <md-card-content>
          <md-table
            md-fixed-header
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-button class="md-primary md-round md-small" @click="newVid"
                >Upload Digital Certificate</md-button
              >

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Search records"
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Created On" md-sort-by="createdOn">
                {{ item.createdOn | format_date }}
              </md-table-cell>
              <md-table-cell md-label="Updated On" md-sort-by="updatedAt">
                <div v-if="item.updatedAt">
                  {{ item.updatedAt | format_date }}
                </div>
                <div v-else>N/A</div>
              </md-table-cell>
              <md-table-cell md-label="Name" md-sort-by="name">
                {{ item.name }}
              </md-table-cell>
              <md-table-cell md-label="Client" md-sort-by="client">
                {{ item.client.name }}
              </md-table-cell>
              <md-table-cell md-label="Actions">
                <md-button
                  @click="openLink(item.name)"
                  class="md-just-icon md-warning md-simple"
                >
                  <md-icon>link</md-icon>
                  <md-tooltip>View Digital Certificate</md-tooltip>
                </md-button>
                <md-button
                  class="md-just-icon md-warning md-simple"
                  @click.native="handleEdit(item)"
                >
                  <md-icon>dvr</md-icon>
                </md-button>
                <md-button
                  class="md-just-icon md-danger md-simple"
                  @click.native="handleDelete(item)"
                >
                  <md-icon>delete</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import { Pagination } from "@/components";
import Fuse from "fuse.js";
import { db, storage } from "@/config/firebaseInit";
import Swal from "sweetalert2";
import router from "@/router";
import moment from "moment";

export default {
  components: {
    Pagination,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        perPage: 100,
        currentPage: 1,
        perPageOptions: [50, 100, 250, 500],
        total: 0,
      },
      footerTable: ["Name", "URL", "Actions"],
      searchQuery: "",
      propsToSearch: ["name", "url"],
      tableData: [],
      unfilteredItems: [],
      searchedData: [],
      companies: [],
      fuseSearch: null,
      filterBy: {
        companies: [],
        startDate: null,
        endDate: null,
      },
      searchVal: "",
    };
  },
  firestore() {
    return {
      companies: db.collection("companies").orderBy("name", "asc"),
      unfilteredItems: db
        .collection("digital_certificate_videos")
        .orderBy("createdOn", "desc"),
    };
  },
  methods: {
    handleSearch(e) {
      let value = e;
      this.searchVal = value.toUpperCase();
      if (!value) {
        return (this.tableData = this.unfilteredItems);
      }
      this.tableData = this.unfilteredItems.filter((i) =>
        i.name.includes(value)
      );
    },
    clearFilters() {
      for (const key in this.filterBy) {
        if (key === "startDate" || key === "endDate") {
          this.filterBy[key] = null;
        } else {
          this.filterBy[key] = [];
        }
      }
      this.searchVal = null;
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].toString().localeCompare(b[sortBy].toString());
        }
        return b[sortBy].toString().localeCompare(a[sortBy].toString());
      });
    },
    newVid() {
      let ref = db.collection("digital_certificate_videos").doc();
      this.handleEdit(ref);
    },
    handleEdit(item) {
      let id = item.id;
      router.push(`/digital-certificates/${id}`);
    },
    openLink(name) {
      window.open(
        `https://certificates.diamondservicesusa.com/only-sku/${name}`,
        "_blank"
      );
    },
    removeMedia(item) {
      const imageRef = storage.ref(item.meta.ref);
      // Delete the file
      return imageRef
        .delete()
        .then(() => true)
        .catch((error) => {
          this.displayError(error);
        });
    },
    displayError(error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log({ errorCode, errorMessage });
      return Swal.fire(
        "Error!",
        `${errorMessage} - code: ${errorCode}. Please try again or contact support.`,
        "error"
      );
    },
    handleDelete(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to undo this",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "DELETE",
        cancelButtonText: "CANCEL",
      }).then((result) => {
        if (result.value) {
          return db
            .collection("digital_certificate_videos")
            .doc(item.id)
            .delete()
            .then(() => this.removeMedia(item))
            .then(() => {
              Swal.fire("Deleted!", "The file has been deleted.", "success");
            });
        }
      });
    },
    validateDateRange(key) {
      if (moment(this.filterBy.startDate).isAfter(this.filterBy.endDate)) {
        return Swal.fire(
          "Warning",
          "End date cannot be before start date.",
          "warning"
        ).then(() => {
          this.filterBy.startDate = null;
          this.filterBy.endDate = null;
        });
      }
      if (key === "startDate") {
        this.filterBy.endDate = this.filterBy.startDate;
      }
    },
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ["name", "url"],
      threshold: 0.3,
    });
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    unfilteredItems: {
      deep: true,
      handler() {
        if (!this.unfilteredItems.length) {
          this.tableData = this.unfilteredItems;
        }
      },
    },
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
    filterBy: {
      deep: true,
      async handler() {
        let ref = db.collection("digital_certificate_videos");
        let filteredItems = this.unfilteredItems;
        if (
          !this.filterBy.companies.length &&
          !this.filterBy.startDate &&
          !this.filterBy.endDate
        ) {
          this.tableData = this.unfilteredItems;
          return false;
        }
        if (this.filterBy.startDate && this.filterBy.endDate) {
          let startDate = new Date(this.filterBy.startDate).getTime();
          let endDate = new Date(this.filterBy.endDate).setHours(24, 0, 0, 0);
          ref = ref.orderBy("createdOn").startAt(startDate).endAt(endDate);
          let container = await ref.get();
          filteredItems = container.docs
            .map((d) => Object.assign({ id: d.id }, d.data()))
            .sort((a, b) => (a.created > b.created ? -1 : 1));
        }

        this.tableData = filteredItems.filter((item) => {
          let company = item.client.id;
          return (
            !this.filterBy.companies.length ||
            this.filterBy.companies.includes(company)
          );
        });
      },
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
