<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-content>
          <md-table
            md-fixed-header
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
            id="company-table"
          >
            <md-table-toolbar :style="{ marginBottom: '20px' }">
              <md-field>
                <label for="company">Per page</label>
                <md-select v-model="pagination.perPage" name="company">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                    >{{ item }}</md-option
                  >
                </md-select>
              </md-field>

              <!-- <md-button class="md-primary md-round md-small" @click="exportCSV">Export</md-button> -->
              <md-button
                class="md-primary md-round md-small"
                @click="newCompany"
                >New Client</md-button
              >
              <!-- <md-button class="md-primary md-round md-small" @click="promptFileUpload">Import</md-button> -->
              <input
                style="display: none"
                id="inputFile"
                type="file"
                @input="importCSV"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Search records"
                  v-model="searchQuery"
                ></md-input>
              </md-field>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Name" md-sort-by="name"
                ><a @click="handleEdit(item)">{{ item.name }}</a></md-table-cell
              >
              <md-table-cell md-label="Rep Name" md-sort-by="rep name">{{
                item.rep_name || "TBD"
              }}</md-table-cell>
              <md-table-cell md-label="Phone" md-sort-by="phone">{{
                humanizeNumber(item.phone) || "TBD"
              }}</md-table-cell>
              <md-table-cell md-label="Email" md-sort-by="email">{{
                item.email || "TBD"
              }}</md-table-cell>

              <md-table-cell md-label="Ad Portal Access" md-sort-by="serial">
                <md-badge
                  v-if="item.hasAdPortalAccess"
                  class="md-square md-primary"
                  md-content="Active"
                ></md-badge>
                <md-badge v-else class="md-square" md-content="Inactive" />
              </md-table-cell>
              <md-table-cell md-label="Total SKUs" md-sort-by="serial">{{
                item.total_skus
              }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class>
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          ></pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import { Pagination } from "@/components";
import Fuse from "fuse.js";
import { db, batch } from "@/config/firebaseInit";
import Swal from "sweetalert2";
import router from "@/router";
import XLSX from "xlsx";
import { saveAs } from "file-saver";

export default {
  components: {
    Pagination,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        perPage: 100,
        currentPage: 1,
        perPageOptions: [100, 200, 500],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["title", "fullname", "description"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
    };
  },
  firestore() {
    return {
      tableData: db.collection("companies"),
    };
  },

  methods: {
    humanizeNumber(phone) {
      if (!phone) return;
      var stripped = phone.replace(/[^a-zA-Z0-9]/g, "");
      let newNum = "";
      for (let i = 0; i < stripped.length; i += 1) {
        newNum += stripped[i];
        if ((i === 2) | (i === 5)) {
          newNum += "-";
        }
      }
      return newNum;
    },
    goToProfile(id) {
      router.push(`/companies/profile/${id}`);
    },
    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },

    exportCSV() {
      var wb = XLSX.utils.table_to_book(
        document.getElementById("company-table"),
        {
          sheet: "Company Sheet",
        }
      );

      for (let key in wb.Sheets["Company Sheet"]) {
        if (key.includes("L") || key.includes("M")) {
          delete wb.Sheets["Company Sheet"][key];
        }
      }

      var wbExport = XLSX.write(wb, {
        sheetType: "xlsx",
        bookSST: true,
        type: "binary",
      });

      var excelBlob = new Blob(
        [this.s2ab(wbExport)],
        { type: "application/vnd.ms-excel;charset=utf-8" },
        "company.xlsx"
      );

      saveAs(excelBlob, "company.xlsx");
    },
    humanizePrice(price) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(price);
    },
    published(action, id) {
      db.collection("companies").doc(id).update({ published: action });
    },

    importCSV(oEvent) {
      var oFile = oEvent.target.files[0];
      var sFilename = oFile.name;

      var reader = new FileReader();
      var result = {};
      let sheetNames;

      reader.onload = (e) => {
        var data = e.target.result;
        data = new Uint8Array(data);
        var workbook = XLSX.read(data, { type: "array" });
        var result = {};
        workbook.SheetNames.forEach(function (sheetName) {
          sheetNames = sheetName;
          var roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
            header: 1,
          });
          if (roa.length) result[sheetName] = roa;
        });

        // see the result, caution: it works after reader event is done.
        let spredSheetHeader = result[sheetNames][0];
        result[sheetNames].splice(0, 1);
        let spredSheetData = result[sheetNames]
          .filter((dataArr) => dataArr.length > 0)
          .map((dataArr, i) => {
            let object = {};
            dataArr.forEach((data, i) => {
              if (data == "Yes") {
                data = true;
              } else if (data == "No") {
                data = false;
              }

              object[spredSheetHeader[i].toLowerCase()] = data;
            });
            return object;
          });

        this.ImportDataToDB(spredSheetData);
      };
      reader.readAsArrayBuffer(oFile);
    },

    async ImportDataToDB(spredSheetData) {
      for (let i = 0; i < spredSheetData.length; i++) {
        let ref = await db.collection("companies").doc();
        let data = spredSheetData[i];
        data.contact = {
          name: null,
          email: null,
          phone: null,
        };
        await batch.set(ref, data);
      }
      await batch.commit().then(() => {
        return Swal.fire(
          "Success",
          "Rows in red are imported data that needs further information",
          "success"
        );
      });
    },
    promptFileUpload() {
      document.getElementById("inputFile").click();
    },
    async rearrangeItem(item) {
      await db
        .collection("companies")
        .doc(item.id)
        .update({ order: item.order });
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].toString().localeCompare(b[sortBy].toString());
        }
        return b[sortBy].toString().localeCompare(a[sortBy].toString());
      });
    },
    newCompany() {
      let ref = db.collection("companies").doc();
      this.handleEdit(ref);
    },
    handleEdit(item) {
      let id = item.id;
      router.push(`/clients/${id}`);
    },
    handleDelete(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to undo this",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "DELETE",
        cancelButtonText: "CANCEL",
      }).then((result) => {
        if (result.value) {
          return db
            .collection("companies")
            .doc(item.id)
            .delete()
            .then(() => {
              Swal.fire("Deleted!", "The page has been deleted.", "success");
            });
        }
      });
    },
    goToReports(company) {
      router.push(`/company/${company.id}`);
    },
  },

  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ["name", "rep_name", "phone", "email", "total_skus"],
      threshold: 0.3,
    });
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
a {
  cursor: pointer;
}

.md-badge {
  right: 40% !important;
  width: 80px;
}
</style>
