<template>
  <div class="content">
    <md-card class="light">
      <md-card-content>
        <div class="filters">
          <div class="filter">
            <p>Select a client</p>
            <v-select
              :appendToBody="true"
              v-model="filterBy.client"
              :options="clients"
              :clearable="false"
              label="name"
              :reduce="(c) => c.id"
            >
            </v-select>
          </div>
          <div class="filter">
            <p>Select a year</p>
            <v-select
              :appendToBody="true"
              v-model="filterBy.year"
              :options="analyticsAttributes.years"
              :clearable="false"
              label="name"
            >
            </v-select>
          </div>
          <div class="filter">
            <p>Select a certificate</p>
            <v-select
              :appendToBody="true"
              v-model="filterBy.certificate"
              :options="digitalCertificates"
              :clearable="false"
            >
              <template v-slot:no-options="{}">
                <em style="opacity: 0.5"
                  >Click on Filter to get certificates.</em
                >
              </template>
            </v-select>
          </div>
          <md-button
            style="margin-top: 52px"
            class="md-success"
            @click="handleFilter"
            >Filter</md-button
          >
        </div>
      </md-card-content>
    </md-card>
    <div class="md-layout-item md-size-500">
      <stats-card header-color="blue">
        <template slot="header">
          <md-icon
            class="white_icon"
            style="font-size: 90px !important; color: white"
            >analytics</md-icon
          >
        </template>
        <template slot="content">
          <!-- <p class="category" style="color: green">Jewelry</p> -->
          <div class="container">
            <div class="analytics-cotainer">
              <h3 class="title">
                Total Certificate Views for {{ selectedYear }}
              </h3>
              <animated-number
                v-if="analytics_totals.views && showNumbers"
                :value="analytics_totals.views"
              ></animated-number>
            </div>
            <div class="analytics-cotainer">
              <h3 class="title">Total Ad Clicks for {{ selectedYear }}</h3>
              <animated-number
                v-if="analytics_totals.clicks && showNumbers"
                :value="analytics_totals.clicks"
              ></animated-number>
            </div>
          </div>
        </template>
      </stats-card>
    </div>

    <div class="md-layout-item md-size-500">
      <AnalyticsTable
        :selectableMonths="[
          'ALL',
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ]"
        :tableData="analyticsTableData"
        :analytics="analytics"
      />
    </div>
    <div class="md-layout">
      <!-- <div class="md-layout-item">
                <chart-card
                  header-animation="false"
                  :chart-data="straightLinesChart.data"
                  :chart-options="straightLinesChart.options"
                  chart-type="Line"
                  chart-inside-header
                  no-footer
                  background-color="warning"
                  :renderChart="renderChart"
                >
                  <template slot="content">
                    <h4 class="title">
                      Line Chart For Certificate Views By Monthly
                    </h4>
                  </template>
                </chart-card>
              </div> -->
      <!-- <div class="md-layout-item">
                <chart-card
                  header-animation="false"
                  :chart-data="simpleBarChart.data"
                  :chart-options="simpleBarChart.options"
                  :chart-responsive-options="simpleBarChart.responsiveOptions"
                  chart-type="Bar"
                  chart-inside-header
                  no-footer
                  background-color="blue"
                >
                  <template slot="content">
                    <h4 class="title">Simple Bar Chart</h4>
                    <p class="category">Bar Chart</p>
                  </template>
                </chart-card>
              </div> -->
      <!-- <div class="md-layout-item md-size-50 md-small-size-100">
                <chart-card
                  header-animation="false"
                  :chart-data="colouredLineChart.data"
                  :chart-options="colouredLineChart.options"
                  chart-type="Line"
                  header-icon
                  chart-inside-content
                  no-footer
                  background-color="blue"
                >
                  <template slot="content">
                    <div class="card-icon">
                      <md-icon>timeline</md-icon>
                    </div>
                    <h4 class="title">
                      Coloured Line Chart
                      <small> - Rounded</small>
                    </h4>
                  </template>
                </chart-card>
              </div> -->

      <div class="md-layout-item md-size-50 md-small-size-100">
        <chart-card
          header-animation="false"
          :chart-data="colouredBarsChart.data"
          :chart-options="colouredBarsChart.options"
          chart-type="Line"
          header-icon
          chart-inside-content
          background-color="warning"
          :renderChart="renderChart"
        >
          <template slot="content">
            <!-- <div class="card-icon">
                      <md-icon>timeline</md-icon>
                    </div> -->
            <h4 class="title">
              Certificate Views and Click
              <small> - {{ selectedYear }} </small>
            </h4>
          </template>
          <template slot="footer">
            <div class="md-layout">
              <div class="md-layout-item md-size-100">
                <h6 class="category">Legend</h6>
              </div>
              <div class="md-layout-item">
                <i class="fa fa-circle text-info"></i> Views
                <i class="fa fa-circle text-danger"></i>Advertisement Clicks
              </div>
            </div>
          </template>
        </chart-card>
      </div>

      <div class="md-layout-item md-size-50 md-small-size-100">
        <chart-card
          header-animation="false"
          :chart-data="multipleBarsChart.data"
          :chart-options="multipleBarsChart.options"
          chart-type="Bar"
          header-icon
          chart-inside-content
          background-color="green"
          :renderChart="renderChart"
        >
          <template slot="content">
            <h4 class="title">
              Certificate Views and Advertisement Clicks
              <small> - {{ selectedYear }}</small>
            </h4>
          </template>
          <template slot="footer">
            <div class="md-layout">
              <div class="md-layout-item md-size-100">
                <h6 class="category">Legend</h6>
              </div>
              <div class="md-layout-item">
                <i class="fa fa-circle text-info"></i> Views
                <i class="fa fa-circle text-danger"></i> Advertisement Clicks
              </div>
            </div>
          </template>
        </chart-card>
      </div>

      <div class="md-layout-item md-size-50 md-small-size-100">
        <chart-card
          header-animation="false"
          :chart-data="pieChart.data"
          :chart-options="pieChart.options"
          chart-type="Pie"
          header-icon
          chart-inside-content
          :renderChart="renderChart"
        >
          <template slot="content">
            <h4 class="title">
              <small>
                <div class="dropdown">
                  <span class="clickable-text" @click="toggleDropdown"
                    >{{ selectedOptionFor.PieChart.action }} &#9660;</span
                  >
                  <div class="dropdown-content" v-if="isOpen">
                    <a
                      @click="
                        handleSelectedOption(
                          'PieChart',
                          'action',
                          'Clicks',
                          false
                        )
                      "
                      >Advertisement Clicks</a
                    >
                    <a
                      @click="
                        handleSelectedOption(
                          'PieChart',
                          'action',
                          'Views',
                          false
                        )
                      "
                      >Views</a
                    >
                  </div>
                </div>
              </small>
              for <strong>{{ selectedYear }}</strong> on Each Device
              <small>
                -

                <div class="dropdown">
                  <span class="clickable-text" @click="toggleDropdown"
                    >{{ selectedOptionFor.PieChart.option }} -
                    {{ selectedOptionFor.PieChart.value }} &#9660;</span
                  >
                  <div class="dropdown-content" v-if="isOpen">
                    <a
                      @click="
                        handleSelectedOption(
                          'PieChart',
                          'option',
                          'Entire Year',
                          false
                        )
                      "
                      >Entire Year</a
                    >
                    <a
                      @click="
                        handleSelectedOption(
                          'PieChart',
                          'option',
                          'Month',
                          true
                        )
                      "
                      >Month</a
                    >
                  </div>

                  <div
                    class="second-dropdown"
                    v-if="isOpen && selectedOption === 'Month'"
                  >
                    <div class="dropdown-content">
                      <a
                        v-for="month in analyticsAttributes.months"
                        :key="month"
                        @click="
                          handleSelectedOption(
                            'PieChart',
                            'value',
                            month,
                            false
                          )
                        "
                        >{{ month }}</a
                      >
                    </div>
                  </div>
                </div>
              </small>
            </h4>
          </template>
          <template slot="footer">
            <div class="md-layout">
              <div class="md-layout-item md-size-100">
                <h6 class="category">Legend</h6>
              </div>
              <div class="md-layout-item">
                <i class="fa fa-circle text-info"></i> Android
                <i class="fa fa-circle text-warning"></i> Desktop
                <i class="fa fa-circle text-danger"></i> iOS
              </div>
            </div>
          </template>
        </chart-card>
      </div>

      <!-- <div class="md-layout-item md-size-50 md-small-size-100">
        <md-card class="">
          <md-card-header>WORLD MAP</md-card-header>
          <md-card-content>
            <async-world-map
              v-if="Object.entries(mapData).length"
              class="map map-big"
              :data="mapData"
              :action="selectedOptionFor.Map.action"
            ></async-world-map>

            <div v-else class="no-data">NO DATA</div>
          </md-card-content>
          <md-card-actions md-alignment="left">
            <h4 class="title">
              <small>
                <div class="dropdown">
                  <span class="clickable-text" @click="toggleDropdown"
                    >{{ selectedOptionFor.Map.action }} &#9660;</span
                  >
                  <div class="dropdown-content" v-if="isOpen">
                    <a
                      @click="
                        handleSelectedOption('Map', 'action', 'Clicks', false)
                      "
                      >Advertisement Clicks</a
                    >
                    <a
                      @click="
                        handleSelectedOption('Map', 'action', 'Views', false)
                      "
                      >Views</a
                    >
                  </div>
                </div>
              </small>
              for <strong>{{ selectedYear }}</strong>
            </h4>
          </md-card-actions>
        </md-card>
      </div> -->
    </div>
  </div>
</template>

<script>
import { db, batch } from "@/config/firebaseInit";
import Swal from "sweetalert2";
import moment from "moment";
import router from "@/router";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import Popup from "@/components/InventoryPopup.vue";
import AnalyticsTable from "./components/AnalyticsTable.vue";
import { DateTime } from "luxon";
import {
  StatsCard,
  ChartCard,
  NavTabsCard,
  AnimatedNumber,
} from "@/components";
import "chartist-plugin-tooltips";
import AsyncWorldMap from "@/components/WorldMap/AsyncWorldMap.vue";

export default {
  components: {
    ChartCard,
    StatsCard,
    NavTabsCard,
    AnimatedNumber,
    AsyncWorldMap,
    AnalyticsTable,
  },
  data() {
    return {
      analytics: [],
      analyticsTableData: {},
      clients: [],
      renderChart: false,
      disaplyedAnalyticsData: {},
      mapData: {},
      filterBy: {
        client: null,
        year: moment().year(),
        certificate: "ALL",
        startDate: "",
        endDate: "",
      },
      showLineGraph: false,
      isOpen: false,
      selectedOption: "",
      selectedYear: moment().year(),
      digitalCertificates: [],
      analyticsAttributes: {},
      selectedOptionFor: {
        LineGraph: {
          option: this.selectedYear,
          value: "",
        },
        PieChart: {
          option: "Entire Year",
          value: "",
          action: "Views",
        },
        BarGraph: {
          option: "Year",
          value: "",
        },
        Map: {
          option: "Entire Year",
          value: "",
          action: "Views",
        },
      },
      roundedLineChart: {
        data: {
          labels: ["M", "T", "W", "T", "F", "S", "S"],
          series: [[12, 17, 7, 17, 23, 18, 38]],
        },
        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 10,
          }),
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
          showPoint: false,
        },
      },
      straightLinesChart: {
        data: {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          series: [],
        },

        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 3,
          }),
          low: 0,
          high: 500, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
          classNames: {
            point: "ct-point ct-white",
            line: "ct-line ct-white",
          },
        },
      },
      simpleBarChart: {
        data: {
          labels: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
          series: [
            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
          ],
        },
        options: {
          seriesBarDistance: 10,
          axisX: {
            showGrid: false,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      colouredLineChart: {
        data: {
          labels: [
            "'06",
            "'07",
            "'08",
            "'09",
            "'10",
            "'11",
            "'12",
            "'13",
            "'14",
            "'15",
          ],
          series: [[287, 480, 290, 554, 690, 690, 500, 752, 650, 900, 944]],
        },
        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 10,
          }),
          axisY: {
            showGrid: true,
            offset: 40,
          },
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 5000,
          showPoint: true,
          height: "300px",
        },
      },
      colouredBarsChart: {
        data: {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          series: [],
        },
        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 10,
          }),
          axisY: {
            showGrid: true,
            offset: 40,
          },
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 5000,
          showPoint: true,
          height: "300px",
          plugins: [this.$Chartist.plugins.tooltip()],
        },
      },
      multipleBarsChart: {
        data: {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          series: [],
        },
        options: {
          seriesBarDistance: 10,
          axisX: {
            showGrid: false,
          },
          height: "300px",
          plugins: [this.$Chartist.plugins.tooltip()],
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      pieChart: {
        data: {
          labels: [],
          series: [],
        },
        options: {
          height: "230px",
          plugins: [this.$Chartist.plugins.tooltip()],
        },
      },
      showNumbers: false,
      analytics_totals: {
        clicks: 0,
        views: 0,
        year: null,
        month: null,
      },
    };
  },
  firestore() {
    return {
      clients: db.collection("companies"),
      analyticsAttributes: db.doc("analytics/attributes"),
    };
  },

  async created() {},
  methods: {
    async handleFilter() {
      let loader = this.$loading.show({
        // Optional parameters
        container: null,
        canCancel: false,
      });

      this.selectedYear = this.filterBy.year;
      if (!this.filterBy.client) {
        return Swal.fire("Please select a client to continue");
      }

      let current_year_all_analytics = await db
        .collection(
          `analytics/clients/certificate_analytics/${this.filterBy.client}/year/${this.selectedYear}/month`
        )
        .get();

      if (!current_year_all_analytics.docs.length) {
        this.resetAnalytics();
        return Swal.fire("No Record for this client as of yet");
      }

      let disaplyedAnalyticsData = this.sortMonthsArray(
        current_year_all_analytics.docs.map((d) =>
          Object.assign({ id: d.id }, d.data())
        )
      );

      // if (!this.disaplyedAnalyticsData.hasOwnProperty(this.selectedYear)) {
      //   return Swal.fire(
      //     "Theres no record for analytics for this client on the selected year"
      //   );
      // }

      this.fetchClientCertificates(this.filterBy.client);
      this.analytics_totals.views = 0;
      this.analytics_totals.clicks = 0;
      this.showNumbers = false;
      const totalAmountViews = [];
      const totalAmountClicks = [];
      const totalClickFor = {
        Android: 0,
        iOS: 0,
        Desktop: 0,
      };
      const totalViewsFor = {
        Android: 0,
        iOS: 0,
        Desktop: 0,
      };

      let totalViews = 0;
      let totalClicks = 0;

      const analytics = disaplyedAnalyticsData;
      this.analytics = analytics;
      // Convert the object to an array of key-value pairs

      // Sort the array based on the desired order
      // monthsArray.sort((a, b) => {
      //   const indexA = this.analyticsAttributes.months.indexOf(a.id);
      //   const indexB = this.analyticsAttributes.months.indexOf(b.id);
      //   return indexA - indexB;
      // });

      // Convert the sorted array back to an object
      const sortedMonthsObj = {};
      analytics.forEach((element) => {
        sortedMonthsObj[element.id] = element;
      });
      this.disaplyedAnalyticsData = sortedMonthsObj;
      this.analyticsTableData = sortedMonthsObj;

      if (this.filterBy.certificate == "ALL") {
        for (const key in sortedMonthsObj) {
          if (Object.hasOwnProperty.call(sortedMonthsObj, key)) {
            const element = sortedMonthsObj[key];
            totalViews += element.total_certificate_views;
            totalClicks += element.total_clicks_to_company_site;
            this.analytics_totals.views = totalViews;
            this.analytics_totals.clicks = totalClicks;
            totalAmountViews.push(
              Object.assign({
                meta: "Certificate Views",
                value: element.total_certificate_views,
              })
            );
            totalAmountClicks.push(
              Object.assign({
                meta: "Clicks",
                value: element.total_clicks_to_company_site,
              })
            );

            const devices = element.devices;
            for (const key in devices) {
              if (Object.hasOwnProperty.call(devices, key)) {
                const device = devices[key];
                totalClickFor[key] += device.clicks_to_company_site;
                totalViewsFor[key] += device.certificate_views;
              }
            }
          }
        }
      } else {
        for (const key in sortedMonthsObj) {
          if (Object.hasOwnProperty.call(sortedMonthsObj, key)) {
            const element = sortedMonthsObj[key];

            if (
              element.certificates.hasOwnProperty(this.filterBy.certificate)
            ) {
              const certificate =
                element.certificates[this.filterBy.certificate];
              totalViews += certificate.total_certificate_views;
              totalClicks += certificate.total_clicks_to_company_site;
              this.analytics_totals.views = totalViews;
              this.analytics_totals.clicks = totalClicks;
              totalAmountViews.push(
                Object.assign({
                  meta: "Certificate Views",
                  value: certificate.total_certificate_views,
                })
              );
              totalAmountClicks.push(
                Object.assign({
                  meta: "Clicks",
                  value: certificate.total_clicks_to_company_site,
                })
              );

              const devices = certificate.devices;
              for (const key in devices) {
                if (Object.hasOwnProperty.call(devices, key)) {
                  const device = devices[key];
                  totalClickFor[key] += device.clicks_to_company_site;
                  totalViewsFor[key] += device.certificate_views;
                  console.log({ totalClickFor, totalViewsFor });
                }
              }
            } else {
              totalAmountViews.push(
                Object.assign({
                  meta: "Certificate Views",
                  value: 0,
                })
              );
              totalAmountClicks.push(
                Object.assign({
                  meta: "Clicks",
                  value: 0,
                })
              );
            }
          }
        }
      }

      this.pieChart.data.labels = [];
      this.pieChart.data.series = [];

      if (this.selectedOptionFor.PieChart.action == "Clicks") {
        for (const key in totalClickFor) {
          if (Object.hasOwnProperty.call(totalClickFor, key)) {
            const clicks = totalClickFor[key];
            const percentage = Math.round((clicks / totalClicks) * 100);
            this.pieChart.data.labels.push(`${percentage}%`);
            this.pieChart.data.series.push({
              meta: key,
              value: percentage,
            });
          }
        }
      }

      if (this.selectedOptionFor.PieChart.action == "Views") {
        for (const key in totalViewsFor) {
          if (Object.hasOwnProperty.call(totalViewsFor, key)) {
            const views = totalViewsFor[key];
            const percentage = Math.round((views / totalViews) * 100);
            this.pieChart.data.labels.push(`${percentage}%`);
            this.pieChart.data.series.push({
              meta: key,
              value: percentage,
            });
          }
        }
      }

      this.colouredBarsChart.data.series = [
        totalAmountViews,
        totalAmountClicks,
      ];

      this.multipleBarsChart.data.series = [
        totalAmountViews,
        totalAmountClicks,
      ];

      this.handleMapFilters(this.disaplyedAnalyticsData);

      this.renderChart = true;
      setTimeout(() => {
        this.renderChart = false;
        this.$nextTick();
        this.$forceUpdate();
        this.showNumbers = true;
        loader.hide();
      }, 1000);
    },

    sortMonthsArray(monthsArray) {
      const monthOrder = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      // Create a map of month names to their index positions
      const monthIndex = monthOrder.reduce((acc, month, index) => {
        acc[month] = index;
        return acc;
      }, {});

      // Sort the monthsArray based on the monthIndex mapping
      return monthsArray.sort((a, b) => monthIndex[a.id] - monthIndex[b.id]);
    },
    resetAnalytics() {
      this.colouredBarsChart.data.series = [];
      this.pieChart.data.labels = [];
      this.pieChart.data.series = [];
      this.multipleBarsChart.data.series = [];
      this.analytics_totals.views = 0;
      this.analytics_totals.clicks = 0;
      this.filterBy.client = null;
      this.mapData = {};
      setTimeout(() => {
        this.renderChart = false;
        this.$nextTick();
        this.$forceUpdate();
        this.showNumbers = false;
      }, 500);
    },
    async fetchClientCertificates(id) {
      let digitalCertificates = await db
        .collection("digital_certificate_videos")
        .where("client.id", "==", id)
        .get();
      this.digitalCertificates = digitalCertificates.docs.map((d) => d.id);
      this.digitalCertificates.unshift("ALL");
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    handleSelectedOption(key, subkey, value, isOpen) {
      this.selectedOption = value;
      this.selectedOptionFor[key][subkey] = value;
      this.isOpen = isOpen;
      if (value == "Entire Year") {
        this.selectedOptionFor[key].value = this.selectedYear;
      }
      if (key == "PieChart" && !isOpen) {
        this.handlePieChartFilters();
      }

      if (key == "Map" && !isOpen) {
        this.handleMapFilters(this.disaplyedAnalyticsData);
      }
    },

    handlePieChartFilters() {
      this.pieChart.data.labels = [];
      this.pieChart.data.series = [];

      const totalClickFor = {
        Android: 0,
        iOS: 0,
        Desktop: 0,
      };
      const totalViewsFor = {
        Android: 0,
        iOS: 0,
        Desktop: 0,
      };

      let totalViews = 0;
      let totalClicks = 0;
      const analytics = this.disaplyedAnalyticsData;

      // Convert the object to an array of key-value pairs
      const monthsArray = Object.entries(analytics);

      // Sort the array based on the desired order
      monthsArray.sort((a, b) => {
        const indexA = this.analyticsAttributes.months.indexOf(a[0]);
        const indexB = this.analyticsAttributes.months.indexOf(b[0]);
        return indexA - indexB;
      });
      // Convert the sorted array back to an object
      const sortedMonthsObj = Object.fromEntries(monthsArray);

      if (this.selectedOptionFor.PieChart.option == "Entire Year") {
        for (const key in sortedMonthsObj) {
          if (Object.hasOwnProperty.call(sortedMonthsObj, key)) {
            const element = sortedMonthsObj[key];
            const devices = element.devices;
            totalViews += element.total_certificate_views;
            totalClicks += element.total_clicks_to_company_site;
            for (const key in devices) {
              if (Object.hasOwnProperty.call(devices, key)) {
                const device = devices[key];
                totalClickFor[key] += device.clicks_to_company_site;
                totalViewsFor[key] += device.certificate_views;
              }
            }
          }
        }
      }
      if (this.selectedOptionFor.PieChart.option == "Month") {
        const element = sortedMonthsObj[this.selectedOptionFor.PieChart.value];
        const devices = element.devices;
        totalViews += element.total_certificate_views;
        totalClicks += element.total_clicks_to_company_site;
        for (const key in devices) {
          if (Object.hasOwnProperty.call(devices, key)) {
            const device = devices[key];
            totalClickFor[key] += device.clicks_to_company_site;
            totalViewsFor[key] += device.certificate_views;
          }
        }
      }

      if (this.selectedOptionFor.PieChart.action == "Clicks") {
        for (const key in totalClickFor) {
          if (Object.hasOwnProperty.call(totalClickFor, key)) {
            const clicks = totalClickFor[key];
            let percentage = Math.round((clicks / totalClicks) * 100);
            if (isNaN(percentage)) percentage = 0;
            this.pieChart.data.labels.push(`${percentage}%`);
            this.pieChart.data.series.push({ meta: key, value: percentage });
          }
        }
      }

      if (this.selectedOptionFor.PieChart.action == "Views") {
        for (const key in totalViewsFor) {
          if (Object.hasOwnProperty.call(totalViewsFor, key)) {
            const views = totalViewsFor[key];
            const percentage = Math.round((views / totalViews) * 100);
            this.pieChart.data.labels.push(`${percentage}%`);
            this.pieChart.data.series.push({ meta: key, value: percentage });
          }
        }
      }

      this.renderChart = true;
      setTimeout(() => {
        this.renderChart = false;
        this.$nextTick();
        this.$forceUpdate();
      }, 1000);
    },
    handleMapFilters(disaplyedAnalyticsData) {
      //   const totalClickFor = {
      //     Android: 0,
      //     iOS: 0,
      //     Desktop: 0,
      //   };
      //   const totalViewsFor = {
      //     Android: 0,
      //     iOS: 0,
      //     Desktop: 0,
      //   };

      const country_code = require("./country_code.json");
      const analytics = disaplyedAnalyticsData;
      this.mapData = {};

      // Convert the object to an array of key-value pairs
      const monthsArray = Object.entries(analytics);

      // Sort the array based on the desired order
      monthsArray.sort((a, b) => {
        const indexA = this.analyticsAttributes.months.indexOf(a[0]);
        const indexB = this.analyticsAttributes.months.indexOf(b[0]);
        return indexA - indexB;
      });
      // Convert the sorted array back to an object
      const sortedMonthsObj = Object.fromEntries(monthsArray);

      for (const key in sortedMonthsObj) {
        if (Object.hasOwnProperty.call(sortedMonthsObj, key)) {
          const element = sortedMonthsObj[key];
          const regions = element.regions;

          for (const key in regions) {
            if (Object.hasOwnProperty.call(regions, key)) {
              const region = regions[key];
              const code = country_code[key];
              if (!this.mapData[code]) {
                this.mapData[code] = 0;
              }
              if (this.selectedOptionFor.Map.action == "Clicks")
                this.mapData[code] += region.clicks_to_company_site;
              if (this.selectedOptionFor.Map.action == "Views")
                this.mapData[code] += region.certificate_views;
            }
          }

          // for (const key in devices) {
          //   if (Object.hasOwnProperty.call(devices, key)) {
          //     const device = devices[key];
          //     totalClickFor[key] += device.clicks_to_company_site;
          //     totalViewsFor[key] += device.certificate_views;
          //   }
          // }
        }
      }

      //   if (this.selectedOptionFor.Map.option == "Entire Year") {

      //   }
      //   if (this.selectedOptionFor.Map.option == "Month") {
      //     const element = sortedMonthsObj[this.selectedOptionFor.Map.value];
      //     const devices = element.devices;
      //     totalViews += element.total_certificate_views;
      //     totalClicks += element.total_clicks_to_company_site;
      //     for (const key in devices) {
      //       if (Object.hasOwnProperty.call(devices, key)) {
      //         const device = devices[key];
      //         totalClickFor[key] += device.clicks_to_company_site;
      //         totalViewsFor[key] += device.certificate_views;
      //       }
      //     }
      //   }
    },
  },
  watch: {
    // filterBy: {
    //   handler(val) {
    //     this.selectedYear = this.filterBy.year;
    //     if (!this.filterBy.client) {
    //       return Swal.fire("Please select a client to continue");
    //     }
    //     this.disaplyedAnalyticsData = this.analytics.find(
    //       (d) => d.id == this.filterBy.client
    //     );
    //     if (!this.disaplyedAnalyticsData) {
    //       this.resetAnalytics();
    //       return Swal.fire("No Record for this client as of yet");
    //     }
    //     if (!this.disaplyedAnalyticsData.hasOwnProperty(this.selectedYear)) {
    //       return Swal.fire(
    //         "Theres no record for analytics for this client on the selected year"
    //       );
    //     }
    //     this.fetchClientCertificates(this.filterBy.client);
    //     this.analytics_totals.views = 0;
    //     this.analytics_totals.clicks = 0;
    //     this.showNumbers = false;
    //     const totalAmountViews = [];
    //     const totalAmountClicks = [];
    //     const totalClickFor = {
    //       Android: 0,
    //       iOS: 0,
    //       Desktop: 0,
    //     };
    //     const totalViewsFor = {
    //       Android: 0,
    //       iOS: 0,
    //       Desktop: 0,
    //     };
    //     let totalViews = 0;
    //     let totalClicks = 0;
    //     const analytics = this.disaplyedAnalyticsData[this.filterBy.year];
    //     // Convert the object to an array of key-value pairs
    //     const monthsArray = Object.entries(analytics);
    //     // Sort the array based on the desired order
    //     monthsArray.sort((a, b) => {
    //       const indexA = this.analyticsAttributes.months.indexOf(a[0]);
    //       const indexB = this.analyticsAttributes.months.indexOf(b[0]);
    //       return indexA - indexB;
    //     });
    //     // Convert the sorted array back to an object
    //     const sortedMonthsObj = Object.fromEntries(monthsArray);
    //     if (this.filterBy.certificate == "ALL") {
    //       for (const key in sortedMonthsObj) {
    //         if (Object.hasOwnProperty.call(sortedMonthsObj, key)) {
    //           const element = sortedMonthsObj[key];
    //           totalViews += element.total_certificate_views;
    //           totalClicks += element.total_clicks_to_company_site;
    //           this.analytics_totals.views = totalViews;
    //           this.analytics_totals.clicks = totalClicks;
    //           totalAmountViews.push(
    //             Object.assign({
    //               meta: "Certificate Views",
    //               value: element.total_certificate_views,
    //             })
    //           );
    //           totalAmountClicks.push(
    //             Object.assign({
    //               meta: "Clicks",
    //               value: element.total_clicks_to_company_site,
    //             })
    //           );
    //           const devices = element.devices;
    //           for (const key in devices) {
    //             if (Object.hasOwnProperty.call(devices, key)) {
    //               const device = devices[key];
    //               totalClickFor[key] += device.clicks_to_company_site;
    //               totalViewsFor[key] += device.certificate_views;
    //             }
    //           }
    //         }
    //       }
    //     } else {
    //       for (const key in sortedMonthsObj) {
    //         if (Object.hasOwnProperty.call(sortedMonthsObj, key)) {
    //           const element = sortedMonthsObj[key];
    //           if (element.certificates.hasOwnProperty(this.filterBy.certificate)) {
    //             const certificate = element.certificates[this.filterBy.certificate];
    //             totalViews += certificate.total_certificate_views;
    //             totalClicks += certificate.total_clicks_to_company_site;
    //             this.analytics_totals.views = totalViews;
    //             this.analytics_totals.clicks = totalClicks;
    //             totalAmountViews.push(
    //               Object.assign({
    //                 meta: "Certificate Views",
    //                 value: certificate.total_certificate_views,
    //               })
    //             );
    //             totalAmountClicks.push(
    //               Object.assign({
    //                 meta: "Clicks",
    //                 value: certificate.total_clicks_to_company_site,
    //               })
    //             );
    //             const devices = certificate.devices;
    //             for (const key in devices) {
    //               if (Object.hasOwnProperty.call(devices, key)) {
    //                 const device = devices[key];
    //                 totalClickFor[key] += device.clicks_to_company_site;
    //                 totalViewsFor[key] += device.certificate_views;
    //                 console.log({ totalClickFor, totalViewsFor });
    //               }
    //             }
    //           } else {
    //             totalAmountViews.push(
    //               Object.assign({
    //                 meta: "Certificate Views",
    //                 value: 0,
    //               })
    //             );
    //             totalAmountClicks.push(
    //               Object.assign({
    //                 meta: "Clicks",
    //                 value: 0,
    //               })
    //             );
    //           }
    //         }
    //       }
    //     }
    //     this.pieChart.data.labels = [];
    //     this.pieChart.data.series = [];
    //     if (this.selectedOptionFor.PieChart.action == "Clicks") {
    //       for (const key in totalClickFor) {
    //         if (Object.hasOwnProperty.call(totalClickFor, key)) {
    //           const clicks = totalClickFor[key];
    //           const percentage = Math.round((clicks / totalClicks) * 100);
    //           this.pieChart.data.labels.push(`${percentage}%`);
    //           this.pieChart.data.series.push({
    //             meta: key,
    //             value: percentage,
    //           });
    //         }
    //       }
    //     }
    //     if (this.selectedOptionFor.PieChart.action == "Views") {
    //       for (const key in totalViewsFor) {
    //         if (Object.hasOwnProperty.call(totalViewsFor, key)) {
    //           const views = totalViewsFor[key];
    //           const percentage = Math.round((views / totalViews) * 100);
    //           this.pieChart.data.labels.push(`${percentage}%`);
    //           this.pieChart.data.series.push({
    //             meta: key,
    //             value: percentage,
    //           });
    //         }
    //       }
    //     }
    //     this.colouredBarsChart.data.series = [
    //       totalAmountViews,
    //       totalAmountClicks,
    //     ];
    //     this.multipleBarsChart.data.series = [
    //       totalAmountViews,
    //       totalAmountClicks,
    //     ];
    //     this.handleMapFilters(this.disaplyedAnalyticsData);
    //     this.renderChart = true;
    //     setTimeout(() => {
    //       this.renderChart = false;
    //       this.$nextTick();
    //       this.$forceUpdate();
    //       this.showNumbers = true;
    //     }, 1000);
    //   },
    //   deep: true,
    // },
  },
};
</script>

<style scoped>
.map-big {
  height: 500px !important;
}
/* Styling for the clickable text */
.clickable-text {
  cursor: pointer;
  text-decoration: underline;
  color: #888; /* Light grayish color */
  display: inline-block;
}

/* Styling for the dropdown container */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Styling for the dropdown content */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 126px;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 300px;
  overflow-y: auto;
}

/* Styling for the dropdown options */
.dropdown-content a {
  color: rgb(54, 153, 14) !important;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  border: 0.5px solid black;
}
.second-dropdown {
  position: absolute;
  top: 0;
  left: 100%;
}
/* Hover effect for dropdown options */
/* .dropdown-content a:hover {
  background-color: #ddd;
  color: black;
  cursor: pointer;
} */

/* Show the dropdown content when the clickable text is hovered */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Styling for the second dropdown container */

/* Show the second dropdown content when the "Yearly" option is selected */
/* .dropdown .clickable-text[data-active="true"] + .second-dropdown {
  display: block;
} */

.title {
  color: black !important;
  /* text-align: center; */
}

.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 5em;
  padding: 0.5em;
  background: #f4c63d;
  color: #453d3f;
  font-family: Oxygen, Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.chartist-tooltip:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -15px;
  border: 15px solid transparent;
  border-top-color: #f4c63d;
}
.chartist-tooltip.tooltip-show {
  opacity: 1;
}

.ct-area,
.ct-line {
  pointer-events: none;
}

.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}

.container {
  justify-content: space-around !important;
}

.analytics-cotainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
