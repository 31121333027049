<template>
  <div class="attribute-cards light" v-if="attributes.id">
    <md-card-content>
      <form @submit.prevent>
        <h3>{{ attributes.name }}</h3>
        <transition name="fade">
          <div
            class="show-attributes"
            v-if="$parent.active_attribute == attributes.id"
          >
            <p @click="$parent.active_attribute = null" class="dark-text hide">
              hide / close
            </p>
            <div v-if="!attributes.id.includes('templates')" class="another">
              <input
                v-if="attributes.type !== 'Number'"
                type="text"
                v-model="another"
                placeholder="Add a new option here..."
              />

              <div v-else class="number-inputs">
                <input
                  type="number"
                  v-model="another"
                  min="15"
                  max="29"
                  placeholder="Add a number between 15-29 seconds"
                />
                <input
                  type="text"
                  :value="attributes.values"
                  disabled
                  placeholder="Add a new option here..."
                />
              </div>
              <button @click="addAttr" class="btn add_btn_1">Save</button>
            </div>
            <div
              v-if="attributes.type !== 'Number'"
              v-for="(attr, index) in attributes.values"
              :key="index"
              class="attr"
            >
              <div class="attr" v-if="attributes.id.includes('templates')">
                <span style="color: red"
                  >Template ID: {{ attributes.values[index].id }}</span
                >
                <input
                  type="text"
                  name="attr"
                  v-model="attributes.values[index].name"
                  @change="updateAttr"
                />
              </div>

              <input
                v-else
                type="text"
                name="attr"
                v-model="attributes.values[index]"
                @change="updateAttr"
              />
              <button
                v-if="!attributes.id.includes('templates')"
                type="button"
                @click.prevent="deleteAttr(index)"
                class="x_btn"
              >
                X
              </button>
            </div>
          </div>
          <div v-else v-show="authorizedUser">
            <p
              @click="$parent.active_attribute = attributes.id"
              class="dark-text"
            >
              View / edit
            </p>
          </div>
        </transition>
      </form>
    </md-card-content>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "AttributesCard",
  props: ["attributes", "currentUser"],
  data() {
    return {
      feedback: null,
      another: null,
      showModal: false,
      selectedIndex: null,
    };
  },

  methods: {
    authorizedUser() {
      let auth = false;
      if (
        this.currentUser.email == "kelvin@diamondservicesusa.com" ||
        this.currentUser.email == "sam@diamondservicesusa.com"
      )
        auth = true;
      return auth;
    },
    addAttr() {
      if (this.another) {
        if (this.attributes.type !== "Number")
          this.attributes.values.push(this.another);
        else this.attributes.values = Number(this.another);
        this.updateAttr();
        this.another = null;
      } else {
        this.feedback = "You must enter a value";
      }
    },
    deleteAttr(index) {
      if (this.authorizedUser) {
        Swal.fire({
          title: "Are you sure?",
          text: "You will not be able to undo this",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "DELETE",
          cancelButtonText: "CANCEL",
        }).then((result) => {
          if (result.value) {
            this.attributes.values.splice(index, 1);
            this.updateAttr();
          }
        });
      }
    },
    updateAttr() {
      this.$emit("emitAttr", this.attributes);
    },
  },
  created() {},
};
</script>

<style scoped>
.attr {
  margin: 5px;
  width: 100%;
  display: flex;
}

.attr input {
  width: 100%;
  border: none;
  padding: 7px 10px;
}

.attr .x_btn {
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

.btn {
  background-color: green;
}

.attr .another {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 2px solid rgba(234, 234, 234, 0.16);
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.attribute-cards {
  width: 100%;
  max-width: 300px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.25) !important;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 0;
  font-family: Helvetica, Arial, sans-serif;
}

.attribute-cards h3 {
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
}

.attribute-cards .md-card {
  background: transparent !important;
  box-shadow: none;
}

.attribute-cards .md-card-content {
  position: relative;
  background-color: black;
}

.attribute-cards h3 {
  margin-top: 0;
  color: white;
  font-weight: bold;
  margin-bottom: 20px;
}

.dark-text {
  color: rgb(27 105 111) !important;
  font-weight: bold;
  font-size: 15px;
  margin: 0;
  cursor: pointer;
}

.hide {
  margin-bottom: 15px;
}

.another {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  justify-items: center;
  align-items: baseline;
}

.another input {
  margin-bottom: 10px;
  width: 180px;
}

/* .show-attributes {
  position: absolute;
  width: 100%;
  background: #78c5d7;
  z-index: 99;
  left: 0;
  padding: 10px;
} */
</style>
