<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-content>
          <md-button
            @click="() => (this.switchToMult = !this.switchToMult)"
            :style="{ 'background-color': 'green !important' }"
            >{{
              switchToMult ? "Switch Back" : "Multi Complete Item"
            }}</md-button
          >

          <div v-if="switchToMult" class="md-toolbar-section-start">
            You selected {{ selectedItems.length }} Certificates
            <md-button
              @click="completeSelectedItems"
              class="md-just-icon md-info md-simple"
            >
              <md-icon>check_box</md-icon>
              <md-tooltip md-direction="bottom"
                >Complete Item/Create Digital Certificate</md-tooltip
              >
            </md-button>
          </div>
          <!-- Table for Multi Select -->
          <md-table
            style="height: 500px"
            v-if="switchToMult"
            @md-selected="onSelect"
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            :table-header-color="tableHeaderColor"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Search records"
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>
            </md-table-toolbar>

            <md-table-row
              md-selectable="multiple"
              md-auto-select
              slot="md-table-row"
              slot-scope="{ item }"
            >
              <md-table-cell md-label="Cert #">{{
                item.CertNum
              }}</md-table-cell>
              <md-table-cell md-label="Template">{{
                item.Template.name
              }}</md-table-cell>
              <md-table-cell md-label="Order #">{{
                item.OrderNum
              }}</md-table-cell>
              <md-table-cell md-label="Line #">{{
                item.LineNum
              }}</md-table-cell>
              <md-table-cell md-label="Type">{{ item.Type }}</md-table-cell>
              <md-table-cell md-label="Client">{{
                item.Company.name
              }}</md-table-cell>
            </md-table-row>
          </md-table>

          <!-- Table for Single Item -->
          <md-table
            v-show="!switchToMult"
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            :table-header-color="tableHeaderColor"
            style="height: 500px"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Search records"
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Cert #">{{
                item.CertNum
              }}</md-table-cell>
              <md-table-cell md-label="Template">{{
                item.Template.name
              }}</md-table-cell>
              <md-table-cell md-label="Type">{{ item.Type }}</md-table-cell>
              <md-table-cell md-label="Client">
                <v-select
                  :appendToBody="true"
                  :deselectFromDropdown="true"
                  v-model="item.Company"
                  label="name"
                  :reduce="(t) => t"
                  :closeOnSelect="true"
                  :clearable="false"
                  :options="clients"
                  @input="saveClient($event, item)"
                >
                </v-select>
              </md-table-cell>

              <md-table-cell md-label="Video Path">
                <input type="text" v-model="item.Video.name" />
              </md-table-cell>
              <md-table-cell md-label="Actions">
                <md-button
                  class="md-just-icon md-info md-simple"
                  @click.native="handleCheckedItem(item)"
                >
                  <md-icon>check_box</md-icon>
                  <md-tooltip md-direction="bottom"
                    >Complete Item/Create Digital Certificate</md-tooltip
                  >
                </md-button>
                <md-button
                  class="md-just-icon md-warning md-simple"
                  @click.native="handleEdit(item)"
                >
                  <md-icon>dvr</md-icon>
                  <md-tooltip md-direction="bottom"
                    >Go To Detail Page</md-tooltip
                  >
                </md-button>
                <md-button
                  class="md-just-icon md-danger md-simple"
                  @click.native="handleModal(item)"
                >
                  <md-icon>open_in_new</md-icon>
                  <md-tooltip md-direction="bottom">Quick Preview</md-tooltip>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>

          <div class="footer-table md-table">
            <table>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>

      <!-- Modal for Quick Preview -->
      <modal v-if="showPreviewModal" @close="closeModal">
        <template slot="header">
          <h3>Previewing {{ selectedItem.CertNum }}</h3>
        </template>
        <template slot="body">
          <iframe
            :style="{
              width: '1000px',
              height: '600px',
            }"
            :src="`${originPath}/${selectedItem.Type}/${selectedItem.id}`"
            frameborder="0"
          ></iframe>
        </template>
        <template slot="footer"> </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { db } from "../config/firebaseInit";
import { Pagination, Modal } from "@/components";

import Fuse from "fuse.js";
import Swal from "sweetalert2";

export default {
  name: "order-table",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
    tableData: {
      type: Array,
      default: [],
    },
  },
  components: {
    Pagination,
    Modal,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 100, 500],
        total: 0,
      },
      footerTable: [
        "Cert #",
        "Template",
        "Type",
        "Client",
        "Video Path",
        "Actions",
      ],
      searchQuery: "",
      propsToSearch: ["OrderNum", "LineNum", "CertNum", "Customer"],
      searchedData: [],
      clients: [],
      fuseSearch: null,
      items: [],
      selectedItems: [],
      selectedItem: {},
      showPreviewModal: false,
      originPath: null,
      switchToMult: false,
    };
  },
  mounted() {
    // Fuse search initialization.
  },
  created() {
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ["OrderNum", "LineNum", "CertNum"],
      threshold: 0.3,
    });
    this.originPath = window.location.origin;
    this.getAllClients();
  },
  methods: {
    saveClient(client, item) {
      return db
        .doc(`${item.Type.toLowerCase()}/${item.CertNum}`)
        .update({ Company: client });
    },
    async getAllClients() {
      const clients = await db.collection("companies").get();
      this.clients = clients.docs.map((d) =>
        Object.assign({ id: d.id }, d.data())
      );
    },
    completeSelectedItems() {
      const batch = db.batch();
      this.selectedItems.forEach((item) => {
        const itemRef = db.doc(`${item.Type.toLowerCase()}/${item.id}`);
        batch.update(itemRef, { NewlyAdded: false});
      });
      return batch.commit().then(() => {
        this.selectedItems = [];
        this.switchToMult = false;
        this.$emit("fetch-data");
      });
    },
    onSelect(data) {
      this.selectedItems = data;
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    handleCheckedItem(item) {
      if (item.Company.id) {
        Swal.fire({
          title: `You checked Item ${item.CertNum} To Be Completed`,
          buttonsStyling: false,
          type: "success",
          confirmButtonClass: "md-button md-success",
        }).then(() => {
          return db
            .doc(`${item.Type.toLowerCase()}/${item.id}`)
            .update({ NewlyAdded: false, update_spreadsheet: true })
            .then(() => this.$emit("fetch-data"));
        });
      } else {
        Swal.fire({
          title: "Cannot Complete Item: " + item.CertNum,
          text: `Doesn't have a Client selected. Please Select one for this certificate to continue`,
          buttonsStyling: false,
          type: "warning",
          confirmButtonClass: "md-button md-success",
        });
      }
    },
    handleEdit(item) {
      Swal.fire({
        title: `You want to edit item: ${item.CertNum}?`,
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonClass: "md-button md-success btn-fill",
        cancelButtonClass: "md-button md-danger btn-fill",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$router.push(`/${item.Type.toLowerCase()}/${item.id}`);
        }
      });
    },
    handleModal(item) {
      this.selectedItem = item;
      this.showPreviewModal = true;
    },
    closeModal() {
      this.selectedItem = {};
      this.showPreviewModal = false;
    },
    deleteRow(item) {
      let indexToDelete = this.tableData.findIndex(
        (tableRow) => tableRow.id === item.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
