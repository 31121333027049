<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card class="light">
        <md-card-content>
          <div class="filters">
            <div class="filter">
              <p>Filter By Client</p>
              <v-select
                :appendToBody="true"
                :deselectFromDropdown="true"
                v-model="filterBy.companies"
                :options="companies"
                multiple
                label="name"
                :reduce="(p) => p.id"
                :closeOnSelect="false"
              >
              </v-select>
            </div>

            <div
              class="filter filter-btn"
              style="width: 200px; bottom: 5px; position: absolute; right: 0"
            >
              <md-button
                style="background-color: red !important"
                class="text-btn"
                @click="clearFilters"
                >Clear Filters</md-button
              >
            </div>
          </div>
        </md-card-content>
      </md-card>

      <md-card>
        <md-card-content>
          <div
            class="btn-row stats_holder"
            style="display: flex; flex-direction: row"
          >
            <md-button
              :class="
                showPrintingOptions
                  ? 'md-accent red-btn'
                  : 'md-primary md-round md-small green-btn'
              "
              @click="
                () => {
                  if (showPrintingOptions) {
                    showPrintingOptions = false;
                    showSelectableTable = false;
                    selectedExportData = [];
                  } else {
                    showPrintingOptions = true;
                    showSelectableTable = true;
                  }
                }
              "
            >
              {{
                showPrintingOptions ? "Cancel" : "Print Multiple QR Codes"
              }}</md-button
            >
            <md-button
              v-if="!showPrintingOptions"
              class="md-primary md-round md-small"
              @click="newList"
              >New QR Digital List</md-button
            >
          </div>
          <md-table
            v-if="!showSelectableTable"
            md-fixed-header
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Search records"
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Created" md-sort-by="createdOn">
                {{ item.createdOn | format_date }}
              </md-table-cell>
              <md-table-cell md-label="Title" md-sort-by="title">
                {{ item.title }}
              </md-table-cell>
              <md-table-cell md-label="Client" md-sort-by="client">
                {{ item.client.name }}
              </md-table-cell>
              <md-table-cell md-label="Actions" class="btn-row">
                <md-button
                  style="margin-right: 10px"
                  @click="printQRCode(item)"
                  class="md-just-icon md-warning md-simple"
                >
                  <md-icon>print</md-icon>
                  <md-tooltip>Print QR Code</md-tooltip>
                </md-button>
                <a
                  style="margin-right: 10px"
                  class="md-danger md-simple"
                  :href="`https://certificates.diamondservicesusa.com/digitalcertlist/${item.id}`"
                >
                  <md-icon style="color: green">visibility</md-icon>
                  <md-tooltip>View Digital List Page</md-tooltip>
                </a>
                <a
                  style="margin-right: 10px"
                  class="md-danger md-simple"
                  :href="`/digital-cert-qr/${item.id}`"
                >
                  <md-icon>dvr</md-icon>
                  <md-tooltip>View Details</md-tooltip>
                </a>
                <md-button
                  class="md-just-icon md-danger md-simple"
                  @click.native="handleDelete(item)"
                >
                  <md-icon>delete</md-icon>
                  <md-tooltip>Delete List</md-tooltip>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>

          <SelectableTable
            v-else
            @onSelected="onSelected"
            :tableData="queriedData"
            :tableInfo="exportItemsInfo"
          >
            <template v-slot:btns>
              <div style="display: flex; flex-direction: column">
                <div>
                  <p class="required">Can Only Select Two At A Time</p>
                </div>
                <div>
                  <md-button
                    v-if="showPrintingOptions"
                    class="md-primary"
                    @click="handleMultiplePrints"
                    >Print Selected Items</md-button
                  >
                </div>
              </div>
            </template>
          </SelectableTable>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
    <div
      v-if="!showSelectableTable"
      class="qr-code-container"
      ref="qrCodeContainer"
    >
      <qr-code-wrapper
        class="qrcode"
        render-as="svg"
        :value="selectedQR"
      ></qr-code-wrapper>
    </div>

    <div v-else>
      <div
        v-for="item in selectedItems"
        :key="item.id"
        class="qr-code-container"
        :ref="`qrCodeContainer-${item.id}`"
      >
        <qr-code-wrapper
          class="qrcode"
          render-as="svg"
          :value="`https://certificates.diamondservicesusa.com/digitalcertlist/${item.id}`"
        ></qr-code-wrapper>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { Pagination } from "@/components";
import Fuse from "fuse.js";
import { db, storage } from "@/config/firebaseInit";
import Swal from "sweetalert2";
import router from "@/router";
import SelectableTable from "@/components/SelectableTable.vue";

export default {
  components: {
    Pagination,
    SelectableTable,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      selectedQR: "",
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        perPage: 100,
        currentPage: 1,
        perPageOptions: [50, 100, 250, 500],
        total: 0,
      },
      searchQuery: "",
      showSelectableTable: false,
      showPrintingOptions: false,
      propsToSearch: ["name", "url"],
      tableData: [],
      searchedData: [],
      unfilteredItems: [],
      selectedItems: [],
      fuseSearch: null,
      companies: [],
      filterBy: {
        companies: [],
      },
      exportItemsInfo: [
        {
          label: "Title",
          key: "title",
        },
      ],
    };
  },
  firestore() {
    return {
      unfilteredItems: db.collection("digital_certificate_lists"),
      // .orderBy("createdOn", "desc"),
      companies: db.collection("companies").orderBy("name", "asc"),
    };
  },
  methods: {
    handleMultiplePrints() {
      if (this.selectedItems.length && this.selectedItems.length <= 2) {
        const printWindow = window.open("", "_blank");
        const content = document.createElement("div");
        content.style.cssText =
          "display: flex; justify-content: space-evenly; width: 300px; margin: 0 auto;";

        this.selectedItems.forEach((item) => {
          // this.selectedQR = `https://certificates.diamondservicesusa.com/digitalcertlist/${item.id}`;
          // Get the QR code element by its ID or class
          //   const qrCodeElement = document.querySelector(".qrcode > svg");
          //   // Create a new window for printing
          //   const printWindow = window.open("", "_blank");

          const qrCodeContainer = this.$refs[`qrCodeContainer-${item.id}`][0];
          console.log(qrCodeContainer);
          const qrCodeSvg = qrCodeContainer.querySelector("svg");
          content.innerHTML += `
              <div style="
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;"
              >
              <h5>${item.title}</h5>
              ${qrCodeSvg.outerHTML}
              </div>`;

          // Set the content of the new window to the QR code image
        });

        printWindow.document.write(content.outerHTML);
        printWindow.document.close();
        // Trigger the print functionality
        printWindow.print();
        // this.selectedQR = null;
        setTimeout(() => {
          this.selectedQR = null;
          this.showSelectableTable = false;
          this.showPrintingOptions = false;
          this.selectedItems = [];
        }, 3000);
      }
    },
    onSelected(items) {
      this.selectedItems = items;
    },
    printQRCode(item) {
      this.selectedQR = `https://certificates.diamondservicesusa.com/digitalcertlist/${item.id}`;
      // Get the QR code element by its ID or class
      //   const qrCodeElement = document.querySelector(".qrcode > svg");
      //   // Create a new window for printing
      //   const printWindow = window.open("", "_blank");
      const qrCodeContainer = this.$refs.qrCodeContainer;
      const qrCodeSvg = qrCodeContainer.querySelector("svg");
      const printWindow = window.open("", "_blank");
      const content = document.createElement("div");
      content.innerHTML = `
              <div style=" 
                display: flex;
                flex-direction: column;
                margin: 0 auto;
                align-items: center;
                justify-content: center;
                justify-items: center;"
              > 
              <h5>${item.title}</h5>
              ${qrCodeSvg.outerHTML} 
              </div>`;

      // Set the content of the new window to the QR code image
      printWindow.document.write(content.innerHTML);
      printWindow.document.close();
      // Trigger the print functionality
      printWindow.print();
      // // this.selectedQR = null;
      setTimeout(() => {
        this.selectedQR = null;
      }, 3000);
    },

    clearFilters() {
      for (const key in this.filterBy) {
        if (key === "startDate" || key === "endDate") {
          this.filterBy[key] = null;
        } else {
          this.filterBy[key] = [];
        }
      }
      this.searchVal = null;
    },
    handleSearch(e) {
      const value = e;
      if (!value) {
        return (this.tableData = this.unfilteredItems);
      }
      this.tableData = this.unfilteredItems.filter((i) => {
        let cert = i.CertNum;
        let sku = i.ClientSKU;
        return cert.includes(value) || sku.includes(value);
      });
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    newList() {
      let ref = db.collection("digital_certificate_lists").doc();
      this.handleEdit(ref);
    },
    handleEdit(item) {
      let id = item.id;
      router.push(`/digital-cert-qr/${id}`);
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    removeMedia(item) {
      const imageRef = storage.ref(item.meta.ref);
      // Delete the file
      return imageRef
        .delete()
        .then(() => true)
        .catch((error) => {
          this.displayError(error);
        });
    },
    displayError(error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log({ errorCode, errorMessage });
      return Swal.fire(
        "Error!",
        `${errorMessage} - code: ${errorCode}. Please try again or contact support.`,
        "error"
      );
    },
    handleDelete(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to undo this",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "DELETE",
        cancelButtonText: "CANCEL",
      }).then((result) => {
        if (result.value) {
          return db
            .collection("digital_certificate_lists")
            .doc(item.id)
            .delete()
            .then(() => this.removeMedia(item))
            .then(() => {
              Swal.fire("Deleted!", "The file has been deleted.", "success");
            });
        }
      });
    },
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.unfilteredItems, {
      keys: ["title"],
      threshold: 0.3,
    });
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
    unfilteredItems: {
      deep: true,
      handler() {
        if (!this.unfilteredItems.length) {
          this.tableData = this.unfilteredItems;
        }
      },
    },
    filterBy: {
      deep: true,
      async handler() {
        // let ref = db.collection("digital_certificate_videos");
        let filteredItems = this.unfilteredItems;
        if (!this.filterBy.companies.length) {
          this.tableData = this.unfilteredItems;
          return false;
        }
        // if (this.filterBy.startDate && this.filterBy.endDate) {
        //   let startDate = new Date(this.filterBy.startDate).getTime();
        //   let endDate = new Date(this.filterBy.endDate).setHours(24, 0, 0, 0);
        //   ref = ref.orderBy("created").startAt(startDate).endAt(endDate);
        //   let container = await ref.get();
        //   filteredItems = container.docs
        //     .map((d) => Object.assign({ id: d.id }, d.data()))
        //     .sort((a, b) => (a.created > b.created ? -1 : 1));
        // }

        this.tableData = filteredItems.filter((item) => {
          let company = item.client.id;
          return (
            !this.filterBy.companies.length ||
            this.filterBy.companies.includes(company)
          );
        });
      },
    },
  },
};
</script>
  
  <style lang="css" scoped>
.qr-code-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.qr-code-container {
  display: none;
}

.red-btn {
  background-color: red !important;
}

.green-btn {
  background-color: green !important;
}
</style>
  