<template>
  <div id="worldMap"></div>
</template>
<script>
import $ from "jquery";

export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    action: {
      type: String,
      default: "Views",
    },
  },

  methods: {
    initVectorMap() {
      window.$("#worldMap").vectorMap({
        map: "world_mill_en",
        backgroundColor: "transparent",
        zoomOnScroll: false,
        regionStyle: {
          initial: {
            fill: "#e4e4e4",
            "fill-opacity": 0.9,
            stroke: "none",
            "stroke-width": 0,
            "stroke-opacity": 0,
          },
        },
        series: {
          regions: [
            {
              values: this.data,
              scale: ["#C8EEFF", "#0071A4"],
              normalizeFunction: "polynomial",
            },
          ],
        },
        onRegionTipShow: (e, el, code) => {
          el.html(
            el.html() +
              `(Total: ${this.action} - ` +
              `${this.data[code] || 0}` +
              ")"
          );
        },
      });
    },
  },
  async mounted() {
    window.$ = window.jQuery = $;
    await import("jvectormap-next");
    await import("./world_map");
    this.initVectorMap();
  },
};
</script>
<style>
</style>
