<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card class="light">
        <md-card-content>
          <md-field class="search-bar">
            <md-input
              type="search"
              clearable
              placeholder="Search an imperfection by its name"
              @input="handleSearch"
            ></md-input>
          </md-field>

          <div class="filters">
            <div class="filter">
              <p>Start From:</p>
              <md-datepicker
                @input="validateDateRange('startDate')"
                v-model="filterBy.startDate"
              />
            </div>
            <div class="filter">
              <p>End To:</p>
              <md-datepicker
                @input="validateDateRange('endDate')"
                v-model="filterBy.endDate"
              />
            </div>

            <div class="filter">
              <p>Filter By Shapes</p>
              <v-select
                :appendToBody="true"
                :deselectFromDropdown="true"
                v-model="filterBy.shapes"
                :options="shapes"
                multiple
                :closeOnSelect="false"
              >
                <template v-slot:option="option">
                  <input
                    type="checkbox"
                    :value="option.label"
                    v-model="filterBy.shapes"
                  />
                  {{ option.label }}
                </template>
              </v-select>
            </div>

            <div class="filter">
              <p>Filter By Imperfections</p>
              <v-select
                :appendToBody="true"
                :deselectFromDropdown="true"
                v-model="filterBy.imperfections"
                :options="imperfections"
                multiple
                :closeOnSelect="false"
              >
                <template v-slot:option="option">
                  <input
                    type="checkbox"
                    :value="option.label"
                    v-model="filterBy.imperfections"
                  />
                  {{ option.label }}
                </template>
              </v-select>
            </div>
            <div class="filter">
              <md-checkbox v-model="showCompletedItems"
                ><span class="span-checkbox"
                  >Show Completed Imperfections</span
                ></md-checkbox
              >
              <md-checkbox v-model="showPendingItems"
                ><span class="span-checkbox"
                  >Show Pending Imperfections</span
                ></md-checkbox
              >
            </div>

            <div
              class="filter filter-btn"
              style="
                    width: 200px;
                    bottom: 5px;
                    position: absolute;
                    right: 0;
                "
            >
              <md-button
                style="background-color: red !important;"
                class="text-btn"
                @click="clearFilters"
                >Clear Filters</md-button
              >
            </div>
          </div>
        </md-card-content>
      </md-card>

      <md-card>
        <md-card-content>
          <div class="btn-row stats_holder">
            <md-button
              class="md-primary md-round md-small"
              @click="newImperfection"
              >New Imperfection</md-button
            >
          </div>

          <md-table
            md-fixed-header
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
            id="diamonds-table"
          >
            <md-table-toolbar :style="{ marginBottom: '20px' }">
              <md-field>
                <label for="diamond">Per page</label>
                <md-select v-model="pagination.perPage" name="imperfections">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                    >{{ item }}</md-option
                  >
                </md-select>
              </md-field>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Created On" md-sort-by="created">{{
                item.created | format_date
              }}</md-table-cell>
              <md-table-cell md-label="Status" md-sort-by="printed">
                <p class="status" :class="{ complete: item.printed }">
                  {{ item.printed ? "DONE" : "DRAFT" }}
                </p>
              </md-table-cell>
              <md-table-cell md-label="Name" md-sort-by="name">{{
                item.name
              }}</md-table-cell>
              <md-table-cell md-label="Shape">{{
                item.diamond_cut.name
              }}</md-table-cell>
              <md-table-cell md-label="Imperfections">
                <div class="imperfections">
                  <img
                    class="imperfection"
                    v-for="(imperfection, index) in item.imperfectionAddedList"
                    :key="index"
                    :src="
                      require('@/assets/img/imperfections/' +
                        imperfection.image)
                    "
                  />
                </div>
              </md-table-cell>
              <md-table-cell md-label="Proportions">
                <input
                  :ref="item.id"
                  type="file"
                  @change="upload_proportions($event, item)"
                  accept="image/*"
                  class="hide_upload"
                />

                <div
                  class="upload_click"
                  @click="click_upload_proportions(item.id)"
                >
                  <img
                    class="upload_btn"
                    style="width: 40px"
                    src="@/assets/img/upload-button.svg"
                  />
                  <p v-if="!item.proportions_input" class="upload-text">
                    Upload
                  </p>
                  <img
                    v-else
                    class="proportions_input"
                    :src="item.proportions_input"
                  />
                </div>
              </md-table-cell>
              <md-table-cell md-label="Connect">
                <p
                  v-if="
                    item.proportions_input &&
                      !item.printed &&
                      item.imperfection_connected
                  "
                  @click="connect(item)"
                  class="remove_connected_message"
                >
                  Edit Connection
                </p>
                <p
                  v-if="!item.proportions_input"
                  class="connect_warning_proportions"
                >
                  Missing: Proportions Upload
                </p>

                <p
                  v-if="item.proportions_input && !item.imperfection_connected"
                  class="connect_message"
                  @click="connect(item)"
                >
                  Connect Diamond
                </p>
                <p
                  v-if="
                    item.proportions_input &&
                      item.imperfection_connected &&
                      item.printed
                  "
                  @click="connect(item)"
                  class="connected_message"
                >
                  Disconnect Diamond
                </p>
              </md-table-cell>
              <md-table-cell md-label="Certification">
                <div>
                  <p
                    v-if="item.proportions_input && item.imperfection_connected"
                    @click="pamphlet(item.id)"
                    class="remove_connected_message"
                  >
                    Print
                  </p>
                  <p
                    v-if="
                      !item.proportions_input && !item.imperfection_connected
                    "
                    class="connect_warning"
                  >
                    Missing: Connect and Proportions Upload
                  </p>
                  <p
                    v-if="
                      !item.imperfection_connected && item.proportions_input
                    "
                    class="connect_warning"
                  >
                    Missing: Connect
                  </p>
                  <p
                    v-if="
                      !item.proportions_input && item.imperfection_connected
                    "
                    class="connect_warning"
                  >
                    Missing: Proportions Upload
                  </p>
                </div>
              </md-table-cell>
              <md-table-cell class="actions" md-label="Controls">
                <md-tooltip md-direction="bottom">{{ tipInfo }}</md-tooltip>
                <div>
                  <img
                    @mouseover="tipInfo = 'View Details'"
                    @click="showDetails(item)"
                    src="@/assets/img/info.svg"
                  />
                  <img
                    @mouseover="tipInfo = 'View Imperfection Image'"
                    @click="viewImage(item)"
                    src="@/assets/img/view.svg"
                  />
                  <img
                    @mouseover="tipInfo = 'Edit'"
                    @click="handleEdit(item)"
                    src="@/assets/img/edit.svg"
                  />
                  <img
                    @mouseover="tipInfo = 'Delete'"
                    @click="remove(item)"
                    src="@/assets/img/delete.svg"
                  />
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>

          <div
            v-if="connect_clicked"
            class="connect_holder_background"
            @click="connect_clicked = false"
          ></div>
          <transition v-if="connect_clicked" name="fade">
            <div class="connect_holder">
              <div>
                <div class="close_popup" @click="connect_clicked = false">
                  <p class="close">x</p>
                </div>
                <div class="admin_details" colspan="100%">
                  <div class="table-search-header">
                    <h2 class="table-search-header-text">
                      Search for a DIS Certification Number to Connect to.
                    </h2>
                    <input
                      type="search"
                      class="mb-3 search-input"
                      clearable
                      style="width: 200px"
                      placeholder="Search by Cert#"
                      v-model="search"
                    />
                  </div>
                  <h3 class="table-header-text">
                    Add Your Imperfections by Selecting a Certificate below
                  </h3>
                  <md-table
                    :value="search_results"
                    class="paginated-table table-striped table-hover"
                  >
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                      <md-table-cell md-label="Cert #">{{
                        item.CertNum
                      }}</md-table-cell>
                      <md-table-cell md-label="Actions">
                        <div
                          v-if="!item.imperfection"
                          @click="
                            selectDiamond(
                              item,
                              {
                                diamond: selectedDiamond,
                                url: selectedDiamond.url,
                              },
                              item.CertNum
                            )
                          "
                          v-bind:class="{
                            select_diamond_btn_imperfections: item.imperfection,
                          }"
                          class="info_text select-btn select_diamond_btn"
                        >
                          Connect
                        </div>
                        <div v-else>
                          <p class="diamond_imperfection_text">
                            Diamond has imperfections
                          </p>
                          <p
                            v-bind:class="{
                              select_diamond_btn_imperfections:
                                item.imperfection,
                            }"
                            class="info_text select_diamond_btn"
                          >
                            Disconnect
                          </p>
                        </div>
                      </md-table-cell>
                    </md-table-row>
                  </md-table>
                </div>
              </div>
            </div>
          </transition>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class>
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          ></pagination>
        </md-card-actions>
      </md-card>
      <popup v-if="showModal" @close="closeModal">
        <template v-slot:body>
          <div class="admin_details" colspan="100%">
            <transition name="fade">
              <div class="admin_info">
                <div class="text" v-if="diamond_pamphlet_summary">
                  <div>
                    <p v-if="diamond_time_stamps.created_timestamp">
                      Created On: {{ diamond_time_stamps.created_timestamp }}
                    </p>
                    <p v-if="diamond_time_stamps.last_edit_timestamp">
                      Last Edit On:
                      {{ diamond_time_stamps.last_edit_timestamp }}
                    </p>
                    <p v-if="diamond_time_stamps.proportions_image_timestamp">
                      Proportions Uploaded On:
                      {{ diamond_time_stamps.proportions_image_timestamp }}
                    </p>
                    <p v-if="diamond_time_stamps.connect_timestamp">
                      Connected On:
                      {{ diamond_time_stamps.connect_timestamp }}
                    </p>
                    <p v-if="diamond_time_stamps.print_timestamp">
                      Last Printed On:
                      {{ diamond_time_stamps.print_timestamp }}
                    </p>
                    <br />
                  </div>
                  <p v-if="diamond_pamphlet_summary.created">
                    Created On:
                    <span>{{
                      diamond_pamphlet_summary.created | format_date
                    }}</span>
                  </p>
                  <p v-if="diamond_pamphlet_summary.CertNum">
                    CertNum:
                    <span>{{ diamond_pamphlet_summary.CertNum }}</span>
                  </p>
                  <p v-if="diamond_pamphlet_summary.Weight">
                    Carat Weight:
                    <span>{{ diamond_pamphlet_summary.Weight }}</span>
                  </p>
                  <p v-if="diamond_pamphlet_summary.Color.value">
                    Color:
                    <span>{{
                      `${diamond_pamphlet_summary.Color.value}${
                        diamond_pamphlet_summary.Color.asterisks
                      }`
                    }}</span>
                  </p>
                  <p v-if="diamond_pamphlet_summary.Clarity.value">
                    Clarity:
                    <span>{{
                      `${diamond_pamphlet_summary.Clarity.value}${
                        diamond_pamphlet_summary.Clarity.asterisks
                      }`
                    }}</span>
                  </p>
                  <p v-if="diamond_pamphlet_summary.Depth">
                    Depth:
                    <span>{{ diamond_pamphlet_summary.Depth }}</span>
                  </p>
                  <p v-if="diamond_pamphlet_summary.Table">
                    Table:
                    <span>{{ diamond_pamphlet_summary.Table }}</span>
                  </p>
                  <p v-if="diamond_pamphlet_summary.Girdle">
                    Girdle:
                    <span>{{ diamond_pamphlet_summary.Girdle }}</span>
                  </p>
                  <p v-if="diamond_pamphlet_summary.Cutlet">
                    Cut:
                    <span>{{ diamond_pamphlet_summary.Cutlet }}</span>
                  </p>
                  <p v-if="diamond_pamphlet_summary.Polish">
                    Polish:
                    <span>{{ diamond_pamphlet_summary.Polish }}</span>
                  </p>
                  <p v-if="diamond_pamphlet_summary.Symmetry">
                    Symmetry:
                    <span>{{ diamond_pamphlet_summary.Symmetry }}</span>
                  </p>
                  <p v-if="diamond_pamphlet_summary.Florescence">
                    Florescence:
                    <span>{{ diamond_pamphlet_summary.Florescence }}</span>
                  </p>
                  <div
                    v-if="diamond_pamphlet_summary.imperfectionAddedList"
                    class="
                                imperfections_key_holder
                                rotated_clarity_char
                              "
                  >
                    <div
                      class="imperfections_key"
                      v-for="(imperfection,
                      index) in diamond_pamphlet_summary.imperfectionAddedList"
                      :key="index"
                    >
                      <p class="imperfections_key_text">
                        {{ imperfection.name }}
                      </p>
                    </div>
                  </div>
                  <p v-if="diamond_pamphlet_summary.Inscription">
                    Inscription:
                    {{ diamond_pamphlet_summary.Inscription }}
                  </p>
                  <p v-if="diamond_pamphlet_summary.cutlet">
                    Certificate Comments:
                    {{ diamond_pamphlet_summary.CertificateComments }}
                  </p>
                </div>
                <div class="images">
                  <div  v-if="diamondDetails.proportions_input">
                    <p>Proportions image:</p>
                    <img
                      ref="proportion_url"
                      class="proportions_image"
                      :src="diamondDetails.proportions_input"
                      alt="Proportions Image"
                    />
                  </div>
                  <div v-if="diamondDetails.url">
                    <p>Imperfection image:</p>
                    <img
                      ref="characteristics_url"
                      class="imperfection_img"
                      :src="diamondDetails.url"
                      alt="Imperfection Template"
                    />
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </template>
      </popup>
    </div>
  </div>
</template>
<script>
import { db, storage, functions, auth } from "@/config/firebaseInit";
import Swal from "sweetalert2";
import firebase from "firebase";
import { Pagination } from "@/components";
import moment from "moment";
import Fuse from "fuse.js";
import router from "@/router";
import Modal from "@/components/Modal.vue";

export default {
  name: "Imperfections",
  components: {
    Pagination,
    popup: Modal,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        perPage: 250,
        currentPage: 1,
        perPageOptions: [250, 500, 1000],
        total: 0,
      },
      imperfections: [
        "abrasion",
        "extra facet",
        "nick",
        "pit",
        "polish lines",
        "scratch",
        "surface graining",
        "internal graining",
        "internal laser drill",
        "bruise",
        "cavity",
        "chip",
        "cloud",
        "crystal",
        "feather",
        "feather filled",
        "indented natural",
        "knot",
        "laser drill hole",
        "natural",
        "needle",
        "pinpoint",
        "twinning wisp",
      ],
      shapes: [
        "Asscher",
        "Cushion",
        "Emerald",
        "Heart",
        "Marquise",
        "Oval",
        "Pear",
        "Round",
        "Princess",
        "Radiant",
      ],
      filterBy: {
        shapes: [],
        imperfections: [],
        startDate: "",
        endDate: "",
      },
      showPendingItems: false,
      showCompletedItems: false,
      searchQuery: "",
      showModal: false,
      tipInfo: "",
      searchVal: "",
      propsToSearch: ["title", "fullname", "description"],
      tableData: [],
      searchedData: [],
      unfilteredItems: [],
      displayNumber: 2,
      lastPaginationRange: null,
      nextPaginationRange: null,
      previousPaginationIndex: 0,
      previousPaginationRange: [],
      pagination_counts: null,
      diamondDetails: {},
      diamond_pamphlet_summary: null,
      searchedTable: [],
      searchTableIndex: 0,
      searchingTable: false,
      selectedDiamond: {},
      search: null,
      query: null,
      search_results: [],
      unfilteredResults: [],
      searchTable: null,
      searchTable_results: null,
      savedDiamonds: [],
      completedDiamonds: [],
      diamondDrafts: [],
      currentTableDiamonds: [],
      displayCompletedTable: true,
      connect_clicked: false,
      name_clicked: false,
      diamond_time_stamps: null,
      connect_diamond: null,
      connect_diamond_id: null,
      searchForImperfections: functions.httpsCallable(
        "algolia-searchForImperfections"
      ),
      getAllDiamonds: functions.httpsCallable("getAllDiamonds"),
      saveImperfectionsToDiamond: functions.httpsCallable(
        "saveImperfectionsToDiamond"
      ),
      removeImperfectionsFromDiamond: functions.httpsCallable(
        "removeImperfectionsFromDiamond"
      ),
      removeCertificateThroughDiamondsAPI: functions.httpsCallable(
        "removeCertificateThroughDiamondsAPI"
      ),
      pamphletDiamond: null,

      options: [],
      CertNum_search: null,
    };
  },
  async created() {
    this.getImperfections();
    // this.toggleTable();
    this.moveToPage(0);
  },
  watch: {
    showCompletedItems(e) {
      if (e) this.tableData = this.unfilteredItems.filter((i) => i.printed);
      else if (!this.showPendingItems) this.tableData = this.unfilteredItems;

      if (e && this.showPendingItems) this.showPendingItems = false;
    },
    showPendingItems(e) {
      if (e) this.tableData = this.unfilteredItems.filter((i) => !i.printed);
      else if (!this.showCompletedItems) this.tableData = this.unfilteredItems;
      if (e && this.showCompletedItems) this.showCompletedItems = false;
    },

    filterBy: {
      deep: true,
      async handler() {
        let ref = db.collection("diamond_imperfections");

        let filteredItems = this.unfilteredItems;
        if (
          !this.filterBy.imperfections.length &&
          !this.filterBy.shapes.length &&
          !this.filterBy.startDate &&
          !this.filterBy.endDate
        ) {
          this.tableData = this.unfilteredItems;
          return false;
        }
        if (this.filterBy.startDate && this.filterBy.endDate) {
          let startDate = new Date(this.filterBy.startDate).getTime();
          let endDate = new Date(this.filterBy.endDate).setHours(23, 59, 0, 0);
          ref = ref
            .orderBy("created_timestamp", "asc")
            .startAt(startDate)
            .endAt(endDate);
          let container = await ref.get();
          filteredItems = container.docs.map((doc) => {
            const item = doc.data();
            let data = {
              created: item.created_timestamp,
              id: doc.id,
              url: item.url,
              name: item.name,
              diamond_cut: item.diamond_cut,
              image_ref: item.image_ref,
              imperfections: item.imperfections,
              imperfectionAddedList: item.imperfectionAddedList,
              canvas_design_data: item.canvas_design_data || null,
              printed: item.printed,
              proportions_input: item.proportions_image,
              imperfection_created_timestamp: item.created,
              imperfection_last_edit_timestamp: item.last_edited,
              imperfection_connect_timestamp: item.imperfection_connect_created,
              proportions_image_created: item.proportions_image_created,
              print_date: item.print_date,
              imperfection_connected: item.imperfection_connect || false,
            };
            return data;
          });
        }

        this.tableData = filteredItems.filter((item) => {
          let shape = item.diamond_cut.name;
          let imperfections = item.imperfectionAddedList.map((i) => i.name);

          return (
            (this.filterBy.imperfections.filter(
              (e) => imperfections.indexOf(e) !== -1
            ).length ||
              !this.filterBy.imperfections.length) &&
            (this.filterBy.shapes.includes(shape) ||
              !this.filterBy.shapes.length)
          );
        });
      },
    },
    searchTable() {
      if (!this.searchTable) {
        if (this.displayCompletedTable) {
          this.searchTable_results = this.CompletedTable;
        } else {
          this.searchTable_results = this.diamondDrafts;
        }
      } else {
        this.fuseTableSearch();
      }
    },
    search() {
      if (!this.search) {
        this.search_results = this.unfilteredResults;
      } else {
        this.fuseSearch(this.search);
      }
    },
  },
  methods: {
    clearFilters() {
      for (const key in this.filterBy) {
        if (key === "startDate" || key === "endDate") {
          this.filterBy[key] = null;
        } else {
          this.filterBy[key] = [];
        }
      }
      this.searchVal = null;
    },
    validateDateRange(key) {
      if (moment(this.filterBy.startDate).isAfter(this.filterBy.endDate)) {
        return Swal.fire(
          "Warning",
          "End date cannot be before start date.",
          "warning"
        ).then(() => {
          this.filterBy.startDate = null;
          this.filterBy.endDate = null;
        });
      }
      if (key === "startDate") {
        this.filterBy.endDate = this.filterBy.startDate;
      }
    },
    customSort(value) {
      // return value.sort((a, b) => {
      //   const sortBy = this.currentSort;
      //   if (this.currentSortOrder === "desc") {
      //     return a[sortBy].toString().localeCompare(b[sortBy].toString());
      //   }
      //   return b[sortBy].toString().localeCompare(a[sortBy].toString());
      // });

      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (sortBy.includes(".")) {
          let sortByA = sortBy.split(".")[0];
          let sortByB = sortBy.split(".")[1];
          if (a[sortByA][sortByB] && b[sortByA][sortByB]) {
            if (this.currentSortOrder === "desc") {
              return a[sortByA][sortByB].localeCompare(b[sortByA][sortByB]);
            }
            return b[sortByA][sortByB].localeCompare(a[sortByA][sortByB]);
          }
          return -1;
        }

        if (this.currentSortOrder === "desc") {
          if (typeof a[sortBy] === "number") {
            return a[sortBy] > b[sortBy] ? -1 : 1;
          }
          return a[sortBy].localeCompare(b[sortBy]);
        }
        if (typeof b[sortBy] === "number") {
          return b[sortBy] > a[sortBy] ? -1 : 1;
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    changeSearchTablePage(page) {
      if (page == 0) {
        this.pagination.current_page = 1;
        this.searchTableIndex = 0;
        this.searchTable_results = this.searchedTable.slice(
          0,
          this.displayNumber
        );
      }
      if (page == -1) {
        this.pagination.current_page--;
        this.searchTableIndex -= this.displayNumber;
        this.searchTable_results = this.searchedTable.slice(
          this.searchTableIndex,
          this.displayNumber
        );
      }
      if (page == 1) {
        this.pagination.current_page++;
        this.searchTableIndex += this.displayNumber;
        this.searchTable_results = this.searchedTable.slice(
          this.searchTableIndex - 1,
          this.displayNumber
        );
      }

      if (page == 2) {
        let last_full_page = Math.floor(
          this.pagination.total / this.displayNumber
        );
        this.pagination.current_page = Math.ceil(
          this.pagination.total / this.displayNumber
        );
        this.searchTableIndex += this.displayNumber;
        this.searchTable_results = this.searchedTable.slice(
          this.searchedTable.length - last_full_page - 1,
          this.displayNumber
        );
      }
    },
    // clearQuerySearch(e) {
    //   this.query = null;
    //   this.getImperfections();
    //   this.pagination.current_page = 1;
    //   this.searchTableIndex = 0;
    //   this.searchedTable = [];
    //   this.searchingTable = false;
    //   if (this.displayCompletedTable) {
    //     this.searchTable_results = this.CompletedTable;
    //   } else {
    //     this.searchTable_results = this.diamondDrafts;
    //   }
    //   return this.moveToPage(0);
    // },
    handleSearch(e) {
      this.tableData = this.unfilteredItems.filter((i) => i.name.includes(e));
    },
    moveToPage(page) {
      var ref = null;
      if (this.displayCompletedTable) {
        ref = db
          .collection("diamond_imperfections")
          .where("user_id", "==", auth.currentUser.uid)
          .where("printed", "==", false);
      } else {
        ref = db
          .collection("diamond_imperfections")
          .where("user_id", "==", auth.currentUser.uid)
          .where("printed", "==", true);
      }
      if (
        page === 1 &&
        this.pagination.current_page + 1 ==
          Math.ceil(this.pagination.total / this.displayNumber)
      ) {
        page = undefined;
      }
      if (page === -1 && this.pagination.current_page - 1 == 1) {
        page = 0;
      }

      if (page === -1) {
        //Previous Page
        ref = ref
          .limit(this.displayNumber)
          .orderBy("last_edited", "desc")
          .startAfter(this.previous_doc);
      } else if (page === 1) {
        //Next Page
        ref = ref
          .limit(this.displayNumber)
          .orderBy("last_edited", "desc")
          .startAfter(this.next_doc);
      } else if (page === 0) {
        //First page
        ref = ref.limit(this.displayNumber).orderBy("last_edited", "desc");
      } else {
        //Last page
        let total = this.pagination.total;
        let last_full_page = Math.floor(total / this.displayNumber);
        let limit = total - last_full_page * this.displayNumber;
        ref = ref
          .limit(limit || this.displayNumber)
          .orderBy("last_edited", "desc");
      }
      ref.onSnapshot((query) => {
        let docs = query.docs;

        if (page === -1 || page === undefined) {
          // counting down
          docs.reverse();
        }
        if (query.size === 0 && page !== 0) {
          if (page === 1) {
            this.next_doc = null;
          } else if (page === -1) {
            this.previous_doc = null;
          }
        } else {
          let results = query.docs.map(function(doc) {
            let data = {
              name: doc.data().name,
              diamond_cut: doc.data().diamond_cut,
              image_ref: doc.data().image_ref,
              imperfections: doc.data().imperfections,
              imperfectionAddedList: doc.data().imperfectionAddedList,
              canvas_design_data: doc.data().canvas_design_data
                ? doc.data().canvas_design_data
                : null,
              printed: doc.data().printed,
              id: doc.id,
              url: doc.data().url,
              proportions_input: doc.data().proportions_image,
              imperfection_created_timestamp: doc.data().created,
              imperfection_last_edit_timestamp: doc.data().last_edited,
              imperfection_connect_timestamp: doc.data()
                .imperfection_connect_created,
              proportions_image_created: doc.data().proportions_image_created,
              print_date: doc.data().print_date,
              imperfection_connected: doc.data().imperfection_connect
                ? doc.data().imperfection_connect
                : false,
            };
            return data;
          });
          this.previous_doc = docs[0];
          this.next_doc = docs[docs.length - 1];
          if (page === 0) {
            this.previous_doc = null;
            this.pagination.current_page = 1;
            if (query.size < this.displayNumber) {
              this.next_doc = null;
            }
          } else if (page === undefined) {
            let total = this.pagination.total;
            let last_full_page = Math.ceil(total / this.displayNumber);
            this.next_doc = null;
            this.pagination.current_page = last_full_page;
          } else {
            this.pagination.current_page += page;
            if (query.size < this.displayNumber) {
              this.pagination[page === -1 ? "previous_doc" : "next_doc"] = null;
            }
          }
          this.searchTable_results = results;
        }
      });
      return true;
    },
    closeModal() {
      this.diamondDetails = {};
      this.diamond_pamphlet_summary = null;
      this.showModal = false;
    },
    async showDetails(imperfection_diamond) {
      this.showModal = true;
      this.diamondDetails = imperfection_diamond;
      this.diamond_time_stamps = {
        created_timestamp: imperfection_diamond.imperfection_created_timestamp,
        last_edit_timestamp:
          imperfection_diamond.imperfection_last_edit_timestamp,
        connect_timestamp: imperfection_diamond.imperfection_connect_timestamp,
        proportions_image_timestamp:
          imperfection_diamond.proportions_image_created,
        print_timestamp: imperfection_diamond.print_date,
      };
      if (imperfection_diamond.imperfection_connected) {
        let querySnapshot = await db
          .collection("diamond")
          .where("imperfection.id", "==", imperfection_diamond.id)
          .get();

        if (querySnapshot.empty) return false;
        let diamond = querySnapshot.docs[0].data();
        this.diamond_pamphlet_summary = {
          created: diamond.created,
          CertNum: diamond.CertNum,
          Weight: diamond.Weight,
          Color: diamond.Color,
          Clarity: diamond.Clarity,
          Depth: diamond.Depth,
          Table: diamond.Table,
          Girdle: diamond.Girdle,
          Cutlet: diamond.Cut,
          Polish: diamond.Polish,
          Symmetry: diamond.Symmetry,
          Florescence: diamond.Fluorescence,
          imperfectionAddedList: diamond.imperfection,
          Inscription: diamond.Inscription,
          CertificateComments: diamond.CertificateComments,
        };
      }
    },

    showTimeStamps(diamond) {
      this.name_clicked = true;
      this.diamond_time_stamps = {
        created_timestamp: diamond.imperfection_created_timestamp,
        last_edit_timestamp: diamond.imperfection_last_edit_timestamp,
        connect_timestamp: diamond.imperfection_connect_timestamp,
        proportions_image_timestamp: diamond.proportions_image_created,
        print_timestamp: diamond.print_date,
      };
    },
    newImperfection() {
      return router.push(`/imperfections/${Date.now().toString()}`);
    },
    handleEdit(item) {
      if (!item.printed) {
        let id = item.id;
        router.push(`/imperfections/${id}`);
      } else {
        Swal.fire("", "Pamphlet has been printed, cannot edit.", "info");
      }
    },
    fuseTableSearch() {
      let vm = this;
      var fuse = null;
      if (this.displayCompletedTable) {
        fuse = new Fuse(this.CompletedTable, {
          keys: ["name"],
          threshold: 0.3,
        });
      } else {
        fuse = new Fuse(this.diamondDrafts, {
          keys: ["name"],
          threshold: 0.3,
        });
      }
      this.searchTable_results = this.searchTable.length
        ? fuse.search(this.searchTable).map(({ item }) => item)
        : fuse.list;
    },
    fuseSearch(value) {
      // let vm = this;
      // let fuse = new Fuse(this.unfilteredResults, {
      //   keys: ["CertNum"],
      //   threshold: 0.3,
      // });
      // this.search_results = this.search.length
      //   ? fuse.search(this.search).map(({ item }) => item)
      //   : fuse.list;

      this.search_results = this.unfilteredResults.filter((r) =>
        r.CertNum.includes(value)
      );
    },
    click_upload_proportions(key) {
      this.$refs[key].click();
    },
    upload_proportions(e, diamond) {
      let created = new Date().toLocaleString();
      const file = e.target.files[0];
      let ref = "images/" + file.name;
      storage
        .ref(ref)
        .put(file)
        .then((response) => {
          response.ref.getDownloadURL().then((downloadURL) => {
            diamond.proportions_input = downloadURL;
            db.collection("diamond_imperfections")
              .doc(diamond.id)
              .update({
                proportions_image: downloadURL,
                proportions_ref: ref,
                proportions_image_created: created,
              });
          });
        })
        .then(() => {
          return Swal.fire("", "Proportions added", "success");
        })
        .catch((err) => console.log(err));
    },
    pamphlet(id) {
      window.open(
        `https://diamonds-imperfections.web.app/pamphlet/${id}`,
        "_blank"
      );
    },
    //
    async getImperfections() {
      let querySnapshot = await db
        .collection("diamond_imperfections")
        .orderBy("created_timestamp", "desc")
        .get();

      // (this.pagination_counts = pagination.data()),
      // this.previousPaginationRange = querySnapshot.docs[0];
      // this.nextPaginationRange =
      //   querySnapshot.docs[querySnapshot.docs.length - 1];

      // this.sortDraftsAndCompleted();
      this.tableData = querySnapshot.docs.map((doc) => {
        const item = doc.data();
        let data = {
          created: item.created_timestamp,
          id: doc.id,
          url: item.url,
          name: item.name,
          diamond_cut: item.diamond_cut,
          image_ref: item.image_ref,
          imperfections: item.imperfections,
          imperfectionAddedList: item.imperfectionAddedList,
          canvas_design_data: item.canvas_design_data || null,
          printed: item.printed,
          proportions_input: item.proportions_image,
          imperfection_created_timestamp: item.created,
          imperfection_last_edit_timestamp: item.last_edited,
          imperfection_connect_timestamp: item.imperfection_connect_created,
          proportions_image_created: item.proportions_image_created,
          print_date: item.print_date,
          imperfection_connected: item.imperfection_connect || false,
        };
        return data;
      });
      this.unfilteredItems = this.tableData;

      // if (this.displayCompletedTable) {
      //   this.searchTable_results = this.diamondDrafts;
      //   this.pagination.total = pagination.data().drafts;
      // } else {
      //   this.searchTable_results = this.completedDiamonds;
      //   this.pagination.total = pagination.data().completed;
      // }
      // this.moveToPage(0);
    },
    sortDraftsAndCompleted() {
      this.tableData.forEach((element) => {
        if (element.printed) {
          this.completedDiamonds.push(element);
        } else {
          this.diamondDrafts.push(element);
        }
      });
    },
    viewImage(diamond) {
      let ref = storage.ref(diamond.image_ref);
      ref
        .getDownloadURL()
        .then(function(url) {
          window.open(url, "_blank");
        })
        .catch(function(error) {
          alert(error.code);
        });
    },
    connect(diamond) {
      let loader = this.$loading.show({
        container: null,
        canCancel: false,
      });

      if (diamond.imperfection_connected) {
        loader.hide();
        this.selectDiamond(
          diamond,
          {
            diamond: diamond,
            url: diamond.url,
          },
          null
        );
      } else {
        this.selectedDiamond = diamond;
        this.getAllDiamonds()
          .then((res) => {
           
            this.search_results = res.data;
            this.unfilteredResults = res.data;
            this.connect_clicked = true;
            loader.hide();
          })
          .catch((err) => {
            loader.hide();
            return Swal.fire("", err.message + " Try again later", "error");
          });
      }
    },
    async selectDiamond(diamond, imperfection, CertNum) {
      let loader = this.$loading.show({
        container: null,
        canCancel: false,
      });

      if (diamond.imperfection_connected) {
        let dis_cert_diamond = await db
          .collection("diamond")
          .where("imperfection.id", "==", diamond.id)
          .get();
        let diamondData = Object.assign(
          { id: dis_cert_diamond.docs[0].id },
          dis_cert_diamond.docs[0].data()
        );
        if (diamond.id)
          // this.removeCertificateThroughDiamondsAPI({
          //   diamond_id: diamondData.id,
          //   CertNum: diamondData.CertNum,
          //   certificate_url: diamond.CertificateVideo ? diamond.CertificateVideo.url : null
          // })
          //   .then(res => {

          //   })
          this.removeImperfectionsFromDiamond({
            CertNum: diamondData.CertNum,
            imperfection: diamond.id,
          })
            .then(() => {
              db.collection("diamond_imperfections")
                .doc(diamond.id)
                .set(
                  {
                    imperfection_connect: false,
                    imperfection_connect_created: null,
                  },
                  { merge: true }
                );
              this.connect_clicked = false;
              this.connect_diamond = null;

              loader.hide();
              return Swal.fire(
                "",
                "Diamond Imperfections Has Been Removed",
                "success"
              ).then(() => {
                diamond.imperfection_connected = false;
              });
            })
            .catch((error) => {
              console.log(error);
              loader.hide();
              return Swal.fire(
                "Error",
                "Diamond Imperfections Has NOT Been Removed",
                "error"
              );
            });
      } else {
        if (diamond.id)
          // diamond.imperfection_connected = true;
          this.saveImperfectionsToDiamond({
            CertNum: CertNum,
            imperfection: imperfection.diamond.id,
            url: imperfection.diamond.url,
          }).then(() => {
            this.connect_clicked = false;
            this.connect_diamond = null;
            loader.hide();
            return Swal.fire(
              "",
              "Diamond Imperfections Has Been Added",
              "success"
            ).then(() => {
              // window.location.reload();
              imperfection.diamond.imperfection_connected = true;
            });
          });
        let created = new Date().toLocaleString();
        db.collection("diamond_imperfections")
          .doc(imperfection.diamond.id)
          .set(
            {
              imperfection_connect: true,
              imperfection_connect_created: created,
            },
            { merge: true }
          );
      }
    },
    async remove(diamond) {
      if (diamond.imperfection_connected) {
        var dis_cert_diamond = await db
          .collection("diamond")
          .where("imperfection.id", "==", diamond.id)
          .get();
      }
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to undo this",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "DELETE",
        cancelButtonText: "CANCEL",
      }).then((result) => {
        if (result.value) {
          return db
            .collection("diamond_imperfections")
            .doc(diamond.id)
            .delete()
            .then(() => {
              if (diamond.imperfection_connected) {
                // this.removeCertificateThroughDiamondsAPI({
                //   diamond_id: dis_cert_diamond.docs[0].id,
                //   CertNum: dis_cert_diamond.docs[0].data()
                //     .CertNum,
                //   certificate_url: null
                // });
                db.collection("diamond")
                  .doc(dis_cert_diamond.docs[0].id)
                  .update({
                    imperfection: false,
                    connect_timestamp: null,
                  });
              }
              const decrement = firebase.firestore.FieldValue.increment(-1);
              if (diamond.printed) {
                db.collection("diamond_imperfection_total")
                  .doc(auth.currentUser.uid)
                  .update({ total: decrement, completed: decrement });
              } else {
                db.collection("diamond_imperfection_total")
                  .doc(auth.currentUser.uid)
                  .update({ total: decrement, drafts: decrement });
              }
              this.getImperfections();
              Swal.fire(
                "Deleted!",
                `This Diamond has been deleted.`,
                "success"
              );
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.proportions_image {
  width: 200px;
}
.remove_connected_message {
  margin-top: 0px;
  color: #20b363;
  font-size: 13px;
  cursor: pointer;
}
.upload_btn {
  width: 10px;
}
.status {
  color: #ff9800;
}
.actions {
  & > div {
    display: flex;
    img {
      height: 30px;
      cursor: pointer;
      margin-left: 10px;
      opacity: 0.3;
      transition: all 0.3s;
    }

    img:hover {
      opacity: 1;
      transition: all 0.3s;
    }
  }
}

.imperfection_img {
  width: 300px;
}
.imperfections_key_holder {
  margin-left: 40px;
  .imperfection_chosen_text {
    font-weight: bold;
    margin-left: 15px;
    text-transform: uppercase;
  }
  .imperfections_key {
    display: flex;
    align-items: center;
    margin-top: -55px;
    margin-bottom: 30px;
    margin-left: 15px;
    .imperfection_img_icon {
      width: auto;
      height: 75px;
    }
    .imperfections_key_text {
      margin-left: 0px;
      margin-top: 30px;
    }
  }
  .imperfections_key_noicon {
    padding-top: 10px;
    margin-left: 30px;
  }
}
// .close {
//   position: absolute;
//   right: 10px;
//   color: red;
//   cursor: pointer;
//   font-size: 20px;
// }

.close_popup {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 10px;
  top: -10px;
  p {
    font-size: 20px;
    color: red;
  }
}

.imperfections_col,
.name_col {
  margin: 0px;
  font-size: 13px;
  font-weight: normal;
}
.connect_holder_background {
  width: 100%;
  height: 111%;
  position: fixed;
  top: 0;
  left: 0;
  background: gray;
  opacity: 0.25;
  z-index: 1;
}
.connect_hold {
  padding: 15px;
}
.connect_holder {
  z-index: 10;
  position: fixed;
  background: #f7f7f7;
  width: 35%;
  top: 230px;
  display: flex;
  justify-content: center;
  left: 35%;
  max-height: 50vh;
  overflow: hidden;
  overflow-y: auto;
  form {
    padding: 30px;
  }

  .diamond_imperfection_text {
    font-size: 10px;
    position: relative;
    top: -14px;
    right: -120px;
  }
  .table-search-header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .table-search-header-text {
    font-size: 20px;
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 20px;
  }
  .search-input {
    padding: 13px;
    height: 32px;
    position: relative;
    margin-right: 5%;
    -webkit-transform-origin-x: center;
    box-shadow: 1px 1px lightgrey;
    border: none;
  }
  .search-btn {
    padding: 8px 10px;
    box-shadow: 1px 1px lightgrey;
    border: none;
  }
  .search_background {
    height: 100%;
    width: 100%;
    color: #eaeaea;
  }
}
.certification-btn {
  flex-direction: column;
}
.upload_click {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.proportions_input {
  height: 40px;
  margin-left: 8px;
}
.connect_warning {
  color: grey;
  font-size: 13px;
}
.connect {
  height: 30px;
  cursor: pointer;
}
.connect_warning_proportions {
  margin-top: 0px;
  color: grey;
  font-size: 13px;
  text-decoration: none !important;
}
.connected_message {
  margin-top: 0px;
  color: green;
  font-size: 13px;
  cursor: pointer;
  & :hover {
    color: red;
  }
}

.connected_message:hover {
  color: red;
}

.pamphlet-btn .remove_connected_message {
  color: #0b6efb;
  text-decoration: underline;
}
.connect_message {
  margin-top: 0px;
  color: #0b6efb;
  font-size: 13px;
  cursor: pointer;
}
.connect_noclick {
  width: 30px;
  cursor: pointer;
}
.hide_upload {
  position: absolute;
  z-index: -2;
}
.select-btn {
  cursor: pointer;
}
.connect-btn p {
  text-decoration: underline;
}
.select-btn:hover {
  background-color: #0b6efb;
}

.complete {
  color: #20b363;
}

.admin_details {
  background: #f7f7f7;
  padding: 20px;
  .admin_info {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    p {
      width: 100%;
    }
    .text {
      width: 100%;
      max-width: 300px;
      margin-right: 30px;
      padding-right: 30px;
      border-right: 3px solid #eaeaea;
      p {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-weight: bold;
        margin-bottom: 5px;
        span {
          font-weight: normal;
        }
      }
    }
  }

  .admin_table {
    width: 100%;
    .table-header-text {
      text-align: center;
    }
  }
  .admin_details_info_holder {
    display: flex;

    .admin_details_info_tabs {
      color: #fff !important;

      border: none;
      border-radius: 6px;
      padding: 0px 20px !important;
      margin: 10px;
      display: flex;
      .info_text {
        color: black;

        font-weight: 700;
        font-size: 15px;
        text-transform: none;

        align-items: flex-start;
      }
    }
    .admin_details_info {
      color: #fff !important;
      background-color: #0b6efb !important;

      border: none;
      border-radius: 6px;
      padding: 0px 20px !important;
      margin: 10px;
      display: flex;
      .info_text {
        color: white;

        font-weight: 700;
        font-size: 15px;
        text-transform: none;

        align-items: flex-start;
      }
    }
  }
}

.checkboxes {
  display: flex !important;
  flex-direction: column;
}

// .admin_details{
//       height: 700px;
//     width: 100%;
// }
.imperfection {
  height: 30px !important;
  border: 2px solid #eaeaea;
  border-radius: 100%;
  margin-right: 5px;
}
.images {
  & > div {
    margin-bottom: 20px;
    img {
      width: 100%;
      max-width: 300px;
    }
  }
  p {
    font-weight: bold;
    margin-bottom: 5px;
  }
}

.diamonds {
  height: auto !important;
  min-height: 100vh !important;
  width: 100%;
  position: absolute;
  background-color: #eaeaea;
  .body {
    text-align: center;
    width: 100%;
    .selection {
      height: 50%;
      width: 75%;
      max-width: 600px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: white;
      &.table {
        max-width: 1200px;
        width: 90%;
        margin: auto;
        table {
          border-collapse: collapse;
          thead {
            border-bottom: 3px solid #eaeaea;
            th {
              font-weight: bold;
              text-align: left;
              padding: 0 10px;
              padding-bottom: 10px;
              font-size: 16px;
            }
            @media screen and (max-width: 700px) {
              display: none;
            }
            tr {
              border-bottom: 2px solid #eaeaea;
              th {
                padding-bottom: 10px;
              }
            }
          }
        }
        tbody {
          tr {
            border-bottom: 2px solid #eaeaea;
            @media screen and (max-width: 700px) {
              display: flex;
              flex-wrap: wrap;
              padding: 10px 0;
              padding-right: 40px;
            }
            &:nth-child(odd) {
            }
            transition: all 0.3s;
            td {
              font-size: 16px;
              text-align: left;
              padding: 10px;
              & p {
                cursor: pointer;
                margin: 0;
              }

              @media screen and (max-width: 700px) {
                display: flex;
                flex-direction: column;
                width: 150px;
                margin-right: 0;
                text-align: left;
                overflow: hidden;
                font-size: 16px;
                color: #6a6969;
                padding: 10px;
                &::before {
                  content: attr(data-label);
                  float: left;
                  font-weight: bold;
                  margin-bottom: 3px;
                  font-size: 13px;
                  color: black;
                }
                &.view-btn,
                &.delete-btn {
                  width: 50%;
                  align-items: flex-start;
                }
                &.delete-btn {
                  align-items: flex-end;
                }
              }
              @media screen and (max-width: 550px) {
                width: 100%;
                padding: 10px;
              }
              .imperfection {
                height: 30px;
              }
              .pamphlet {
                height: 30px;
                cursor: pointer;
              }
              .view {
                height: 15px;
                cursor: pointer;
              }
              .delete {
                height: 13px;
                cursor: pointer;
              }
            }
            &:hover {
              background: #f7f7f7;
              .actions {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}
.createNew {
  width: 100% !important;
}
.diamondImg {
  padding: 20px;
}
.select_diamond_btn {
  text-transform: uppercase;
  background: #01a3fd;
  color: white;
  padding: 4px 22px;
  font-weight: bold;
  width: 115px;
  border: none;
  outline: none;
  border-radius: 25px;
  text-align: center;
  right: 60px;
  position: absolute;
  font-size: 13px;
  top: 10px;
}
.select_diamond_btn_imperfections {
  opacity: 0.5;
  .select-btn:hover {
    background-color: unset;
  }
  p {
    font-size: 10px;
    margin-top: 20px;
  }
}

.btn {
  cursor: pointer;
  text-transform: uppercase;
  color: white;
  padding: 10px 25px;
  font-weight: bold;
  font-size: 10px;
  width: auto;
  border: none;
  outline: none;
  border-radius: 25px;
}

.diamonds .body .search.search {
  margin-bottom: 15px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  input {
    width: 100%;
    text-align: left;
    padding: 15px 20px;
    border: none;
    outline: none;
    color: black;
    font-size: 17px;
    &::placeholder {
      color: black;
      opacity: 0.5;
    }
  }
  .clear {
    position: absolute;
    padding: 10px 15px;
    font-size: 22px;
    right: 0;
    opacity: 0.4;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      opacity: 1;
    }
  }
}

.pagination {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  p {
    margin-bottom: 5px;
  }
  .total {
    opacity: 0.5;
  }
  .arrows {
    display: flex;
    align-items: center;
    & > div {
      height: 30px;
      width: 30px;
      background: white;
      border: 3px solid #eaeaea;
      margin-left: 10px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.2;
      pointer-events: none;
      &.clickable {
        cursor: pointer;
        pointer-events: all;
        opacity: 1;
      }
      &.first,
      &.next {
        transform: rotate(180deg);
      }
      img {
        height: 13px;
      }
    }
  }
}

.upload-text {
  font-size: 13px;
  color: #0b6efb;
  margin-left: 5px !important;
}
</style>
