<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-button @click="$router.go(-1)" class="md-primary">back</md-button>
      <md-card>
        <md-card-content>
          <md-field>
            <label>Choose Template</label>
            <md-select required v-model.number="selectedTemplate">
              <md-option
                v-for="type in templates"
                :key="type.id"
                :value="type.id"
              >
                {{ type.name }}
              </md-option>
            </md-select>
          </md-field>
        </md-card-content>
      </md-card>
      <md-card v-if="selectedTemplate">
        <md-card-content>
          <div>
            <md-card>
              <md-card-header>
                <md-card-header-text>
                  <div class="md-title">General Diamond Information</div>
                </md-card-header-text>
              </md-card-header>
              <md-card-content class="field-inputs">
                <md-field>
                  <label>{{
                    diamond.Company && diamond.Company.name
                      ? diamond.Company.name
                      : "Company"
                  }}</label>
                  <md-select required v-model="selectedCompany">
                    <md-option :value="false">NONE</md-option>
                    <md-option
                      v-for="company in companies"
                      :key="company.id"
                      :value="company.id"
                      >{{ company.name }}</md-option
                    >
                  </md-select>
                </md-field>
                <md-field ref="CertNum">
                  <label>Certificate #</label>
                  <md-input type="text" v-model="diamond.CertNum"></md-input>
                </md-field>

                <md-field ref="ClientSKU">
                  <label>SKU</label>
                  <md-input
                    @input="diamond.Video.name = diamond.ClientSKU"
                    type="text"
                    v-model="diamond.ClientSKU"
                  ></md-input>
                </md-field>

                <md-field>
                  <label>Inscription(s)</label>
                  <md-input
                    type="text"
                    v-model="diamond.Inscription"
                  ></md-input>
                </md-field>

                <md-field>
                  <label>Video File Name</label>
                  <md-input v-model="diamond.Video.name"> </md-input>
                </md-field>

                <md-field>
                  <label>Measurements (ex: 12.08 x 7.98 x 4.99 mm)</label>
                  <md-input
                    @change="handleMeasurements(diamond.Measurements)"
                    v-model="diamond.Measurements"
                    type="text"
                  ></md-input>
                </md-field>

                <md-field
                  :class="{ 'md-invalid': errors['Table'] }"
                  v-if="selectedTemplate == 1 || selectedTemplate == 2"
                  ref="Table"
                >
                  <label>Table (in %)</label>
                  <md-input
                    required
                    type="number"
                    v-model.number="diamond.Table"
                  ></md-input>
                  <span class="md-error">Missing Field</span>
                </md-field>
                <md-field
                  :class="{ 'md-invalid': errors['Depth'] }"
                  ref="Depth"
                  v-if="selectedTemplate == 1 || selectedTemplate == 2"
                >
                  <label>Depth (in %)</label>
                  <md-input
                    required
                    type="number"
                    v-model.number="diamond.Depth"
                  ></md-input>
                  <span class="md-error">Missing Field</span>
                </md-field>

                <md-field
                  :class="{ 'md-invalid': errors['Shape'] }"
                  ref="Shape"
                >
                  <label>Shape</label>
                  <md-select required v-model="diamond.Shape">
                    <md-option
                      v-for="(shape, i) in shapes"
                      :key="i"
                      :value="shape"
                      >{{ shape }}</md-option
                    >
                  </md-select>
                  <span class="md-error">Missing Field</span>
                </md-field>

                <md-field
                  :class="{ 'md-invalid': errors['Cut'] }"
                  ref="Cut"
                  v-if="selectedTemplate == 1"
                >
                  <label>Cut</label>
                  <md-select required v-model="diamond.Cut">
                    <md-option v-for="(cut, i) in cuts" :key="i" :value="cut">
                      {{ cut }}
                    </md-option>
                  </md-select>
                  <span class="md-error">Missing Field</span>
                </md-field>

                <md-field
                  v-if="selectedTemplate != 6"
                  :class="{ 'md-invalid': errors['Polish'] }"
                  ref="Polish"
                >
                  <label>Polish</label>
                  <md-select required v-model="diamond.Polish">
                    <md-option v-for="(p, i) in polish" :key="i" :value="p">
                      {{ p }}
                    </md-option>
                  </md-select>
                  <span class="md-error">Missing Field</span>
                </md-field>

                <md-field
                  v-if="selectedTemplate != 6"
                  :class="{ 'md-invalid': errors['Symmetry'] }"
                  ref="Symmetry"
                >
                  <label>Symmetry</label>
                  <md-select required v-model="diamond.Symmetry">
                    <md-option v-for="(s, i) in symmetry" :key="i" :value="s">
                      {{ s }}
                    </md-option>
                  </md-select>
                  <span class="md-error">Missing Field</span>
                </md-field>

                <md-field
                  :class="{ 'md-invalid': errors['Fluorescence'] }"
                  ref="Fluorescence"
                  v-if="
                    selectedTemplate == 1 ||
                    selectedTemplate == 2 ||
                    selectedTemplate == 3
                  "
                >
                  <label>Fluorescence</label>
                  <md-select required v-model="diamond.Fluorescence">
                    <md-option
                      v-for="(s, i) in fluorescence"
                      :key="i"
                      :value="s"
                      >{{ s }}</md-option
                    >
                  </md-select>
                  <span class="md-error">Missing Field</span>
                </md-field>
                <md-field
                  :class="{ 'md-invalid': errors['Girdle'] }"
                  ref="Girdle"
                  v-if="selectedTemplate == 1"
                >
                  <label>Girdle</label>
                  <md-select required v-model="diamond.Girdle">
                    <md-option v-for="(p, i) in girdle" :key="i" :value="p">
                      {{ p }}
                    </md-option>
                  </md-select>
                  <span class="md-error">Missing Field</span>
                </md-field>

                <md-field
                  :class="{ 'md-invalid': errors['Clarity'] }"
                  ref="Clarity"
                >
                  <div class="color-select">
                    <div class="static"><p>Asterisks</p></div>
                    <div class="options">
                      <div v-for="(asterisk, index) in asterisks" :key="index">
                        <input
                          type="radio"
                          :value="asterisk.value"
                          v-model="diamond.Clarity.asterisks"
                        />{{ asterisk.name }}
                      </div>
                    </div>
                    <div class="static">
                      <p>Clarity <span class="required">*</span></p>
                    </div>
                    <md-select v-model="diamond.Clarity.value">
                      <md-option v-for="(c, i) in clarity" :key="i" :value="c">
                        {{ c }}
                      </md-option>
                    </md-select>
                    <span class="md-error">Missing Field</span>
                  </div>
                </md-field>

                <md-field
                  :class="{ 'md-invalid': errors['Color'] }"
                  ref="Color"
                >
                  <div class="color-select">
                    <div class="static"><p>Asterisks</p></div>
                    <div class="options">
                      <div v-for="(asterisk, index) in asterisks" :key="index">
                        <input
                          type="radio"
                          :value="asterisk.value"
                          v-model="diamond.Color.asterisks"
                        />{{ asterisk.name }}
                      </div>
                    </div>

                    <div class="static">
                      <p>Color <span class="required">*</span></p>
                    </div>

                    <div class="options">
                      <div
                        v-for="(color, index) in Object.keys(colors)"
                        :key="index"
                      >
                        <input
                          @change="handleColorChanges"
                          type="radio"
                          :value="color"
                          v-model="diamond.Color.template"
                        />{{ color }}
                      </div>
                    </div>

                    <md-select v-model="diamond.Color.value">
                      <md-option
                        v-for="(color, i) in colors[diamond.Color.template]
                          .value"
                        :key="i"
                        :value="color"
                        >{{ color }}</md-option
                      >
                    </md-select>
                    <span class="md-error">Missing Field</span>
                  </div>
                </md-field>

                <md-field :class="{ 'md-invalid': errors['Weight'] }">
                  <label>Carat Weight</label>
                  <md-input
                    required
                    v-model="diamond.Weight"
                    type="number"
                  ></md-input>
                  <span class="md-error">Missing Field</span>
                </md-field>

                <md-field>
                  <label>Lab Grown or Natural Stone?</label>
                  <md-select v-model="diamond.ManufacturedAs">
                    <md-option value="Lab Grown">Lab Grown</md-option>
                    <md-option value="Natural Stone">Natural Stone</md-option>
                  </md-select>
                </md-field>
                <!-- <md-field>
                <label>Certificate</label>
                <md-select v-model="diamond.certification">
                  <md-option
                    v-for="(certificate, i) in certificates"
                    :key="i"
                    :value="certificate"
                  >{{ certificate }}</md-option>
                </md-select>
                <span class="md-error">Missing Field</span>
              </md-field>-->
              </md-card-content>
            </md-card>

            <div class="upload-description">
              <div class="ck-editor">
                <label>Description</label>
                <CKEditor
                  @updateData="updateData($event, 'Description')"
                  :inputData="diamond.Description"
                  :limitedCount="200"
                  :limitedText="true"
                />
              </div>
              <div class="ck-editor">
                <CKEditor
                  label="Comments"
                  @updateData="updateData($event, 'CertificateComments')"
                  :inputData="diamond.CertificateComments"
                  :limitedCount="275"
                  :limitedText="true"
                />
              </div>
            </div>
          </div>
        </md-card-content>

        <md-card-actions md-alignment="space-between">
          <md-button class="md-primary md-round md-small" @click="validate"
            >Save</md-button
          >
          <md-button
            class="md-primary md-round md-small"
            @click="validateThenSendToSpreadsheet"
            >Create Digital Certificate</md-button
          >

          <md-button class="md-danger md-round md-small" @click="handleDelete"
            >Delete</md-button
          >
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import Fuse from "fuse.js";
import CKEditor from "@/components/CKEditor.vue";
import Upload from "@/components/Inputs/Upload.vue";
import { db, storage, auth, functions } from "@/config/firebaseInit.js";
import Swal from "sweetalert2";
import router from "@/router";
import axios from "axios";
//import vSelect from "vue-select";

export default {
  components: {
    CKEditor,
    Upload,
  },
  data() {
    return {
      companies: [],
      shapes: [
        "Asscher",
        "Baguette",
        "Cushion",
        "Heart",
        "Marquise",
        "Oval",
        "Pear",
        "Princess",
        "Radiant",
        "Rose",
        "Shield",
        "Taper",
        "Triangular",
        "Round",
        "Marquise",
        "Emerald",
        "Old Cut",
        "Triangular Cut",
        "Baguette Cut",
        "Trapeze Cut",
        "Shield Cut",
        "Taper Cut",
        "Half Moon Cut",
        "Rose Cut",
        "Square Emerald Cut",
        "Cushion Modified Brilliant",
        "Cushion Brilliant",
        "Emerald Cut",
        "Heart Brilliant",
        "Oval Brilliant",
        "Marquise Brilliant",
        "Square Modified Brilliant",
        "Rectangular Modified Brilliant",
        "Pear Brilliant",
        "Cut-Cornered Square Modified Brilliant",
        "Cut-Cornered Rectangular Modified Brilliant",
        "Round Brilliant",
      ],
      selectedColorTemplate: "Standard",
      selectedColorAsterisks: "",
      selectedClarityAsterisks: "",
      asterisks: [
        {
          name: "None",
          value: "",
        },
        {
          name: "*",
          value: "*",
        },
        {
          name: "**",
          value: "**",
        },
      ],
      colors: {
        Standard: {
          name: "Standard",
          key: "",
          value: [
            "Z",
            "Y",
            "X",
            "W",
            "V",
            "U",
            "T",
            "S",
            "R",
            "Q",
            "P",
            "O",
            "N",
            "M",
            "L",
            "K",
            "J",
            "I",
            "H",
            "G",
            "F",
            "E",
            "D",
          ],
        },
        Blue: {
          name: "Blue",
          value: [
            "Light",
            "Fancy Light",
            "Fancy",
            "Fancy Intense",
            "Fancy Vivid",
          ],
          key: "_B_",
        },
        Brown: {
          name: "Brown",
          value: ["C1", "C2", "C3", "C4", "C5", "C6", "C7", "C8"],
          key: "_B_",
        },
        Green: {
          name: "Green",
          value: [
            "Faint",
            "Very Light",
            "Light",
            "Fancy Light",
            "Fancy",
            "Fancy Intense",
            "Fancy Vivid",
            "Fancy Deep",
          ],
          key: "_G_",
        },
        Pink: {
          name: "Pink",
          value: [
            "Faint",
            "Very Light",
            "Light",
            "Fancy Light",
            "Fancy",
            "Fancy Intense",
            "Fancy Vivid",
            "Fancy Deep",
          ],
          key: "_P_",
        },
        Yellow: {
          name: "Yellow",
          value: ["Fancy Light", "Fancy", "Intense", "Vivid", "Deep"],
          key: "_Y_",
        },
        "Yellow-Orange": {
          name: "Yellow-Orange",
          value: [
            "Fancy Light",
            "Fancy",
            "Fancy Vivid",
            "Intense",
            "Vivid",
            "Deep",
          ],
          key: "_YO_",
        },
        Black: {
          name: "Black",
          value: ["A", "AA", "AAA", "AAAA"],
          key: "_B_",
        },
        Red: {
          name: "Red",
          value: [
            "Faint",
            "Fancy",
            "Fancy Purplish Red",
            "Fancy Dark",
            "Fancy Intense",
            "Fancy Light",
            "Fancy Vivid",
            "Light",
            "Very Light",
          ],
          key: "_R_",
        },
        "Purplish-Red": {
          name: "Purplish-Red",
          value: [
            "Faint",
            "Fancy",
            "Fancy Dark",
            "Fancy Intense",
            "Fancy Light",
            "Fancy Vivid",
            "Light",
            "Very Light",
          ],
          key: "_PR_",
        },
      },
      cuts: [
        "None",
        "Excellent",
        "Super Ideal",
        "Ideal",
        "Very Good",
        "Good",
        "Fair",
      ],
      clarity: [
        "I1",
        "I2",
        "I3",
        "SI",
        "SI1",
        "SI2",
        "SI3",
        "VS",
        "VS2",
        "VS1",
        "VVS2",
        "VVS1",
        "INFLAWLESS",
        "FLAWLESS",
      ],
      girdle: ["M"],
      polish: ["Poor", "Fair", "Good", "Very Good", "Excellent"],
      symmetry: ["Poor", "Fair", "Good", "Very Good", "Excellent"],
      fluorescence: ["Very Strong", "Strong", "Medium", "Faint", "None"],
      effect: ["None", "Shine", "Confetti", "Hearts"],
      certificates: ["GIA", "HRD", "EGL"],
      templates: [
        {
          name: "Everything",
          id: 1,
        },
        {
          name: "Everything (Excluding Cut)",
          id: 2,
        },
        {
          name: "Regular",
          id: 3,
        },
        {
          name: "Regular (Excluding No Fluorescence)",
          id: 4,
        },
        {
          name: "Minimalist",
          id: 5,
        },
        {
          name: "Extra Minimalist",
          id: 6,
        },
      ],
      merchants: [],
      loader: false,
      update_spreadsheet: true,
      selectedCompany: null,
      selectedTemplate: null,
      errors: {},
      diamond: {},
    };
  },

  firestore() {
    return {
      companies: db.collection("companies"),
    };
  },

  async created() {
    // this.getAttributes();
    await this.$bind(
      "diamond",
      db.collection("diamond").doc(this.$route.params.diamond_id)
    ).then((diamond) => {
      if (!diamond) {
        this.diamond = {
          UserID: "",
          ClientSKU: "",
          Template: null,
          StockNum: "",
          Inscription: "",
          Shape: "",
          Weight: "",
          Cut: "",
          ProductType: "Diamond",
          Clarity: {
            value: "",
            asterisks: "",
          },
          Polish: "",
          CertificateComments: "",
          Symmetry: "",
          CertNum: "",
          Description: "",
          Fluorescence: "",
          Measurements: "",
          Table: "",
          Depth: "",
          Video: {},
          Color: {
            value: "",
            template: "Standard",
            asterisks: "",
          },
          Girdle: "",
          ManufacturedAs: "",
          Company: {
            id: "",
            name: "",
            logo: "",
          },
          update_spreadsheet: true,
        };
      } else {
        this.selectedCompany = diamond.Company.id ? diamond.Company.id : null;
        this.selectedTemplate = diamond.Template.id
          ? diamond.Template.id
          : null;

        if (diamond.hasOwnProperty("selectedColorTemplate")) {
          const colors = Object.values(this.colors).map((e) => e.key);
          const color_keys = Object.keys(this.colors);
          let index = colors.indexOf(diamond.selectedColorTemplate);
          this.selectedColorTemplate = color_keys[index]
            ? color_keys[index]
            : "Standard";
        }

        if (diamond.Video == null) {
          diamond.Video = { name: null };
        }

        if (diamond.hasOwnProperty("selectedClarityAsterisks")) {
          this.selectedClarityAsterisks = diamond.selectedClarityAsterisks;
        }

        if (diamond.hasOwnProperty("selectedColorAsterisks")) {
          this.selectedColorAsterisks = diamond.selectedColorAsterisks;
        }
      }
    });
  },

  methods: {
    async getAttributes() {
      let attributes = await db.collection("attributes").get();
      attributes = attributes.docs.map((d) =>
        Object.assign({ id: d.id }, d.data())
      );
      attributes.forEach((att) => {
        if (att.id == "color") {
          this.colors.Standard.value = att.values;
        } else if (this[att.id]) {
          this[att.id] = [].concat(this[att.id], att.values);
        }
      });
    },
    handleColorChanges() {
      this.diamond.color = null;
    },
    handleMeasurements(str) {
      let valid = true;
      let measurements = str.replace(/mm/, "").split("x");
      if (measurements.length !== 3) {
        this.diamond.Measurements = "";
        return Swal.fire(
          "Invalid Format",
          "Measurements suppose to have 3 dimensions",
          "warning"
        );
      }
      measurements = measurements.map((n) => {
        n = n.trim();
        if (!Number(n)) valid = false;
        else return n;
      });
      if (valid) {
        this.diamond.Measurements = measurements.join(" x ") + " mm";
      } else {
        this.diamond.Measurements = "";
        return Swal.fire(
          "Invalid Format",
          "Please enter the correct format as shown on measurements",
          "warning"
        );
      }
    },
    handleDelete() {
      const diamondRef = db.doc(`diamond/${this.$route.params.diamond_id}`);
      return Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          diamondRef.delete().then(() => {
            return Swal.fire(
              "Deleted!",
              "The data has been deleted.",
              "success"
            ).then(() => {
              return this.$router.go(-1);
            });
          });
        }
      });
    },
    updateData(payload, key) {
      this.diamond[key] = payload;
    },
    validate() {
      let valid = true;

      if (this.selectedTemplate == 1) {
        for (let k in this.diamond) {
          if (
            k === "Shape" ||
            k === "Cut" ||
            k === "Polish" ||
            k === "Fluorescence" ||
            k === "Depth" ||
            k === "Table" ||
            k === "Girdle" ||
            k === "Symmetry"
          ) {
            if (!this.diamond[k]) {
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          } else if (k === "Color" || k === "Clarity") {
            if (!this.diamond[k].value) {
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          } else if (k == "video" && !Object.keys(this.diamond[k]).length) {
            this.errors[k] = true;
          }
        }
      } else if (this.selectedTemplate == 2) {
        for (let k in this.diamond) {
          if (
            k === "Shape" ||
            k === "Polish" ||
            k === "Fluorescence" ||
            k === "Depth" ||
            k === "Table" ||
            k === "Weight" ||
            k === "Symmetry"
          ) {
            if (!this.diamond[k]) {
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          } else if (k === "Color" || k === "Clarity") {
            if (!this.diamond[k].value) {
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          } else if (k == "video" && !Object.keys(this.diamond[k]).length) {
            this.errors[k] = true;
          }
        }
      } else if (this.selectedTemplate == 3) {
        for (let k in this.diamond) {
          if (
            k === "Shape" ||
            k === "Weight" ||
            k === "Polish" ||
            k === "Fluorescence" ||
            k === "Symmetry"
          ) {
            if (!this.diamond[k]) {
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          } else if (k === "Color" || k === "Clarity") {
            if (!this.diamond[k].value) {
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          }
        }
      } else if (this.selectedTemplate == 4) {
        for (let k in this.diamond) {
          if (
            k === "Shape" ||
            k === "Weight" ||
            k === "Polish" ||
            k === "Symmetry"
          ) {
            if (!this.diamond[k]) {
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          } else if (k === "Color" || k === "Clarity") {
            if (!this.diamond[k].value) {
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          }
        }
      } else if (this.selectedTemplate == 5) {
        for (let k in this.diamond) {
          if (
            k === "Shape" ||
            k === "Weight" ||
            k === "Polish" ||
            k === "Symmetry"
          ) {
            if (!this.diamond[k]) {
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          } else if (k === "Color" || k === "Clarity") {
            if (!this.diamond[k].value) {
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          }
        }
      } else if (this.selectedTemplate == 6) {
        for (let k in this.diamond) {
          if (k === "Shape" || k === "Weight") {
            if (!this.diamond[k]) {
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          } else if (k === "Color" || k === "Clarity") {
            if (!this.diamond[k].value) {
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          }
        }
      }

      if (!this.diamond.ClientSKU && !this.diamond.CertNum) {
        return Swal.fire(
          "Error!",
          "Must enter a Client SKU or a Certificate Number",
          "error"
        );
      }

      if (valid) {
        this.save();
      } else {
        this.$forceUpdate();

        return Swal.fire(
          "Error!",
          "Please enter all required fields ( in red )",
          "error"
        );
      }
    },

    validateThenSendToSpreadsheet() {
      let valid = true;

      if (this.selectedTemplate == 1) {
        for (let k in this.diamond) {
          if (
            k === "Shape" ||
            k === "Cut" ||
            k === "Polish" ||
            k === "Fluorescence" ||
            k === "Depth" ||
            k === "Table" ||
            k === "Girdle" ||
            k === "Symmetry"
          ) {
            if (!this.diamond[k]) {
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          } else if (k === "Color" || k === "Clarity") {
            if (!this.diamond[k].value) {
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          } else if (k == "video" && !Object.keys(this.diamond[k]).length) {
            this.errors[k] = true;
          }
        }
      } else if (this.selectedTemplate == 2) {
        for (let k in this.diamond) {
          if (
            k === "Shape" ||
            k === "Polish" ||
            k === "Fluorescence" ||
            k === "Depth" ||
            k === "Table" ||
            k === "Weight" ||
            k === "Symmetry"
          ) {
            if (!this.diamond[k]) {
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          } else if (k === "Color" || k === "Clarity") {
            if (!this.diamond[k].value) {
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          } else if (k == "video" && !Object.keys(this.diamond[k]).length) {
            this.errors[k] = true;
          }
        }
      } else if (this.selectedTemplate == 3) {
        for (let k in this.diamond) {
          if (
            k === "Shape" ||
            k === "Weight" ||
            k === "Polish" ||
            k === "Fluorescence" ||
            k === "Symmetry"
          ) {
            if (!this.diamond[k]) {
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          } else if (k === "Color" || k === "Clarity") {
            if (!this.diamond[k].value) {
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          }
        }
      } else if (this.selectedTemplate == 4) {
        for (let k in this.diamond) {
          if (
            k === "Shape" ||
            k === "Weight" ||
            k === "Polish" ||
            k === "Symmetry"
          ) {
            if (!this.diamond[k]) {
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          } else if (k === "Color" || k === "Clarity") {
            if (!this.diamond[k].value) {
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          }
        }
      } else if (this.selectedTemplate == 5) {
        for (let k in this.diamond) {
          if (
            k === "Shape" ||
            k === "Weight" ||
            k === "Polish" ||
            k === "Symmetry"
          ) {
            if (!this.diamond[k]) {
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          } else if (k === "Color" || k === "Clarity") {
            if (!this.diamond[k].value) {
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          }
        }
      } else if (this.selectedTemplate == 6) {
        for (let k in this.diamond) {
          if (k === "Shape" || k === "Weight") {
            if (!this.diamond[k]) {
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          } else if (k === "Color" || k === "Clarity") {
            if (!this.diamond[k].value) {
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          }
        }
      }

      if (!this.diamond.ClientSKU && !this.diamond.CertNum) {
        return Swal.fire(
          "Error!",
          "Must enter a Client SKU or a Certificate Number",
          "error"
        );
      }

      if (valid) {
        this.save();
      } else {
        this.$forceUpdate();

        return Swal.fire(
          "Error!",
          "Please enter all required fields ( in red )",
          "error"
        );
      }
    },

    uploadedMedia(payload, key) {
      if (key == "CertificateVideo") this.update_spreadsheet = false;
      if (this.diamond.addedBarcode) this.diamond.addedBarcode = false;
      this.diamond[key] = payload.media;
    },

    removeMedia(key) {
      if (!this.diamond[key].ref) {
        this.diamond[key] = null;
        return;
      }
      const imageRef = storage.ref(this.diamond[key].ref);
      // Delete the file
      imageRef
        .delete()
        .then(() => {
          this.diamond[key] = null;
        })
        .catch((error) => {
          this.displayError(error);
        });
    },
    displayError(error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log({ errorCode, errorMessage });
      return Swal.fire(
        "Error!",
        `${errorMessage} - code: ${errorCode}. Please try again or contact support.`,
        "error"
      );
    },
    async save() {
      let loader = this.$loading.show({
        container: this.$refs.loader,
        canCancel: false,
      });

      if (!this.diamond.created) {
        this.diamond.created = Date.now();
        this.diamond.clicks = 0;
      }

      if (
        this.diamond.Video &&
        this.diamond.Video.name &&
        !this.diamond.Video.name.includes(".mp4")
      ) {
        this.diamond.Video.name += ".mp4";
      }

      this.diamond.update_spreadsheet = this.update_spreadsheet;
      this.diamond.UserID = auth.currentUser.uid;
      if (this.diamond.NewlyAdded) this.diamond.NewlyAdded = false;
      const diamondRef = db.doc(`diamond/${this.diamond.CertNum}`);
      await diamondRef
        .set(this.diamond, { merge: true })
        .then(async () => {
          if (this.diamond.CertificateVideo && !this.diamond.addedBarcode) {
            try {
              const addJewelry = functions.httpsCallable("addJewelryToUriApi");

              return addJewelry({
                barcode: this.diamond.ClientSKU,
                url: this.diamond.CertificateVideo.url,
                customer: this.diamond.Company.name || "NONE",
              })
                .then((res) => {
                  return diamondRef.update({ addedBarcode: true });
                })
                .catch((err) => {
                  throw err;
                });
            } catch (error) {
              loader.hide();
              console.log(error);
              return error;
            }
          }
          loader.hide();
          return true;
        })
        .catch((err) => {
          loader.hide();
          console.log(err);
          this.displayError(err);
        });

      return Swal.fire("Success", "Diamond has been saved", "success").then(
        () => this.$router.push("/diamond")
      );
    },

    async createDigitalCert() {
      let loader = this.$loading.show({
        container: this.$refs.loader,
        canCancel: false,
      });

      if (!this.diamond.created) {
        this.diamond.created = Date.now();
        this.diamond.clicks = 0;
      }

      this.diamond.Video.name = this.addSuffixAndExtension(
        this.diamond.Video.name
      );

      this.diamond.UserID = auth.currentUser.uid;
      this.diamond.update_spreadsheet = true;
      this.diamond.updatedAt = Date.now();
      if (this.diamond.NewlyAdded) this.diamond.NewlyAdded = false;

      await this.formatDescription(this.diamond.Description);
      const diamondRef = db.doc(
        `diamond/${
          this.diamond.CertNum
            ? this.diamond.CertNum
            : this.$route.params.jewelry_id
        }`
      );

      try {
        await diamondRef.set(this.diamond, { merge: true });
        loader.hide();
      } catch (error) {
        this.displayError(error);
        loader.hide();
      }

      return Swal.fire(
        "Success",
        "Certificate has been saved and sent to google spreadsheet",
        "success"
      ).then(() => {
        this.$router.push("/diamond");
      });
    },
  },

  watch: {
    selectedCompany(v) {
      if (this.selectedCompany) {
        let company = this.companies.find((c) => c.id === this.selectedCompany);
        this.diamond.Company = {
          id: company.id,
          name: company.name,
          logo: company.images,
        };
      } else {
        this.diamond.Company = {
          id: "",
          name: "",
          logo: "",
        };
      }
    },
    selectedTemplate() {
      if (this.selectedTemplate) {
        let template = this.templates.find(
          (t) => t.id == this.selectedTemplate
        );
        this.diamond.Template = template;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.md-field.md-required label:after {
  color: red !important;
}

.md-divider {
  margin: 20px 0 20px 0;
}
.md-error {
  height: 75px !important;
}
.field-inputs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .md-field {
    width: 48%;
  }
  .switch {
    width: 48%;
    display: flex;
    .switch-flex {
      margin-right: 50px;
    }
  }
}
.upload-description {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.upload-parent {
  width: 100%;
}

.video_label {
  color: red;
}
</style>
