<template>
  <div
    class="sidebar"
    v-bind:style="styles"
    v-on:mouseover="sidebarOn"
    v-on:mouseleave="sidebarOff"
  >
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <div class="miniSidebar" @click="minimizeSidebar">
        <img :src="imageSrc" width="30px" />
      </div>

      <div class="sideBar-logo">
        <img src="/img/logo-white.png" />
      </div>
      <p @click="logout" class="logout" v-bind:style="logoutwidth">Log Out</p>
      <div class="navbar-bord" v-bind:style="navbar"></div>

      <div
        class="slideBar_link menuAction"
        v-bind:style="slideBarLink"
        @click="goTo('dashboard')"
      >
        <img src="/img/ttc-dashboard.png" />
        <p v-bind:style="slideBarLinkText">Home</p>
        <!-- <div v-if="dashboard">&#9660;</div> -->
        <!-- <div v-else class="arrowElse">&#9650;</div> -->
      </div>
      <!-- <router-link to="/dashboard">
        <div class="slideBarAllLinks" v-if="dashboard">
          <div>K</div>
          <p v-bind:style="slideBarLinkText">Home</p>
        </div>
      </router-link> -->

      <div
        class="slideBar_link"
        v-bind:style="slideBarLink"
        @click="diamonds = !diamonds"
      >
        <img src="/img/product.png" />
        <p v-bind:style="slideBarLinkText">Products</p>
        <div v-if="diamonds">&#9650;</div>
        <div v-else class="arrowElse">&#9660;</div>
      </div>
      <router-link to="/diamond">
        <div class="slideBarAllLinks" v-if="diamonds">
          <div>D</div>
          <p v-bind:style="slideBarLinkText">Diamonds</p>
        </div>
      </router-link>
      <router-link to="/jewelry">
        <div class="slideBarAllLinks" v-if="diamonds">
          <div>J</div>
          <p v-bind:style="slideBarLinkText">Jewelry</p>
        </div>
      </router-link>
      <router-link to="/imperfections">
        <div class="slideBarAllLinks" v-if="diamonds">
          <div>I</div>
          <p v-bind:style="slideBarLinkText">Imperfections</p>
        </div>
      </router-link>
      <div
        class="slideBar_link"
        v-bind:style="slideBarLink"
        @click="goTo('digital-certificates')"
      >
        <img class="white_icon" src="/img/media.png" />
        <p v-bind:style="slideBarLinkText">Digital Certificates</p>
      </div>
      <div
        class="slideBar_link"
        v-bind:style="slideBarLink"
        @click="goTo('digital-cert-qr')"
      >
        <img class="white_icon" src="/img/qr-code.png" />
        <p v-bind:style="slideBarLinkText">Certificates QR Code</p>
      </div>
      <div
        class="slideBar_link"
        v-bind:style="slideBarLink"
        @click="goTo('analytics')"
      >
        <md-icon
          class="white_icon"
          style="
            font-size: 35px !important;
            position: absolute;
            left: 42px !important;
          "
          >analytics</md-icon
        >
        <p v-bind:style="slideBarLinkText" style="margin-left: 50px">
          Analytics
        </p>
      </div>
      <div
        class="slideBar_link"
        v-bind:style="slideBarLink"
        @click="goTo('clients')"
      >
        <img class="white_icon" src="/img/merchants.png" />
        <p v-bind:style="slideBarLinkText">Clients</p>
        <!-- <div v-if="merchant">&#9660;</div>
        <div v-else class="arrowElse">&#9650;</div> -->
      </div>
      <!-- <router-link to="/merchant">
        <div class="slideBarAllLinks" v-if="merchant">
          <div>M</div>
          <p v-bind:style="slideBarLinkText">Merchants</p>
        </div>
      </router-link> -->

      <div
        class="slideBar_link"
        v-bind:style="slideBarLink"
        @click="goTo('user')"
      >
        <img class="white_icon" src="/img/invite-user-1.png" />
        <p v-bind:style="slideBarLinkText">Administrator</p>
        <!-- <div v-if="admin">&#9660;</div>
        <div v-else class="arrowElse">&#9650;</div> -->
      </div>

      <div
        class="slideBar_link"
        v-bind:style="slideBarLink"
        @click="goTo('billing')"
      >
        <img src="/img/billing.png" />
        <p v-bind:style="slideBarLinkText">Billing</p>
      </div>

      <div
        class="slideBar_link"
        v-bind:style="slideBarLink"
        @click="goTo('shipping-log')"
      >
        <md-icon class="white_icon">local_shipping</md-icon>
        <p v-bind:style="slideBarLinkText">Shipping</p>
      </div>

      <div
        class="slideBar_link"
        v-bind:style="slideBarLink"
        @click="settings = !settings"
      >
        <img class="white_icon" src="/img/settings.png" />
        <p v-bind:style="slideBarLinkText">Settings</p>
        <div v-if="settings" class="arrowElse">&#9650;</div>
        <div v-else>&#9660;</div>
      </div>

      <div>
        <router-link to="/attributes">
          <div class="slideBarAllLinks" v-if="settings">
            <div>A</div>
            <p v-bind:style="slideBarLinkText">Attributes</p>
          </div>
        </router-link>
        <router-link v-if="authUsers[currentUser.uid]" to="/logs">
          <div class="slideBarAllLinks" v-if="settings">
            <div>L</div>
            <p v-bind:style="slideBarLinkText">Activity Log</p>
          </div>
        </router-link>
        <!-- <router-link to="/jewelry">
          <div class="slideBarAllLinks" v-if="settings">
            <div>J</div>
            <p v-bind:style="slideBarLinkText">Jewelry</p>
          </div>
        </router-link> -->
      </div>
      <!-- <router-link to="/billing">
        <div class="slideBarAllLinks" v-if="authUsers[currentUser.uid]">
          <div>B</div>
          <p v-bind:style="slideBarLinkText">Billing</p>
        </div>
      </router-link> -->
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import { auth } from "@/config/firebaseInit";
export default {
  name: "sidebar",
  data() {
    return {
      imageSrc: "/img/view_list.png",
      authUsers: {
        yKcoUU4PPSXLfc906EH2oCttVaX2: true,
        QOJqD6ErGaSPKLBNW86bPak1uzE3: true,
      },
      logoutwidth: {},
      styles: {},
      navbar: {},
      admin: null,
      slideBarLink: {},
      slideBarLinkText: {},
      dashboard: false,
      request: false,
      toggleMin: true,
      diamonds: false,
      settings: false,
      result: false,
    };
  },
  props: {
    activeColor: {
      type: String,
      default: "green",
      validator: (value) => {
        let acceptedValues = [
          "",
          "purple",
          "azure",
          "green",
          "orange",
          "danger",
          "rose",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    backgroundImage: {
      type: String,
      default: "./img/sidebar-2.jpg",
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: (value) => {
        let acceptedValues = ["", "black", "white", "red"];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    logo: {
      type: String,
      default: "/img/logo.png",
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    goTo(url, action) {
      if (action) {
        let ref = db.collection(url).doc();
        return this.$router.push({ path: "/" + url + "/" + ref.id });
      }
      this.$router.push({ path: "/" + url });
    },
    minimizeSidebar() {
      if (this.toggleMin == false) {
        this.toggleMin = true;
        this.imageSrc = "/img/view_list.png";
      } else {
        this.toggleMin = false;
        this.imageSrc = "/img/more_vert.png";
      }
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },

    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("login");
        });
    },
    sidebarOn() {
      this.styles = {
        width: "280px",
      };
      this.logoutwidth = {
        width: "85%",
        "-webkit-transition": "0.5s",
        transition: "0.5s",
      };
      this.navbar = {
        width: "92%",
        "-webkit-transition": "0.5s",
        transition: "0.5s",
      };
      this.slideBarLink = {
        width: "225px",
        "-webkit-transition": "0.5s",
        transition: "0.5s",
      };
      this.slideBarLinkText = {
        opacity: "1",
        "-webkit-transition": "3s",
        transition: "3s",
      };
    },
    sidebarOff() {
      if (this.toggleMin != false) {
        this.styles = {
          width: "120px",
        };
        this.logoutwidth = {
          width: "20%",
          "-webkit-transition": "0.5s",
          transition: "0.5s",
        };
        this.navbar = {
          width: "30%",
          "-webkit-transition": "0.5s",
          transition: "0.5s",
        };
        this.slideBarLink = {
          width: "50px",
          "-webkit-transition": "0.5s",
          transition: "0.5s",
        };
        this.slideBarLinkText = {
          opacity: "0",
          "-webkit-transition": "0.5s",
          transition: "0.5s",
        };
      }
    },
  },
  computed: {
    currentUser() {
      return auth.currentUser;
    },
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
      };
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style>
.menuAction {
  background: rgb(249, 248, 248, 0.36);
}
.miniSidebar {
  float: right;
  margin: 20px 0;
  cursor: pointer;
}
.minimizeSidebar {
  float: right;
}
.slideBarAllLinks {
  border-radius: 5px;
  width: 225px;
  display: flex;
  height: 40px;
  margin: 10px 0 0 30px;
  cursor: pointer;
}
.slideBarAllLinks p {
  color: #ffffff;
  margin: 9px 0 0 30px;
}
.slideBarAllLinks div {
  color: #ffffff;
  margin: 9px 0 0 20px;
}
/* .slideBarAllLinks:hover{
    background: #363636;
  } */
.navbar-bord {
  width: 30%;
  height: 2px;
  background: white;
  margin: 10px 20px 0 20px;
}
.sideBar-logo img {
  width: 28%;
  /* margin: 0 0 0 10px; */
  margin-left: 24px;
  margin-bottom: 10px;
}
.logout {
  width: 20%;
  text-align: center;
  color: #ffffff;
  font-size: 17px;
  white-space: pre;
  color: rgb(255, 0, 0);
  margin: -20px 0 0 31px;
}
.slideBar_link {
  width: 50px;
  height: 40px;
  margin: 20px 0 0 12%;
  border-radius: 5px;
  padding: 5px 0 0 10px;
  display: flex;
  overflow: hidden;
  cursor: pointer;
}
.slideBar_link:hover {
  background: rgb(249, 248, 248, 0.36);
}
.slideBar_link p {
  color: #ffffff;
  margin: 2px 0 0 20px;
  font-size: 15px;
  opacity: 0;
  width: 140px;
}
.slideBar_link img {
  width: 30px;
  height: 30px !important;
}
.slideBar_link div {
  margin: 5px 0;
  color: #ffffff;
  font-size: 10px;
}
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
</style>
