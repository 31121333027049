<template>
  <popup :styleFormat="{ width: '300px' }" @close="closeModal" v-if="showPopup">
    <template slot="header">
      <h3>Enter {{ itemType }}'s DIS Certificate Number</h3>
    </template>
    <template slot="body">
      <div>
        Use ClientSKU For Video
        <input v-model="useClientSKUForVideo" type="checkbox" name="" id="" />
      </div>
      <div>
        Single Cert
        <input v-model="searchOptionSel" type="radio" name="" value="single" />
        Multiple Cert
        <input
          v-model="searchOptionSel"
          type="radio"
          name=""
          value="multiple"
        />

        <md-snackbar
          md-position="center"
          :md-duration="5000"
          :md-active.sync="showSnackbar"
          md-persistent
        >
          <span
            >You Can Enter Multiple Certificate Numbers To Retrieve The Data,
            Use Commas To Separate Each Certificate.
          </span>
        </md-snackbar>
      </div>
      <md-field>
        <md-input type="text" :placeholder="placeholder" v-model="certNum" />
      </md-field>
    </template>
    <template slot="footer">
      <md-button
        :style="{ 'background-color': 'green !important' }"
        @click="addItemToInventory"
        >Continue</md-button
      >
      <md-button
        :style="{ 'background-color': 'red !important' }"
        @click="closeModal"
        >Cancel</md-button
      >
    </template>
  </popup>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { functions } from "@/config/firebaseInit.js";
import Swal from "sweetalert2";
export default {
  props: ["showPopup", "itemType"],
  components: {
    popup: Modal,
  },
  data() {
    return {
      placeholder: "Enter DIS Number Here",
      showSnackbar: false,
      useClientSKUForVideo: false,
      searchOptionSel: "single",
      certNum: null,
      addDiamondFromAPI: functions.httpsCallable("addDiamondFromAPI"),
      addJewelryFromAPI: functions.httpsCallable("addJewelryFromAPI"),
      fetchAndSaveItemFromDiamondTrack: functions.httpsCallable(
        "fetchAndSaveItemFromDiamondTrack"
      ),
    };
  },
  methods: {
    addItemToInventory() {
      let loader = this.$loading.show({
        // Optional parameters
        container: null,
        canCancel: false,
      });

      if (!this.certNum) {
        loader.hide();
        this.closeModal();
        return Swal.fire("Error!", "Can't leave field empty", "error");
      }
      this.certNum = this.certNum.replace(/\s/g,'');
      const isMultiple = this.searchOptionSel === "multiple";
      this.fetchAndSaveItemFromDiamondTrack({
        certNum: isMultiple ? this.certNum.split(",") : this.certNum,
        type: this.itemType,
        isMultiple: isMultiple,
        useClientSKU: this.useClientSKUForVideo
      })
        .then((res) => {
          loader.hide();
          this.closeModal();
          return Swal.fire("", res.data.message, "success").then(() => {
            this.$emit("fetch-data");
          });
        })
        .catch((err) => {
          loader.hide();
          this.closeModal();
          return Swal.fire(
            "Error: ",
            err.message + " Please try again later",
            "error"
          );
        });

      // if (this.itemType == "Diamond") {
      //   this.addDiamondFromAPI(this.certNum)
      //     .then((res) => {
      //       loader.hide();
      //       this.closeModal();
      //       return Swal.fire("", res.data.message, "success").then(() => {
      //         this.$emit("fetch-data");
      //       });
      //     })
      // .catch((err) => {
      //   loader.hide();
      //   this.closeModal();
      //   return Swal.fire(
      //     "Error: ",
      //     err.message + " Please try again later",
      //     "error"
      //   );
      // });
      // } else {
      //   this.addJewelryFromAPI(this.certNum)
      //     .then((res) => {
      //       loader.hide();
      //       this.closeModal();
      //       return Swal.fire("", res.data.message, "success").then(() => {
      //         this.$emit("fetch-data");
      //       });
      //     })
      //     .catch((err) => {
      //       loader.hide();
      //       this.closeModal();
      //       return Swal.fire(
      //         "Error: ",
      //         err.message + " Please try again later",
      //         "error"
      //       );
      //     });
      // }
    },
    closeModal() {
      this.certNum = null;
      this.$emit("close");
    },
  },
  watch: {
    searchOptionSel(v) {
      if (v == "multiple") {
        this.showSnackbar = true;
        this.placeholder = "Use comma to separate mult cert#";
        this.useClientSKUForVideo = true
      } else {
        this.showSnackbar = false;
        this.placeholder = "Enter DIS Certificate number Here";
        this.useClientSKUForVideo = false
      }
    },
  },
};
</script>

<style>
</style>