<template>
  <div class="diamonds">
    <div class="body">
      <div class="area">
        <div class="dropdown diamond_select">
          <label>Diamond Type</label>
          <v-select
            ref="selectedTemplate"
            :options="templates"
            label="name"
            v-model="selectedTemplate"
            :searchable="false"
            :clearable="false"
            @input="resetCanvas"
          >
            <template #option="{ name, image }">
              <img
                class="preview"
                :src="require('@/assets/img/diamond-shapes/' + image)"
              />
              <p class="dropdown_text">{{ name }}</p>
            </template>
          </v-select>
          <div class="dropdown">
            <label>Imperfection Type</label>
            <button
              v-if="add_imperfection_btn"
              style="background-color: green;"
              class="add_imperfection_btn btn"
              @click="add_imperfection_btn = !add_imperfection_btn"
            >
              Add Imperfection
            </button>

            <v-select
              v-if="!add_imperfection_btn"
              ref="selectedImperfection"
              :options="imperfections"
              label="name"
              v-model="imperfection"
              @input="selectImperfectionNew"
              :searchable="false"
              :clearable="false"
              placeholder="Click here"
            >
              <template #option="{ name, image }">
                <img
                  class="imperfection_preview"
                  :src="require('@/assets/img/imperfections/' + image)"
                />
                <p class="dropdown_text">{{ name }}</p>
              </template>
            </v-select>
          </div>
          <div class="lower-bottom">
            <button
              v-if="currentImperfections.length"
              class="save btn"
              style="background-color: green;"
              @click="savePop()"
            >
              Save Image
            </button>
            <button class="cancel btn" @click="$router.go(-1)">Cancel</button>
          </div>
        </div>
        <div id="fabric-holder" class="selection fabric-holder">
          <h5 class="title">
            Hover over the diamond and click to add imperfections
          </h5>
          <div class="top"></div>
          <div class="editor">
            <div class="canvas-image" ref="canvasimg">
              <canvas id="canvas" width="540" height="277.776"></canvas>
              <img
                ref="img"
                class="editorImg"
                :src="
                  require('@/assets/img/diamond-shapes/' +
                    selectedTemplate.image)
                "
              />

              <div
                id="drop"
                v-if="1 == 2"
                :style="{ top: popup_top, left: popup_left }"
              >
                <div class="dropdown">
                  <v-select
                    ref="selectedImperfection"
                    :options="imperfections"
                    label="name"
                    v-model="imperfection"
                    @input="selectImperfection"
                    :searchable="false"
                    :clearable="false"
                    placeholder="Click here"
                  >
                    <template #option="{ name, image }">
                      <img
                        class="imperfection_preview"
                        :src="require('@/assets/img/imperfections/' + image)"
                      />
                      <p class="dropdown_text">{{ name }}</p>
                    </template>
                  </v-select>
                </div>
              </div>

              <transition name="fade">
                <div
                  id="drop"
                  v-if="clicked"
                  :style="{ top: popup_top, left: popup_left }"
                >
                  <div class="dropdown">
                    <div class="delete-imp" @click="deleteImp()">
                      <p class="delete-text">X</p>
                    </div>
                  </div>
                </div>
              </transition>
            </div>

            <div class="bottom">
              <!-- <p v-if="currentImperfections.length" class="imperfectionTitle">
                Current Imperfections
              </p>
              <div class="imperfectionsChosen">
                <div
                  class="currentImperfections"
                  v-for="(imperfection, i) in currentImperfections"
                  :key="i"
                >
                  <div class="chosen_imperfection">
                    <div
                      class="clickable_imperfection"
                      @click="highlightChoice(i)"
                    >
                      <img
                        class="current_imperfection_img"
                        :src="
                          require('@/assets/img/imperfections/' +
                            imperfection.image)
                        "
                      />
                      <p>{{ imperfection.name }}</p>
                    </div>
                    <div class="delete" @click="deleteImperfection(i)">
                      <p>x</p>
                    </div>
                  </div>
                </div>
              </div>-->
              <div
                v-if="currentImperfections.length"
                class="display_imperfection_icons"
              >
                <label for="checkbox">
                  Would you like the chosen imperfection icons displayed on the
                  printed out certification?
                </label>
                <input
                  type="checkbox"
                  id="checkbox"
                  v-model="display_imperfection_icons"
                />
              </div>
            </div>
          </div>
        </div>
        <transition name="fade">
          <div class="popholder" v-if="clickedsave">
            <div
              class="popbackground"
              @click="clickedsave = false"
              v-if="clickedsave"
            ></div>
            <div v-if="clickedsave" class="popup">
              <div
                class="close"
                @click="clickedsave = false"
                style="cursor: pointer"
              >
                x
              </div>
              <label>Enter Name</label>
              <input type="text" name="Name:" class="name" v-model="name" />
              <button
                class="save btn"
                style="background-color: green;"
                @click="save()"
              >
                Save Image
              </button>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { db, auth } from "@/config/firebaseInit";
import Vue from "vue";
import firebase from "firebase";
import "firebase/firestore";
// import VueSelect from "vue-select";
// import "vue-select/dist/vue-select.css";

import { fabric } from "fabric";

import Swal from "sweetalert2";


// Vue.component("v-select", VueSelect);

export default {
  name: "Imperfection Details",
  components: {},
  data() {
    return {
      name: "",
      canvas: null,
      canvasDesignData: [],
      created: null,
      last_edited: null,
      imperfection: "",
      active_object: null,
      object_selected: false,
      clicked: false,
      clickedsave: false,
      clickedLocation: null,
      add_imperfection_btn: true,
      currentImperfections: [],
      imperfectionAddedList: [],
      imperfectionSize: 50,
      imperfection_obj: null,
      display_imperfection_icons: true,
      popup_top: 0,
      popup_left: 0,
      shadow: "",
      createNewId: null,
      createNewTemplate: null,
      selectedTemplate: {
        name: "Asscher",
        image: "IMAGE-ASSCHER.jpg",
      },
      templates: [
        {
          name: "Asscher",
          image: "IMAGE-ASSCHER.jpg",
        },
        {
          name: "Cushion",
          image: "IMAGE-CUSHION.jpg",
        },
        {
          name: "Emerald",
          image: "IMAGE-EMERALD.jpg",
        },
        {
          name: "Heart",
          image: "IMAGE-HEART.jpg",
        },
        {
          name: "Marquise",
          image: "IMAGE-MARQUISE.jpg",
        },
        {
          name: "Oval",
          image: "IMAGE-OVAL.jpg",
        },
        {
          name: "Pear",
          image: "IMAGE-PEAR.jpg",
        },
        {
          name: "Round",
          image: "IMAGE-ROUND.jpg",
        },
        {
          name: "Princess",
          image: "IMAGE-PRINCESS.jpg",
        },
        {
          name: "Radiant",
          image: "IMAGE-RADIANT.jpg",
        },
      ],
      imperfections: [
        {
          name: "abrasion",
          image: "abrasion.png",
        },
        {
          name: "extra facet",
          image: "extra_facet.png",
        },
        {
          name: "nick",
          image: "nick.png",
        },
        {
          name: "pit",
          image: "pit.png",
        },
        {
          name: "polish lines",
          image: "polish_lines.png",
        },
        {
          name: "scratch",
          image: "scratch.png",
        },
        {
          name: "surface graining",
          image: "surface_graining.png",
        },
        {
          name: "internal graining",
          image: "internal_graining.png",
        },
        {
          name: "internal laser drill",
          image: "internal_laser_drill.png",
        },
        {
          name: "bruise",
          image: "bruise.png",
        },
        {
          name: "cavity",
          image: "cavity.png",
        },
        {
          name: "chip",
          image: "chip.png",
        },
        {
          name: "cloud",
          image: "cloud.png",
        },
        {
          name: "crystal",
          image: "crystal.png",
        },
        // {
        //   name: "etch channel",
        //   image: "etch_channel.png"
        // },
        {
          name: "feather",
          image: "feather.png",
        },
        {
          name: "feather filled",
          image: "feather_filled.png",
        },
        {
          name: "indented natural",
          image: "indented_natural.png",
        },
        {
          name: "knot",
          image: "knot.png",
        },
        {
          name: "laser drill hole",
          image: "laser_drill_hole.png",
        },
        {
          name: "natural",
          image: "natural.png",
        },
        {
          name: "needle",
          image: "needle.png",
        },
        {
          name: "pinpoint",
          image: "pinpoint.png",
        },
        {
          name: "twinning wisp",
          image: "twinning_wisp.png",
        },
      ],
    };
  },
  created() {
    this.createNewId = this.$route.params.id;
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.createFabic();
        if (this.createNewId) {
          this.editCanvas();
        }
      }, 300);
    });
  },
  methods: {
    savePop() {
      this.clickedsave = !this.clickedsave;
      this.clicked = false;
      this.canvas.discardActiveObject().renderAll();
    },
    editCanvas() {
      db.collection("diamond_imperfections")
        .doc(this.createNewId)
        .get()
        .then((snap) => {
          if (snap.exists) {
            this.edit = true;
            this.imperfection_obj = snap.data();
            this.imperfection_obj.id = snap.id;
            this.selectedTemplate = snap.data().canvas_design_data.image_ref;
            this.display_imperfection_icons = this.imperfection_obj.display_imperfection_icons;
            this.created = snap.data().created;
            this.name = snap.data().name;
            this.$nextTick(() => {
              this.canvas.setDimensions({
                width: this.$refs.img.width,
                height: this.$refs.img.height,
              });
            });

            let i = 0;
            snap.data().canvas_design_data.canvas_data.forEach((element) => {
              let vm = this;
              this.imperfectionAddedList = snap.data().imperfectionAddedList;
              let img = new Image();
              let imgRef = snap.data().imperfections[i].image;
              img.src = require("@/assets/img/imperfections/" + imgRef);

              fabric.Image.fromURL(img.src, function(img) {
                img.set({
                  top: element.top,
                  left: element.left,
                  opacity: 1,
                });
                vm.canvas.add(img);
              });
              this.currentImperfections.push(snap.data().imperfections[i]);
              i++;

              this.canvas.renderAll();
              this.add_imperfection_btn = !this.add_imperfection_btn;
            });
          }
        });
    },
    createFabic() {
      let canvas = new fabric.Canvas("canvas");
      this.canvas = canvas;
      this.canvas.backgroundColor = "transparent";
      let vm = this;
      this.canvas.on("object:moving", function(e) {
        vm.popup_top = e.target.top + "px";
        vm.popup_left = e.target.left + "px";
      });
      this.canvas.on("mouse:down", function(e) {
        if (!vm.canvas.getActiveObject()) {
          vm.object_selected = false;

          vm.clicked = false;
          var pointer = canvas.getPointer(event.e);
          canvas.discardActiveObject().renderAll();

          vm.clickedLocation = { top: pointer.y, left: pointer.x };
        } else {
          vm.active_object = e.target;
          vm.highlightChoice(canvas._objects.indexOf(e.target));
          vm.object_selected = true;
        }
      });
      this.canvas.setDimensions({
        width: this.$refs.img.width,
        height: this.$refs.img.height,
      });
      this.canvas.defaultCursor = "cell";
      this.canvas.renderAll();
    },
    resetCanvas(template) {
      this.$nextTick(() => {
        this.canvas.clear();
        this.clicked = false;
        this.currentImperfections = [];
        this.imperfectionAddedList = [];
        this.canvas.setDimensions({
          width: this.$refs.img.width,
          height: this.$refs.img.height,
        });
      });
    },
    resizeCanvas() {},
    highlightChoice(active_object) {
      this.clicked = true;
      this.popup_top = this.canvas._objects[active_object].top + "px";
      this.popup_left = this.canvas._objects[active_object].left + "px";
      this.canvas.setActiveObject(this.canvas._objects[active_object]);
      this.canvas.renderAll();
    },
    deleteImperfection(i) {
      let value = this.currentImperfections[i];

      if (
        this.currentImperfections.indexOf(value) ==
        this.currentImperfections.lastIndexOf(value)
      ) {
        this.imperfectionAddedList.splice(
          this.imperfectionAddedList.indexOf(value),
          1
        );
      }
      this.canvas.remove(this.canvas._objects[i]);
      this.currentImperfections.splice(i, 1);

      this.canvas.renderAll();
    },
    deleteImp() {
      let i = this.canvas._objects.indexOf(this.active_object);
      let value = this.currentImperfections[i];
      if (
        this.currentImperfections.indexOf(value) ==
        this.currentImperfections.lastIndexOf(value)
      ) {
        this.imperfectionAddedList.splice(
          this.imperfectionAddedList.indexOf(value),
          1
        );
      }
      this.currentImperfections.splice(i, 1);
      this.canvas.remove(this.canvas._activeObject);
      this.clicked = false;
      this.canvas.renderAll();
    },
    convertToDataURL() {
      this.clicked = false;
      this.canvas.discardActiveObject();
      this.canvas.renderAll();

      const el = this.$refs.canvasimg;

      const options = {
        type: "dataURL",
        backgroundColor: null,
      };

      return this.$html2canvas(el, options);
    },
    async save() {
      this.clicked = false;
      this.canvas.discardActiveObject();

      if (!this.created) {
        this.created = new Date().toLocaleString();
        this.last_edited = this.created;
      } else {
        this.last_edited = new Date().toLocaleString();
      }

      let loader = this.$loading.show({
        container: null,
        canCancel: false,
      });
      let canvas_design_data = {
        canvas_data: [],
        image_ref: this.selectedTemplate,
      };
      this.canvas._objects.forEach((obj) => {
        let new_obj = {
          fill: obj.fill,
          left: obj.left,
          top: obj.top,
          scaleX: obj.scaleX,
          scaleY: obj.scaleY,
          width: obj.width,
          label: obj.label ? obj.label : "",
          height: obj.height,
        };
        canvas_design_data.canvas_data.push(new_obj);
      });

      if (this.name) {
        var dataURL = await this.convertToDataURL();

        var message = dataURL;

        var storage = firebase.storage();

        var storageRef = storage.ref("diamond_imperfections/" + Date.now());

        let ref = await storageRef
          .putString(message, "data_url")
          .then(function(snapshot) {
            return snapshot.ref.getDownloadURL().then((downloadURL) => {
              return { ref: snapshot.metadata.fullPath, url: downloadURL };
            });
          });
        let data = {
          user_id: auth.currentUser.uid,
          created_timestamp: this.created
            ? new Date(this.created).getTime()
            : Date.now(),
          name: this.name,
          image_ref: ref.ref,
          url: ref.url,
          diamond_cut: this.selectedTemplate,
          selectedTemplate: this.selectedTemplate,
          imperfections: this.currentImperfections,
          imperfectionAddedList: this.imperfectionAddedList,
          imperfection_connect: this.imperfection_obj
            ? this.imperfection_obj.imperfection_connect
            : false,
          display_imperfection_icons: this.display_imperfection_icons,
          canvas_design_data: canvas_design_data,
          created: this.created,
          last_edited: this.last_edited,
          printed: false,
        };

        return db
          .collection("diamond_imperfections")
          .doc(this.createNewId)
          .set(data, { merge: true })
          .then(() => {
            loader.hide();
            return Swal.fire("Success", "Imperfection has updated successfully", "success").then(() => {
              this.$router.push("/imperfections");
            });
          })
          .catch((err) => {
            console.log(err);
            loader.hide();
            return Swal.fire(
              "Error",
              err.message + " Your file couldn't be uploaded, Try again later",
              "warning"
            );
          });
      } else {
        loader.hide();
        return Swal.fire("Error", "Please provide a name", "warning");
      }
    },

    sliderChange(value) {
      this.active_object.height = value;
    },

    selectImperfectionNew(value) {
      let vm = this;

      let img = new Image();
      img.src = require("@/assets/img/imperfections/" + value.image);

      fabric.Image.fromURL(img.src, function(img) {
        img.set({
          top: 138.888,
          left: 330,
        });
        vm.canvas.add(img);
      });
      let exists = false;
      this.imperfectionAddedList.forEach((element) => {
        if (element.name == value.name) {
          exists = true;
        }
      });
      if (!exists) {
        this.imperfectionAddedList.push(value);
      }
      this.currentImperfections.push(value);
      this.canvas.renderAll();
      this.imperfection = "";
      this.add_imperfection_btn = !this.add_imperfection_btn;
    },
    selectImperfection(value) {
      let vm = this;
      let img = new Image();
      img.src = require("@/assets/img/imperfections/" + value.image);

      fabric.Image.fromURL(img.src, function(img) {
        img.set({
          top: vm.clickedLocation.top,
          left: vm.clickedLocation.left,
        });
        vm.canvas.add(img);
      });

      this.currentImperfections.push(value);
      this.canvas.renderAll();
      this.imperfection = "";
    },
  },
};
</script>

<style lang="scss">
.loader {
  height: 60px;
}
.delete-imp {
  position: relative;
  top: -40px;
  left: 97px;
  background: white;
  padding: 0px 11px;
  border-radius: 45%;
  color: red;
  box-shadow: 2px 2px grey;
  font-weight: 700;
  height: 35px;
}
.delete-text {
  position: relative;
  top: -10px;
}
.chosen_imperfection {
  display: flex;
  align-items: center;
  p {
    margin: 0 !important;
  }
  .clickable_imperfection {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
      margin-left: 5px;
    }
  }
}
.current_imperfection_img {
  width: 35px;
  display: flex;
}
.area {
  padding: 20px;
  padding-top: 40px;
  display: flex;
  justify-content: center;
  background: #eaeaea;
  // overflow: hidden;
  // overflow-y: auto;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
}
// .vs__dropdown-menu {
//   .vs__dropdown-option {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-around;
//   }
// }
.imperfection_preview {
  width: 20%;
}
.preview {
  width: 40%;
}
.popbackground {
  width: 100%;
  height: 100%;
  background: gray;
  position: absolute;
  left: 0;
  z-index: 1;
  top: 0;
  opacity: 75%;
}
.popholder {
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  .popup {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 2;
    padding-top: 0px;
    width: 90%;
    padding: 50px 30px;
    max-width: 400px;
    overflow: auto;
    background-color: white;
    box-shadow: 0px 0px 5px #d7d7d7;
    .label {
      font-size: 14px;
    }
    .name {
      width: 100%;
      margin-bottom: 20px;
      margin-top: 5px;
      border: none;
      box-shadow: 0px 0px 5px #d7d7d7;
      padding: 10px 15px;
      font-size: 16px;
    }
    .save {
      width: auto;
    }
    .close {
      position: absolute;
      padding: 10px 15px;
      right: 0;
      top: 0;
      cursor: pointer;
      font-size: 18px;
      opacity: 0.5;
    }
  }
}

.lower-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
  margin-top: 30px;
  @media screen and (max-width: 700px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
  }
  .btn {
    margin-bottom: 10px;
    @media screen and (max-width: 700px) {
      margin-right: 15px;
    }
  }
}
#drop {
  position: absolute;
  z-index: 10;
  .v-select {
    background: white;
    min-width: 250px;
    .dropdown_text {
      margin: 0;
      margin-left: 10px;
    }
    .vs__dropdown-menu .vs__dropdown-option {
      padding: 5px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .v-select {
    background: white;
  }
  .v-select .vs--single .vs--unsearchable {
    width: 100%;
  }
  label {
    background: white;
    margin-bottom: 0;
    padding: 5px;
    padding-bottom: 5px;
    opacity: 1;
  }
}
.canvas-image {
  position: relative;
  .canvas-container {
    position: absolute !important;
    cursor: cell;
  }
}
.diamonds {
  overflow:hidden !important;
   height: auto;
  // width: 100%;
  position: absolute;
  background-color: rgba(211, 211, 211, 0.541);
  .body {
    text-align: center;
    width: 100%;

    .selection {
      max-width: 1000px;
      height: auto;
      width: 75%;
      position: relative;
      background-color: white;
      padding: 30px 30px;
      @media screen and (max-width: 700px) {
        width: 100%;
      }
      &.fabric-holder {
        .title {
          margin-top: 0;
          text-align: left;
        }
      }

      .top {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
      }
      .editor {
        .editorImg {
          max-width: 100%;
        }
        .bottom {
          position: relative;
          width: 100%;
          .imperfectionTitle {
            margin-top: 0px;
            opacity: 0.5;
            font-size: 13px;
            text-align: left;
            margin-bottom: 5px;
          }
          .imperfectionsChosen {
            display: flex;
            flex-direction: row;
            margin-top: 5px;
            flex-wrap: wrap;
          }
          .currentImperfections {
            width: auto;
            margin-right: 10px;
            display: flex;
            align-items: center;
            flex-direction: row;
            box-shadow: 0px 0px 5px #d7d7d7;
            padding-right: 20px;
            cursor: pointer;
            margin-bottom: 30px;
            img {
              height: 40px;
            }
          }
          .delete {
            padding-left: 15px;
            color: gray;
            opacity: 0.5;
          }
          .display_imperfection_icons {
            left: 0px;
            display: flex;
            label {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
.createNew {
  width: 100% !important;
}

.cancel {
  background-color: red;
}
.btn {
  text-transform: uppercase;
  padding: 10px 25px;
  font-weight: bold;
  font-size: 10px;
  width: auto;
  border: none;
  outline: none;
  color: white;
  border-radius: 25px;
  cursor: pointer;
}
.imperfectionSizeTitle {
  opacity: 0.5;
  font-size: 13px;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 5px;
}
.vue-slider-rail {
  width: 200px;
}

.diamond_select {
  padding: 30px 25px;
  width: 300px;
  padding-top: 0;
  .v-select {
    background: white;
    min-width: 250px;
    .dropdown_text {
      margin: 0;
      margin-left: 10px;
    }
    .vs__dropdown-menu .vs__dropdown-option {
      padding: 5px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}
.dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (max-width: 700px) {
    width: 100%;
  }

  label {
    opacity: 0.5;
    font-size: 13px;
    text-align: left;
    margin-bottom: 5px;
  }
}
</style>
