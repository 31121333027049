<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card class="light">
        <md-card-content>
          <md-field class="search-bar">
            <label>Search by Cert # or SKU</label>
            <md-input
              type="search"
              clearable
              placeholder="Search records by Cert# or Client SKU"
              v-model="filterBy.search"
            ></md-input>
          </md-field>
          <div class="filters">
            <div class="filter">
              <p>Start From:</p>
              <md-datepicker
                class="datepicker"
                @input="validateDateRange('startDate')"
                v-model="filterBy.startDate"
              />
            </div>
            <div class="filter">
              <p>End To:</p>
              <md-datepicker
                class="datepicker"
                @input="validateDateRange('endDate')"
                v-model="filterBy.endDate"
              />
            </div>
            <div class="filter">
              <p>Filter By Template</p>
              <v-select
                :appendToBody="true"
                :deselectFromDropdown="true"
                v-model="filterBy.templates"
                :options="templates"
                label="name"
                :reduce="(t) => t.id"
              >
              </v-select>
            </div>

            <div class="filter">
              <p>Filter By Client</p>
              <v-select
                :appendToBody="true"
                :deselectFromDropdown="true"
                v-model="filterBy.companies"
                :options="companies"
                label="name"
                :reduce="(p) => p.id"
              >
              </v-select>
            </div>
            <div class="filter">
              <p>Filter By Shape</p>
              <v-select
                :appendToBody="true"
                v-model="filterBy.shape"
                :options="shapes"
              >
              </v-select>
            </div>
            <div class="filter">
              <p>Filter By Origin</p>
              <v-select
                :appendToBody="true"
                v-model="filterBy.origin"
                :options="['Lab Grown', 'Natural Stone', 'Natural']"
              >
              </v-select>
            </div>
            <div class="filter"></div>
            <div class="filter">
              <md-radio value="with" v-model="filterBy.imperfection"
                ><span class="span-checkbox"
                  >Show With Imperfections</span
                ></md-radio
              >
              <md-radio value="without" v-model="filterBy.imperfection"
                ><span class="span-checkbox"
                  >Show Without Imperfections</span
                ></md-radio
              >
            </div>

            <div
              class="flex flex-row justify-content-between"
              style="width: 300px; bottom: 5px; position: absolute; right: 0"
            >
              <md-button
                style="background-color: green !important"
                class="text-btn"
                @click="handleFilter"
                >Filters</md-button
              >
              <md-button
                style="background-color: red !important"
                class="text-btn"
                @click="clearFilters"
                >Clear Filters</md-button
              >
            </div>
          </div>
        </md-card-content>
      </md-card>

      <md-card>
        <md-card-content>
          <div class="btn-row stats_holder">
            <md-button class="md-primary md-round md-small" @click="exportQRCSV"
              >Export for QR</md-button
            >
            <md-button class="md-primary md-round md-small" @click="exportCSV"
              >Export</md-button
            >
            <md-button
              class="md-primary md-round md-small"
              @click="promptFileUpload"
              >Import</md-button
            >
            <input
              style="display: none"
              id="inputFile"
              type="file"
              @input="importCSV"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <md-button class="md-primary md-round md-small" @click="newDiamond"
              >Add Diamond</md-button
            >
            <md-button
              class="md-primary md-round md-small"
              @click="showPopup = true"
              >Add From David's System</md-button
            >
          </div>
          <md-table
            md-fixed-header
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
            id="diamonds-table"
          >
            <md-table-toolbar :style="{ marginBottom: '20px' }">
              <md-field>
                <label for="diamond">Per page</label>
                <md-select v-model="pagination.perPage" name="diamond">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                    >{{ item }}</md-option
                  >
                </md-select>
              </md-field>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Created On" md-sort-by="created">{{
                item.created | format_date
              }}</md-table-cell>

              <md-table-cell md-label="Certificate #" md-sort-by="CertNum">{{
                item.CertNum || "N/A"
              }}</md-table-cell>

              <md-table-cell md-label="Client">{{
                item.Company.name || "None"
              }}</md-table-cell>

              <md-table-cell md-label="Customer">{{
                item.Customer || "N/A"
              }}</md-table-cell>

              <md-table-cell md-label="Template" md-sort-by="Template.name">{{
                item.Template.name
              }}</md-table-cell>

              <md-table-cell md-label="Weight">{{
                item.Weight || "None"
              }}</md-table-cell>

              <md-table-cell md-label="Shape">{{
                item.Shape || "None"
              }}</md-table-cell>

              <md-table-cell md-label="Origin">{{
                item.ManufacturedAs || "None"
              }}</md-table-cell>

              <md-table-cell md-label="Controls">
                <md-button
                  @click="openLink(item.CertNum)"
                  class="md-just-icon md-warning md-simple"
                >
                  <md-icon>open_in_new</md-icon>
                  <md-tooltip>View Digital Certificate</md-tooltip>
                </md-button>

                <md-button
                  :disabled="!item.imperfection"
                  @click="openURL(item.imperfection.url)"
                  class="md-just-icon md-warning md-simple"
                >
                  <md-icon>link</md-icon>
                  <md-tooltip>View Imperfections</md-tooltip>
                </md-button>

                <md-button
                  class="md-just-icon md-warning md-simple"
                  @click.native="handleEdit(item)"
                >
                  <md-icon>edit</md-icon>
                  <md-tooltip>Edit Certificate</md-tooltip>
                </md-button>

                <md-button
                  class="md-just-icon md-warning md-simple"
                  @click.native="handlePrint(item)"
                >
                  <md-icon>print</md-icon>
                  <md-tooltip>Print Certificate Card</md-tooltip>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class>
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
            @update-table="fetchData"
            :allowClick="Boolean(tableData.length + searchedData.length)"
          ></pagination>
        </md-card-actions>
      </md-card>
    </div>
    <popup
      @close="showPopup = false"
      :showPopup="showPopup"
      itemType="Diamond"
    />
  </div>
</template>

<script>
import { Pagination } from "@/components";
import { db, batch } from "@/config/firebaseInit";
import Swal from "sweetalert2";
import router from "@/router";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import Popup from "@/components/InventoryPopup.vue";
import moment from "moment";
import axios from "axios";

export default {
  components: {
    Pagination,
    popup: Popup,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData.slice(this.from, this.to);
      }
      return result;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableSize;
    },
  },
  data() {
    return {
      tableSize: 0,
      showPopup: false,
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 100, 250, 500],
        total: 0,
      },

      searchQuery: "",
      searchVal: "",
      propsToSearch: ["title", "fullname", "description"],
      tableData: [],
      unfilteredItems: [],
      searchedData: [],
      companies: [],
      fuseSearch: null,
      filterBy: {
        templates: "",
        companies: "",
        search: "",
        shape: "",
        origin: "",
        imperfection: "",
        startDate: null,
        endDate: null,
      },
      propsToSearch: ["title", "fullname", "description"],
      templates: [
        {
          name: "Everything",
          id: 1,
        },
        {
          name: "Everything (Excluding Cut)",
          id: 2,
        },
        {
          name: "Regular",
          id: 3,
        },
        {
          name: "Regular (Excluding No Fluorescence)",
          id: 4,
        },
        {
          name: "Minimalist",
          id: 5,
        },
        {
          name: "Extra Minimalist",
          id: 6,
        },
      ],
      showItemsWithVideo: false,
      showItemsWithoutVideo: false,
      showItemsWithImperfections: false,
      showItemsWithoutImperfections: false,
      polish: ["Poor", "Fair", "Good", "Very Good", "Excellent"],
      symmetry: ["Poor", "Fair", "Good", "Very Good", "Excellent"],
      fluorescence: ["Very Strong", "Strong", "Medium", "Faint", "None"],
      cuts: [
        "None",
        "Excellent",
        "Super Ideal",
        "Ideal",
        "Very Good",
        "Good",
        "Fair",
      ],
      clarity: [
        "I1",
        "I2",
        "I3",
        "SI",
        "SI1",
        "SI2",
        "SI3",
        "VS",
        "VS2",
        "VS1",
        "VVS2",
        "VVS1",
        "INFLAWLESS",
        "FLAWLESS",
      ],
      shapes: [
        "Asscher",
        "Baguette",
        "Cushion",
        "Heart",
        "Marquise",
        "Oval",
        "Pear",
        "Princess",
        "Radiant",
        "Rose",
        "Shield",
        "Taper",
        "Triangular",
        "Round",
        "Emerald",
        "Old Cut",
        "Triangular Cut",
        "Baguette Cut",
        "Trapeze Cut",
        "Shield Cut",
        "Taper Cut",
        "Half Moon Cut",
        "Rose Cut",
        "Square Emerald Cut",
        "Round Brilliant",
        "Cushion Modified Brilliant",
        "Cushion Brilliant",
        "Emerald Cut",
        "Heart Brilliant",
        "Oval Brilliant",
        "Marquise Brilliant",
        "Square Modified Brilliant",
        "Rectangular Modified Brilliant",
        "Pear Brilliant",
        "Cut-Cornered Square Modified Brilliant",
        "Cut-Cornered Rectangular Modified Brilliant",
       ,
      ],
      colors: [
        "Z",
        "Y",
        "X",
        "W",
        "V",
        "U",
        "T",
        "S",
        "R",
        "Q",
        "P",
        "O",
        "N",
        "M",
        "L",
        "K",
        "J",
        "I",
        "H",
        "G",
        "F",
        "E",
        "D",
        "A",
        "AA",
        "AAA",
        "AAAA",
        "C1",
        "C2",
        "C3",
        "C4",
        "C5",
        "C6",
        "C7",
        "C8",
        "Faint",
        "Very Light",
        "Light",
        "Intense",
        "Vivid",
        "Deep",
        "Fancy Light",
        "Fancy",
        "Fancy Intense",
        "Fancy Vivid",
        "Fancy Deep",
      ],
      routeChange: true,
      certificateRef: null,
    };
  },
  firestore() {
    return {
      // tableData: db.collection("diamond").orderBy("created", "desc"),
      companies: db.collection("companies"),
    };
  },

  async created() {
    this.certificateRef = db.collection("diamond");
    const size = await db.doc("analytics/diamond").get();
    this.tableSize = size.data().total;
    this.fetchData("start");
  },

  methods: {
    async handleFilter() {
      let ref = db.collection("diamond");
      let timeFilter = false;
      if (
        !this.filterBy.search &&
        !this.filterBy.imperfection &&
        !this.filterBy.templates &&
        !this.filterBy.companies &&
        !this.filterBy.shape &&
        !this.filterBy.origin &&
        !this.filterBy.startDate &&
        !this.filterBy.endDate
      ) {
        return false;
      }

      if (this.filterBy.startDate && this.filterBy.endDate) {
        timeFilter = true;
        let startDate = new Date(this.filterBy.startDate).getTime();
        let endDate = new Date(this.filterBy.endDate).setHours(24, 0, 0, 0);
        ref = ref.orderBy("created", "asc").startAt(startDate).endAt(endDate);
      }

      for (const key in this.filterBy) {
        if (Object.hasOwnProperty.call(this.filterBy, key)) {
          if (key != "startDate" && key != "endDate") {
            const element = this.filterBy[key];
            if (element) {
              if (key == "search") {
                ref = ref.where("CertNum", "==", element);
                let empty = await ref.get();
                empty = empty.empty;
                if (empty) {
                  ref = db.collection("diamond");
                  ref = ref.where("ClientSKU", "in", [element]);
                }
              }

              if (key == "templates") {
                ref = ref.where("Template.id", "==", element);
              }
              if (key == "companies") {
                ref = ref.where("Company.id", "==", element);
              }
              if (key == "shape") {
                ref = ref.where("Shape", "==", element);
              }
              if (key == "origin") {
                ref = ref.where("ManufacturedAs", "==", element);
              }
              if (key == "imperfection") {
                if (element == "with") {
                  ref = ref
                    .orderBy("imperfection")
                    .where("imperfection", "!=", null);
                }

                if (element == "without") {
                  ref = ref
                    .orderBy("imperfection")
                    .where("imperfection", "==", null);
                }
              }
            }
          }
        }
      }

      let container = timeFilter
        ? await ref.get()
        : await ref.orderBy("created", "desc").get();

      this.searchedData = container.docs.map((d) =>
        Object.assign({ id: d.id }, d.data())
      );
    },
    resetTable() {
      this.pagination.currentPage = 1;
      this.fetchData("start");
    },
    async fetchData(action) {
      if (this.searchedData.length) return false;
      if (action == "start") {
        try {
          const querySnapshot = await this.certificateRef
            .orderBy("created", "desc") // Change to your sorting criteria
            .endAt(0)
            .limit(this.pagination.perPage)
            .get();

          this.tableData = querySnapshot.docs.map((doc) =>
            Object.assign({ id: doc.id }, doc.data())
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else if (action == "next") {
        this.fetchNextData();
      } else if (action == "prev") {
        this.fetchPreviousData();
      } else if (action == "per-page") {
        let firstDoc = this.tableData[0];
        firstDoc = await this.productsRef.doc(firstDoc.id).get();
        const querySnapshot = await this.productsRef
          .orderBy("created", "desc") // Change to your sorting criteria
          .startAt(firstDoc)
          .limit(this.pagination.perPage)
          .get();

        this.tableData = querySnapshot.docs.map((doc) =>
          Object.assign({ id: doc.id }, doc.data())
        );
      }
    },
    async fetchNextData() {
      let lastDoc = this.tableData[this.tableData.length - 1];
      lastDoc = await this.certificateRef.doc(lastDoc.id).get();
      this.tableData = [];
      try {
        const querySnapshot = await this.certificateRef
          // .orderBy("created")
          .orderBy("created", "desc") // Change to your sorting criteria
          .startAfter(lastDoc)
          .limit(this.pagination.perPage)
          .get();

        this.tableData = querySnapshot.docs.map((doc) =>
          Object.assign({ id: doc.id }, doc.data())
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async fetchPreviousData() {
      let firstDoc = this.tableData[0];
      firstDoc = await this.certificateRef.doc(firstDoc.id).get();
      this.tableData = [];
      try {
        const querySnapshot = await this.certificateRef
          // .orderBy("created")
          .orderBy("created", "asc") // Change to your sorting criteria
          .startAfter(firstDoc)
          .limit(this.pagination.perPage)
          .get();

        this.tableData = querySnapshot.docs
          .map((doc) => Object.assign({ id: doc.id }, doc.data()))
          .reverse();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    clearFilters() {
      this.searchedData = [];
      for (const key in this.filterBy) {
        if (key === "startDate" || key === "endDate") {
          this.filterBy[key] = "";
        } else {
          this.filterBy[key] = "";
        }
      }
      this.resetTable();
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].toString().localeCompare(b[sortBy].toString());
        }
        return b[sortBy].toString().localeCompare(a[sortBy].toString());
      });
    },
    handlePrint(item) {
      let data = {
        CertNum: item.CertNum,
        CertificateComments: item.CertificateComments,
        Description: item.Description,
        ImageURL: "",
        Shape: item.Shape,
        Weight: item.Weight + "ct",
        Clarity: `${item.Clarity.value}${item.Clarity.asterisks}`,
        Color: `${item.Color.value}${item.Color.asterisks}`,
        Type: "Diamond",
        ClientSKU: item.ClientSKU,
      };
      return axios
        .post(
          "https://us-central1-diamonds-8cf72.cloudfunctions.net/disCertificateCardPrinterApi-updateDataTable",
          data
        )
        .then((res) => {
          console.log(res);
          Swal.fire("Data sent to server. You can now print");
        });
    },
    validateDateRange(key) {
      if (moment(this.filterBy.startDate).isAfter(this.filterBy.endDate)) {
        return Swal.fire(
          "Warning",
          "End date cannot be before start date.",
          "warning"
        ).then(() => {
          this.filterBy.startDate = null;
          this.filterBy.endDate = null;
        });
      }
      if (key === "startDate") {
        this.filterBy.endDate = this.filterBy.startDate;
      }
    },
    handleSearch(e) {
      const value = e;
      if (!value) {
        return (this.tableData = this.unfilteredItems);
      }
      this.tableData = this.unfilteredItems.filter((i) => {
        let cert = i.CertNum;
        let sku = i.ClientSKU;
        return cert.includes(value) || sku.includes(value);
      });
    },
    openLink(slug) {
      this.routeChange = false;
      if (
        window.location.hostname == "admin.diamondservicesusa.com" ||
        window.location.hostname == "diamonds-8cf72.web.app"
      ) {
        window.open(
          "https://certificates.diamondservicesusa.com/diamond/" + slug,
          "_blank"
        );
      } else {
        window.open(
          "https://digital-cert-staging-diamondservicesusa.firebaseapp.com/diamond/" +
            slug,
          "_blank"
        );
      }

      setTimeout(() => {
        this.routeChange = true;
      }, 4000);
    },
    openURL(url) {
      this.routeChange = false;
      window.open(url, "_blank");
      setTimeout(() => {
        this.routeChange = true;
      }, 4000);
    },
    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },

    exportCSV() {
      var wb = XLSX.utils.table_to_book(
        document.getElementById("diamonds-table"),
        {
          sheet: "Diamond Sheet",
        }
      );

      for (let key in wb.Sheets["Diamond Sheet"]) {
        if (key.includes("L") || key.includes("M")) {
          delete wb.Sheets["Diamond Sheet"][key];
        }
      }

      var wbExport = XLSX.write(wb, {
        sheetType: "xlsx",
        bookSST: true,
        type: "binary",
      });

      var excelBlob = new Blob(
        [this.s2ab(wbExport)],
        { type: "application/vnd.ms-excel;charset=utf-8" },
        "diamond.xlsx"
      );

      saveAs(excelBlob, "diamond.xlsx");
    },
    exportQRCSV() {
      let columns = "Certificate,DigitalCertificateURL,VideoURL\n";
      let rows = this.tableData
        .map(
          (d) =>
            `${d.CertNum},https://diamondservicesusa.firebaseapp.com/diamond/${d.CertNum},${d.Video.url}`
        )
        .join("\r\n");

      let csvContent = "data:text/csv;charset=utf-8," + columns + rows;
      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `DiamondQR.csv`);
      document.body.appendChild(link); // Required for FF
      link.click();
    },

    importCSV(oEvent) {
      var oFile = oEvent.target.files[0];
      var sFilename = oFile.name;

      var reader = new FileReader();
      var result = {};
      let sheetNames;

      reader.onload = (e) => {
        var data = e.target.result;
        data = new Uint8Array(data);
        var workbook = XLSX.read(data, { type: "array" });
        var result = {};
        workbook.SheetNames.forEach(function (sheetName) {
          sheetNames = sheetName;
          var roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
            header: 1,
          });
          if (roa.length) result[sheetName] = roa;
        });

        // see the result, caution: it works after reader event is done.
        let spredSheetHeader = result[sheetNames][0];
        result[sheetNames].splice(0, 1);
        let spredSheetData = result[sheetNames]
          .filter((dataArr) => dataArr.length > 0)
          .map((dataArr, i) => {
            let object = {};
            dataArr.forEach((data, i) => {
              if (data == "Yes") {
                data = true;
              } else if (data == "No") {
                data = false;
              }

              object[spredSheetHeader[i].toLowerCase()] = data;
            });
            return object;
          });

        this.ImportDataToDB(spredSheetData);
      };
      reader.readAsArrayBuffer(oFile);
    },

    async ImportDataToDB(spredSheetData) {
      for (let i = 0; i < spredSheetData.length; i++) {
        let ref = await db.collection("diamond").doc();
        let data = spredSheetData[i];
        data.contact = {
          name: null,
          email: null,
          phone: null,
        };
        await batch.set(ref, data);
      }
      await batch.commit().then(() => {
        return Swal.fire(
          "Success",
          "Rows in red are imported data that needs further information",
          "success"
        );
      });
    },
    promptFileUpload() {
      document.getElementById("inputFile").click();
    },
    newDiamond() {
      let ref = db.collection("diamond").doc();
      this.handleEdit(ref);
    },
    handleEdit(item) {
      let id = item.id;
      router.push(`/diamond/${id}`);
    },
    handleDelete(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to undo this",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "DELETE",
        cancelButtonText: "CANCEL",
      }).then((result) => {
        if (result.value) {
          return db
            .collection("diamond")
            .doc(item.id)
            .delete()
            .then(() => {
              Swal.fire("Deleted!", "The page has been deleted.", "success");
            });
        }
      });
    },
    goToReports(diamond) {
      router.push(`/diamonds/${diamond.id}`);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.routeChange) {
      next();
    }
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
