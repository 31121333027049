<template>
  <md-card class="md-card-stats">
    <md-card-header
      :style="{ 'background':' linear-gradient(60deg, #66bb6a, #43a047)' }"
      class="md-card-header-icon header-icon"
      :class="getClass(headerColor)"
    >
      <slot name="header"></slot>
    </md-card-header>
    <md-card-content>
      <slot name="content"></slot>
    </md-card-content>
    <md-card-actions md-alignment="left">
      <slot name="footer"></slot>
    </md-card-actions>
  </md-card>
</template>
<script>
export default {
  name: "stats-card",
  props: {
    headerColor: {
      type: String,
      default: "",
    },
  },
  methods: {
    getClass: function (headerColor) {
      return "md-card-header-" + headerColor + "";
    },
  },
};
</script>

<style scoped>
.header-icon {
  width: 100px !important;
  height: 100px !important;
  position: absolute !important;
  margin-top: -30px !important;
}
</style>
