// =========================================================
// * Vue Material Dashboard PRO - v1.3.1
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import VueRouter from "vue-router";
import DashboardPlugin from "./material-dashboard";
import Utilities from "./utility/main";
// import VueNouislider from 'vue-nouislider';


// Plugins
import App from "./App.vue";
import Chartist from "chartist";
import firebase from "firebase";
import { firestorePlugin } from "vuefire";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueHtml2Canvas from "vue-html2canvas";
// router setup
import router from "@/router";

// Mask Input
import VueTheMask from "vue-the-mask";

//QR-Code
import QrcodeVue from "qrcode.vue";

//Vue Color
import { Chrome } from "vue-color";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import "./registerServiceWorker";

// plugin setup
Vue.use(VueHtml2Canvas);
Vue.use(Loading);
Vue.use(VueTheMask);
Vue.use(VueRouter);
Vue.component("v-select", vSelect);
Vue.component("color-picker", Chrome);
Vue.use(DashboardPlugin);
Vue.use(firestorePlugin);
Vue.mixin(Utilities.mixin());
Vue.component("qr-code-wrapper", QrcodeVue);
//Vue.use(VueNouislider);
Vue.config.productionTip = false;

// user authentification
// router.beforeEach((to, from, next) => {
//   const currentUser = firebase.auth().currentUser;
//   const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

//   if (requiresAuth && !currentUser) next('login');
//   else if (!requiresAuth && currentUser) next('home')
//   else next();
// });

// global library setup
Object.defineProperty(Vue.prototype, "$Chartist", {
  get() {
    return this.$root.Chartist;
  },
});

let app = "";
/* eslint-disable no-new */
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      el: "#app",
      router,
      data: {
        Chartist: Chartist,
      },
      render: (h) => h(App),
    }).$mount("#app");
  }
});
