<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <!-- Filter Section -->
      <md-card>
        <md-card-content>
          <label>Search by:</label>
          <ul
            style="
              display: inline-flex;
              list-style-type: none;
              margin-left: -35px;
            "
          >
            <li
              v-for="(r, i) in [
                { label: 'Order Number', value: 'on' },
                { label: 'PO #', value: 'po' },
                { label: 'SKU', value: 'sku' },
                { label: 'Cert #', value: 'cert' },
              ]"
              :key="i"
            >
              <input type="radio" v-model="selectedSearchBy" :value="r.value" />
              {{ r.label }}
            </li>
          </ul>
          <md-field class="filter column">
            <div class="search-options"></div>
            <md-input
              type="search"
              clearable
              placeholder="Search records here... Press enter to search"
              v-model="searchVal"
              @keypress="handleSearch($event)"
            ></md-input>
          </md-field>
          <md-button @click="handleSearch('click')" class="md-success text-btn"
            >Search</md-button
          >
          <md-button class="md-danger outline-btn" @click="clearFilters"
            >Clear
          </md-button>
        </md-card-content>
      </md-card>
      <md-card class="light">
        <md-card-content>
          <div class="filters">
            <!-- <div v-if="activeUser.isAdmin " class="filter customer">
                <p>Clients</p>
                <v-select
                  :appendToBody="true"
                  :deselectFromDropdown="true"
                  v-model="filterBy.client"
                  :options="clients"
                  label="name"
                  :clearable="false"
                  :reduce="(t) => t.name"
                  :closeOnSelect="true"
                >
                </v-select>
              </div> -->

            <!-- <div v-if="!activeUser.customerID" class="filter customer">
              <p>Venues</p>
              <v-select
                :appendToBody="true"
                :deselectFromDropdown="true"
                v-model="filterBy.customers"
                :options="customers"
                label="name"
                :clearable="false"
                :reduce="(t) => t.name"
                :closeOnSelect="true"
              >
              </v-select>
            </div> -->
            <div style="position: absolute; text-decoration: underline">
              Search by date
            </div>
            <div class="filter">
              <p>Start From:</p>
              <md-datepicker
                class="datepicker"
                @input="validateDateRange('startDate')"
                v-model="filterBy.startDate"
              />
            </div>
            <div class="filter">
              <p>End To:</p>
              <md-datepicker
                class="datepicker"
                @input="validateDateRange('endDate')"
                v-model="filterBy.endDate"
              />
            </div>

            <div
              class="filter filter-btn"
              style="width: 200px; bottom: 5px; position: absolute; right: 0"
            >
              <md-button class="md-success text-btn" @click="handleFilter"
                >Filter</md-button
              >
              <md-button class="md-danger outline-btn" @click="clearFilters"
                >Clear
              </md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>

      <!-- Data Table Section -->
      <md-card>
        <md-card-content>
          <div class="btn-row stats_holder">
            <md-button
              :class="
                showExportOptions ? 'md-accent' : 'md-primary md-round md-small'
              "
              @click="
                () => {
                  if (showExportOptions) {
                    showExportOptions = false;
                    showSelectableTable = false;
                    selectedExportData = [];
                  } else {
                    showExportOptions = true;
                  }
                }
              "
            >
              {{ showExportOptions ? "Cancel" : "Export" }}</md-button
            >
            <div v-if="showExportOptions">
              <md-button @click="exportCSV('all')" class="md-primary"
                >Export All Table Data</md-button
              >
              <md-button
                class="md-primary"
                @click="showSelectableTable = !showSelectableTable"
                >Export Selected Items</md-button
              >
            </div>
            <!-- <md-button
                  class="md-primary md-round md-small"
                  @click="promptFileUpload"
                  >Import</md-button
                >
                <input
                  style="display: none"
                  id="inputFile"
                  type="file"
                  @input="importCSV"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                /> -->
          </div>
          <div class="total-wrapper">
            Cert Cost Tally: ${{ totalCertPriceTally }}
          </div>
          <md-table
            v-if="!showSelectableTable"
            md-fixed-header
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            class="paginated-table table-striped table-hover"
            id="orders-table"
          >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Date/Time" md-sort-by="created">{{
                item.created | format_date_time
              }}</md-table-cell>
              <md-table-cell md-label="Client">{{
                item.Company.name
              }}</md-table-cell>
              <md-table-cell md-label="Venue" md-sort-by="Customer">{{
                item.Customer || "N/A"
              }}</md-table-cell>
              <md-table-cell md-label="Order #" md-sort-by="OrderNum">{{
                item.OrderNum
              }}</md-table-cell>
              <md-table-cell md-label="PO #" md-sort-by="OrderNum">{{
                item.PurchaseOrder || "N/A"
              }}</md-table-cell>
              <md-table-cell md-label="Item Type">
                <div class="item-sku-wrapper">
                  {{ item.JewelryType }}
                </div>
              </md-table-cell>
              <md-table-cell md-label="Certificate #" md-sort-by="Quantity">{{
                item.CertNum || "N/A"
              }}</md-table-cell>
              <md-table-cell md-label="Stock #" md-sort-by="Quantity">{{
                item.StockNum
              }}</md-table-cell>
              <md-table-cell md-label="SKU">
                <div class="item-sku-wrapper">
                  {{ item.ClientSKU }}
                </div>
              </md-table-cell>

              <!-- <md-table-cell
                  v-if="activeUser.isAdmin"
                  md-label="Client"
                  md-sort-by="Client"
                  >{{ item.Client }}</md-table-cell
                > -->

              <!-- <md-table-cell md-label="Qty" md-sort-by="Quantity">{{
                  item.Quantity
                }}</md-table-cell> -->
              <md-table-cell md-label="Retail Price" md-sort-by="RetailPrice">{{
                item.RetailPrice | format_price
              }}</md-table-cell>
              <md-table-cell
                md-label="Total Cert Cost"
                md-sort-by="TotalCertCost"
                >${{ item.TotalCertPrice }}</md-table-cell
              >

              <md-table-cell md-label="Printed">
                <div class="item-sku-wrapper">
                  <span v-if="item.CardCertPrinted" style="color: green"
                    >✔</span
                  >
                  <span v-else style="color: red">Pending</span>
                </div>
              </md-table-cell>
              <md-table-cell md-label="Shipped"
                ><span v-if="item.HasBeenShipped" style="color: green">✔</span>
                <span v-else style="color: red">{{
                  item.hasOwnProperty("HasBeenShipped") ? "Pending" : "N/A"
                }}</span>
              </md-table-cell>
              <md-table-cell md-label="" md-sort-by=""></md-table-cell>
              <!-- <md-table-cell
                  v-if="activeUser.isAdmin"
                  md-label="QA Associate"
                  md-sort-by=""
                >
                  <div class="qa-associate-wrapper">
                    {{ item.QA_Associate || "TBD" }}
                  </div>
                </md-table-cell> -->

              <!-- <md-table-cell md-label="Controls">
                  <md-button
                    v-if="activeUser.isAdmin"
                    class="md-just-icon md-warning md-simple"
                    @click.native="handleEdit(item)"
                  >
                    View
                  </md-button>
                  <md-button
                    v-if="item.Status === 'Completed'"
                    class="md-just-icon md-warning md-simple"
                    @click.native="showFailedItemReport(item)"
                  >
                    Report
                  </md-button>
                </md-table-cell> -->
            </md-table-row>
          </md-table>

          <SelectableTable
            v-else
            @onSelected="onSelected"
            :tableData="tableData"
            :tableInfo="exportItemsInfo"
          >
            <template v-slot:btns>
              <md-button
                v-if="selectedExportData.length"
                class="qc-button"
                @click="exportCSV('selected')"
              >
                Export
              </md-button>
            </template>
          </SelectableTable>
        </md-card-content>
        <md-divider></md-divider>
        <md-card-actions md-alignment="space-between">
          <div
            class="per-page"
            :style="{ marginBottom: '20px', marginTop: '20px' }"
          >
            <label for="orders">Per page</label>
            <div>
              <select
                style="border: none !important"
                v-model="pagination.perPage"
                name="orders"
              >
                <option
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                  {{ item }}
                </option>
              </select>
            </div>
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>

          <Pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          ></Pagination>
        </md-card-actions>
      </md-card>
    </div>

    <md-snackbar
      md-position="center"
      :md-duration="5000"
      :md-active.sync="showSnackbar"
      md-persistent
    >
      <span>{{ snackbarMsg }}</span>
    </md-snackbar>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";
import Pagination from "@/components/Pagination.vue";
import SelectableTable from "@/components/SelectableTable.vue";
//import MultImages from "@/components/Inputs/UploadMultiple.vue";
import { db } from "@/config/firebaseInit.js";

const { DateTime } = require("luxon");

export default {
  name: "Home",
  components: {
    Pagination,
    SelectableTable,
  },
  computed: {
    totalCertPriceTally() {
      let total = 0;
      total = this.tableData.reduce(
        (sum, item) => sum + Number(item.TotalCertPrice),
        0
      );

      return total.toFixed(2);
    },
    /***
     * Returns a page from the searched data or the whole data.
     */
    queriedData() {
      let result = this.tableData.sort((a, b) => b.created - a.created);
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.length;
    },
  },

  data() {
    return {
      activeUser: {},
      selectedItemType: "",
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        perPage: 250,
        currentPage: 1,
        perPageOptions: [250, 500, 1000],
        total: 0,
      },
      searchQuery: "",
      searchVal: "",
      selectedSearchBy: "on",
      allItemsForAuthentication: [],
      filterBy: {
        showFailedItems: false,
        showPassedItems: false,
        showPassedWithRepair: false,
        client: "",
        status: "",
        customers: [],
        startDate: "",
        endDate: "",
      },
      propsToSearch: ["title", "fullname", "description"],
      showModalContentForLineItem: false,
      showQAAssociateModal: false,
      showModalContentForItem: false,
      selectedQaAssociate: "",
      allQaAssociates: [],
      showImageModal: false,
      showSelectableTable: false,
      showSnackbar: false,
      statusForAllItems: null,
      showChangesBtn: false,
      allFailedItems: [],
      allItemsForSelectedLine: [],
      selectedLineItem: {},
      selectedItem: {},
      selectedFailedItem: {},
      showModalContent: false,
      showExportOptions: false,
      selectedExportData: [],
      searchAuthItems: null,
      popupTab: null,
      lineItemsInfo: [
        // {
        //   label: "Customer",
        //   key: "Customer",
        // },
        // {
        //   label: "Order #",
        //   key: "OrderNum",
        // },
        {
          label: "Line #",
          key: "LineNum",
        },
        {
          label: "Qty",
          key: "Quantity",
        },
        {
          label: "Description",
          key: "Description",
        },
      ],
      itemsInfo: [
        {
          label: "Customer",
          key: "Customer",
        },
        {
          label: "Order #",
          key: "OrderNum",
        },
        {
          label: "Line #",
          key: "LineNum",
        },
        {
          label: "Item #",
          key: "ItemNum",
        },

        {
          label: "Status",
          key: "Status",
        },
      ],
      itemsInfoForAuth: [
        {
          label: "Item #",
          key: "ItemNum",
        },
        {
          label: "Type",
          key: "Type",
        },
        {
          label: "Description",
          key: "Description",
        },
      ],
      exportItemsInfo: [
        //  {
        //   label: "Date/Time",
        //   key: "Timestamp",
        // },
        {
          label: "Order #",
          key: "OrderNum",
        },

        {
          label: "Client",
          key: "Company",
          subKey: "name",
        },
        {
          label: "Certificate",
          key: "CertNum",
        },
        {
          label: "SKU",
          key: "ClientSKU",
        },
        {
          label: "Retail Price",
          key: "RetailPrice",
        },
        {
          label: "Total Cert Cost",
          key: "TotalCertCost",
        },
      ],
      unfilteredItems: [],
      tableData: [],
      selectedOrderLineStatus: [],
      allLineItems: [],
      searchedData: [],
      selectedItems: [],
      customers: [],
      clients: [],
      showDialog: false,
      showQCModal: false,
      showQCModalForMult: false,
      showAuthModal: false,
      showOrderReportModal: false,
      showItemImagesModal: false,
      showNoteModal: false,
      allOrderItemsInfo: {},
      selectedOrder: {},
      leftQCPanelInit: false,
      snackbarMsg: "",
      // routeChange: true,
    };
  },

  firestore() {
    return {
      unfilteredItems: db
        .collection("jewelry")
        .where("Company.id", "==", "1iX1oea29dw1sMzmzMyz")
        .where("QAstatus", "==", "Passed")
        .where("CertNum", "!=", ""),
      // .where("TotalCertPrice", ">=", 0),

      // clients: db.collection("companies"),
    };
  },

  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    // searchQuery(value) {
    //   let result = this.tableData;
    //   if (value !== "") {
    //     result = this.fuseSearch.search(this.searchQuery);
    //   }
    //   this.searchedData = result;
    // },
    statusForAllItems(val) {
      if (val) {
        this.handleStatuses(val);
      }
    },
    unfilteredItems: {
      deep: true,
      handler(x) {
        if (x.length) {
          this.tableData = this.unfilteredItems.filter(
            (i) => i.QAstatus !== "Failed" && i.QAstatus !== "Skipped"
          );
        }
      },
    },
  },

  methods: {
    onSelected(data) {
      this.selectedExportData = data;
    },

    async exportCSV(action) {
      let format_date = (v) => moment(v).format("MM/DD/YYYY");
      let loader = this.$loading.show({
        container: null,
        canCancel: false,
      });
      const { selectedExportData, tableData } = this;
      let payload = action === "all" ? tableData : selectedExportData;
      let columns =
        "Date,Venue,PO,Order,Item Type,Certificate,SKU,Retail Price,Total Cert Price\n";
      let rows = payload
        .map((d) => {
          return `${format_date(d.created)},${
            d.Customer ? d.Customer.replace(",", " ") : ""
          },${d.PurchaseOrder},${d.OrderNum}-${d.LineNum},${d.JewelryType},${
            d.CertNum
          },${d.ClientSKU},$${d.RetailPrice},$${d.TotalCertPrice}`;
        })
        .join("\r\n");

      console.log(rows);
      let csvContent = "data:text/csv;charset=utf-8," + columns + rows;
      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute(
        "download",
        `Certificate Cost Report ${moment().format("LL")}.csv`
      );
      document.body.appendChild(link); // Required for FF
      link.click();

      this.showExportOptions = false;
      this.showSelectableTable = false;
      this.selectedExportData = [];
      loader.hide();
    },

    goBack(cur, prev) {
      this[cur] = false;
      this[prev] = true;
    },
    clearFilters() {
      for (const key in this.filterBy) {
        if (key === "client" || key === "status") {
          this.filterBy[key] = "";
        } else if (key === "startDate" || key === "endDate") {
          this.filterBy[key] = null;
          // this.filterBy[key] = "";
        } else if (
          key === "showFailedItems" ||
          key === "showPassedWithRepair" ||
          key === "showPassedItems"
        ) {
          this.filterBy[key] = false;
        } else {
          this.filterBy[key] = [];
        }
      }
      this.searchVal = null;
      this.tableData = this.unfilteredItems;
    },
    async handleSearch(e) {
      if (e.keyCode == 13 || e == "click") {
        const { selectedSearchBy, searchVal } = this;

        if (!searchVal) this.clearFilters();

        let colRef;

        if (selectedSearchBy === "cert") {
          //on stands for order number
          colRef = db.doc(`jewelry/${searchVal}`);
        } else if (selectedSearchBy === "sku") {
          colRef = db.collection("jewelry").where("SKU", "==", searchVal);
        } else if (selectedSearchBy === "on") {
          colRef = db.collection("jewelry").where("OrderNum", "==", searchVal);
        } else if (selectedSearchBy === "po") {
          colRef = db
            .collection("jewelry")
            .where("PurchaseOrder", "==", searchVal);
        }

        let docs = await colRef.get();
        let data = [];
        if (docs.size) {
          data = docs.docs
            .map((doc) => Object.assign({ id: doc.id }, doc.data()))
            .filter((i) => i.QAstatus !== "Failed" && i.QAstatus !== "Skipped");
        } else if (docs.exists) {
          docs = Object.assign({ id: docs.id }, docs.data());
          if (docs.QAstatus !== "Failed" && docs.QAstatus !== "Skipped") {
            data.push(docs);
          }
        }

        if (data.length) {
          this.tableData = data;
        } else {
          Swal.fire("", "No Records Has Been Found", "error");
        }
      }
    },
    async handleFilter() {
      let loader = this.$loading.show({
        container: null,
        canCancel: false,
      });
      let ref;
      ref = db
        .collection("jewelry")
        .where("Company.id", "==", "1iX1oea29dw1sMzmzMyz");

      let filteredItems = this.unfilteredItems;
      if (
        // !this.filterBy.showFailedItems &&
        // !this.filterBy.showPassedWithRepair &&
        // !this.filterBy.showPassedItems &&
        // !this.filterBy.customers.length &&
        // !this.filterBy.client &&
        // !this.filterBy.status &&
        !this.filterBy.startDate &&
        !this.filterBy.endDate
      ) {
        this.tableData = filteredItems;
        loader.hide();
        return false;
      }
      if (this.filterBy.startDate && this.filterBy.endDate) {
        let startDateObj =
          typeof this.filterBy.startDate == "object"
            ? DateTime.fromJSDate(this.filterBy.startDate)
            : DateTime.fromISO(this.filterBy.startDate);

        let endDateObj =
          typeof this.filterBy.endDate == "object"
            ? DateTime.fromJSDate(this.filterBy.endDate)
            : DateTime.fromISO(this.filterBy.endDate);

        let startDate = startDateObj
          .setZone("America/New_York")
          .startOf("day")
          .toMillis();

        let endDate = endDateObj
          .setZone("America/New_York")
          .endOf("day")
          .toMillis();

        ref = ref.orderBy("created").startAt(startDate).endAt(endDate);
        let container = await ref.get();
        filteredItems = container.docs
          .filter((d) => d.data().QAstatus == "Passed" && d.data().CertNum)
          .map((d) => Object.assign({ id: d.id }, d.data()))
          .sort((a, b) => b.created - a.created);
        this.tableData = filteredItems;
      }

      loader.hide();
    },
    validateDateRange(key) {
      if (moment(this.filterBy.startDate).isAfter(this.filterBy.endDate)) {
        this.filterBy.endDate = null;
      }

      if (key === "startDate") {
        this.filterBy.endDate = this.filterBy.startDate;
      }
    },
  },
};
</script>
<style scoped>
.total-wrapper {
  font-size: 18px;
  font-weight: bold;
  color: green;
  width: 240px;
  max-width: 400px;
}
.filter-btn {
  flex-direction: row;
}

.search-options {
  width: 300px;
  position: absolute;
  top: -11px;
  left: 50px;
}
</style>
