<template>
  <div class="diamonds" :class="{printing: printing}">
    <div class="printBtn btn" v-if="printLoader && !printing" @click="startPrint" ><p>Print</p></div>
    <div class="pamphlet">
      <div class="header">
        <div ref="loader"></div>
      </div>
      <div class="pamphlet-holder" id="pamphlet-holder" :class="{printing: printing}">
        <canvas class="canvas" id="canvas" width="1344" height="816"></canvas>
          <div v-if='!printing' class="certificate-background">
            <img src="https://firebasestorage.googleapis.com/v0/b/diamonds-8cf72.appspot.com/o/report%20(1).png?alt=media&token=8f8b2c64-eb41-411c-b181-9a5f7fa21105" alt="certificate">
          </div>
          <div width="1344" height="816" ref="canvasimg" v-if="imperfection" class="sections" id="sections" :class="{printing: printing}">
            <div class='section1'>
              <p class="date">{{ diamond.created | moment("MM/D/YYYY") }}</p>
              <p class='title'>{{diamond.lab_grown == "Natural Stone" ? 'NATURAL DIAMOND GRADING' : 'Lab Grown diamond grading'}}</p>
              <div class="list">
                <p>DIS Report Number</p>
                <p>{{ diamond.ManufacturedAs === "Lab Grown" ? `LG${diamond.CertNum}`: diamond.CertNum }}</p>
              </div>
              <div class="list">
                <p>Shape and Cutting Style</p>
                <p>{{ diamond.Shape }} </p>
              </div>
              <div class="list">
                <p>Measurement</p>
                <p>{{ diamond.Measurements }} </p>
              </div>
              <div class='space'></div>
              <p class='title'>Grading Results</p>
              <div class="list">
                <p>Carat Weight</p>
                <p>{{ diamond.Weight.toFixed(2) }} CARAT</p>
              </div>
              
              <div class="list">
                <p>Color Grade</p>
                <p>{{ diamond.Color.value }} {{diamond.Color.asterisks}} {{getDiamondColor()}}</p> 
              </div>
              <div class="list">
                <p>Clarity Grade</p>
                <p>{{ diamond.Clarity.value}} {{diamond.Clarity.asterisks}}</p>
              </div>
              <div class="list" v-if="diamond.Cut && diamond.Shape.toLowerCase().includes('round')">
                <p>Cut Grade</p>
                <p>{{ diamond.Cut ||  'N/A' }} </p>
              </div>

              <div class='grading_info'>
                <div class="list">
                  <p>Polish</p>
                  <p>{{ diamond.Polish }}</p>
                </div>
                <div class="list">
                  <p>Symmetry</p>
                  <p>{{ diamond.Symmetry }}</p>
                </div>
                <div class="list">
                  <p>Fluorescence</p>
                  <p>{{ diamond.Fluorescence }}</p>
                </div>
                <div class="list" v-if='diamond.Inscription'>
                  <!-- <p>Inscription(s) DIS {{diamond.lab_grown == "Natural Stone" ? 'NATURAL DIAMOND GRADING' : 'Lab Grown diamond'}} {{ diamond.CertNum }}</p> -->
                  <p>Inscription(s) </p>
                  <p> {{ diamond.Inscription ? diamond.Inscription : 'N/A' }}</p>
                </div>
                <div class="list comments">
                  <p>Comments:</p>
                  <p v-if="!diamond.CertificateComments.includes('<p>')"> {{ diamond.CertificateComments }} </p>
                  <p v-else v-html='diamond.CertificateComments'> </p>
                </div>
              </div>
            </div>

            <div class='section2'>
              <p class='CertNum'>{{ diamond.CertNum }}</p>
              <p class='website_text'>Verify DIS Certificate at www.diamondservicesusa.com</p>
              <p class='title'>Proportions</p>
              <div class='proportions-holder'>
                <div class='proportions-image-data'>
                  <div class='girdle'>{{diamond.Girdle || diamond.Girdle != '' ? diamond.Girdle : 'N/A'}}</div>
                  <div class='depth'>{{diamond.Depth || diamond.Depth != '' ? diamond.Depth + '%' : 'N/A'}}</div>
                  <div class='table'>{{diamond.Table || diamond.Table != '' ? diamond.Table + '%' : 'N/A'}}</div>
                </div>
                <img ref='proportion_url' class="proportions_image" src="@/assets/img/proportions-image.jpg" alt="Proportions Image">
              </div>
              <div class="imperfection_img">
                  <img
                  class='img'
                  :src="imperfection.url"
                  alt="Imperfection Template"
                  ref="characteristics_url"
                  />
                  <div class="imperfections_key_holder">
                    <p class="imperfection_chosen_text section_two_section_head">
                    Key to Symbols
                    </p>
                    <div v-if="!imperfection.imperfectionAddedList" class="key_info_holder">
                      <template v-if='imperfection.display_imperfection_icons'>
                        <div 
                          v-if="!diamond.add_imperfection_icons"
                          class="imperfections_key"
                          v-for="(imperfection, index) in imperfection.imperfections"
                          :key="index"
                          >
                          <img
                          ref='imperfection_url'
                              class="imperfection_img_icon"
                              :src="
                              require('@/assets/img/imperfections/' + imperfection.image)
                              "
                              alt="imperfection"
                          />
                          <p class="imperfections_key_text">{{ imperfection.name }}</p>
                        </div>
                      </template>
                      <div v-if="!imperfection.display_imperfection_icons" class="imperfections_key_noicon">
                        <p>
                          Red Symbols indicate internal charecteristics.</br>
                          Green symbols indicate external characteristics
                        </p>
                      </div>
                    </div>
                    <div v-if="imperfection.imperfectionAddedList" class="key_info_holder">
                      <template v-if='imperfection.display_imperfection_icons'>
                      <div 
                        v-if="!diamond.add_imperfection_icon"
                        class="imperfections_key"
                        v-for="(imperfection, index) in imperfection.imperfectionAddedList"
                        :key="index"
                        >
                        <img
                        ref='imperfection_url'
                            class="imperfection_img_icon"
                            :src="
                            require('@/assets/img/imperfections/' + imperfection.image)
                            "
                            alt="imperfection"
                        />
                        <p class="imperfections_key_text">{{ imperfection.name }}</p>
                      </div>
                      </template>
                      <div v-if="!imperfection.display_imperfection_icons" class="imperfections_key_noicon">
                        <p>
                          Red Symbols indicate internal charecteristics. </br>
                          Green symbols indicate external characteristics
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
            </div>

            <vue-qr
            v-if='diamond.certificate_video'
              class='qr_code'
              :size="110"
              :text="diamond.certificate_video.url">
            </vue-qr>

            <div class='section4'>
              <p class="list date section_four_date">{{ diamond.created | moment("MM/D/YYYY") }}</p>
              <p class='title'>{{diamond.lab_grown == "Natural Stone" ? 'NATURAL DIAMOND GRADING' : 'Lab Grown diamond grading'}} </p>
              <div class="list">
                <p>DIS Report Number</p>
                <p>{{ diamond.CertNum }}</p>
              </div>
              <div class="list">
                <p>Shape</p>
                <p>{{ diamond.Shape }} </p>
              </div>
              <div class="list">
                <p>Measurements</p>
                <p>{{ diamond.Measurements }} </p>
              </div>

              <div class='space'></div>
              <div class="list ">
                <p>Carat Weight</p>
                <p>{{ diamond.Weight.toFixed(2) }} CARAT</p>
              </div>
              
              <div class="list">
                <p>Color Grade</p>
                <p>{{ diamond.Color.value }} {{diamond.Color.asterisks}} {{getDiamondColor()}}</p> 
              </div>
              <div class="list">
                <p>Clarity Grade</p>
                <p>{{ diamond.Clarity.value}} {{diamond.Clarity.asterisks}}</p>
              </div>
              <div class="list" v-if="diamond.Cut && diamond.Shape.toLowerCase().includes('round')">
                <p>Cut Grade</p>
                <p>{{ diamond.Cut ||  'N/A' }} </p>
              </div>
              
              <div class='space'></div>
              <p class='title'>Proportions</p>
              <div class='proportions-holder right-side'>
                <div class='proportions-image-data'>
                  <div class='girdle'>{{diamond.Girdle || diamond.Girdle != '' ? diamond.Girdle : 'N/A'}}</div>
                  <div class='depth'>{{diamond.Depth || diamond.Depth != '' ? diamond.Depth + '%' : 'N/A'}}</div>
                  <div class='table'>{{diamond.Table || diamond.Table != '' ? diamond.Table + '%' : 'N/A'}}</div>
                </div>
                <img ref='proportion_url' class="proportions_image" src="@/assets/img/proportions-image.jpg" alt="Proportions Image">
              </div>
              <div class='space'></div>
              <p class='title'>GRADING RESULTS</p>
              <div class="list ">
                <p>Polish</p>
                <p>{{ diamond.Polish }}</p>
              </div>
              <div class="list">
                <p>Symmetry</p>
                <p>{{ diamond.Symmetry }}</p>
              </div>
              <div class="list">
                <p>Fluorescence</p>
                <p>{{ diamond.Fluorescence }}</p>
              </div>

              <div class="list inscription" v-if='diamond.Inscription'>
                <p>Inscription(s)</p>
                <p>  {{ diamond.Inscription ? diamond.Inscription : 'N/A' }} </p>
              </div>
              <div class="list comments">
                <p class='comment-title'>Comments</p>
                <p class="text_import blue" v-if="!diamond.CertificateComments.includes('<p>')"> {{ diamond.CertificateComments }} </p>
                <p class="text_import blue" v-else v-html='diamond.CertificateComments'> </p>
              </div>

          </div>

          <div class="section_four_rotated">
                <p class="date">{{ diamond.created | moment("MM/D/YYYY") }}</p>
              
                <div class="list">
                  <p>DIS Report No</p>
                  <p>{{ diamond.CertNum }}</p>
                </div>
                <div class="list full-lines">
                  <p>{{diamond.Shape}}</p>
                  <p>{{ diamond.Measurements }}</p>
                </div>
                <div class="list">
                  <p>Carat Weight</p>
                  <p>{{ diamond.Weight.toFixed(2) }} CARAT</p>
                </div>
                <div class="list">
                  <p>Color Grade</p>
                  <p>{{ diamond.Color.value }} {{diamond.Color.asterisks}} {{getDiamondColor()}}</p> 
                </div>
                <div class="list">
                  <p>Clarity Grade</p>
                  <p>{{ diamond.Clarity.value}} {{diamond.Clarity.asterisks}}</p>
                </div>
                <div class="list" v-if="diamond.Cut && diamond.Shape.toLowerCase().includes('round')">
                <p>Cut Grade</p>
                <p>{{ diamond.Cut ||  'N/A' }} </p>
              </div>
                <div class="list" v-if='diamond.Depth'>
                  <p>Depth</p>
                  <p>{{ diamond.Depth ? diamond.Depth : 'N/A' }}</p>
                </div>
                <div class="list" v-if='diamond.Table'>
                  <p>Table</p>
                  <p>{{ diamond.Table ?  diamond.Table: 'N/A' }}</p>
                </div>
                <div class="list" v-if='diamond.Girdle'>
                  <p>Girdle</p>
                  <p>{{ diamond.Girdle ? diamond.Girdle : 'N/A' }}</p>
                </div>
                <!-- <div class="list" v-if='diamond.culet'>
                  <p>Culet</p>
                  <p>{{ diamond.culet ? diamond.culet : 'N/A' }}</p>
                </div> -->
                <div class="list">
                  <p>Polish</p>
                  <p>{{ diamond.Polish }}</p>
                </div>
                <div class="list">
                  <p>Symmetry</p>
                  <p>{{ diamond.Symmetry }}</p>
                </div>
                <div class="list">
                  <p>Fluorescence</p>
                  <p>{{ diamond.Fluorescence }}</p>
                </div>

                <div class="list" v-if='diamond.Inscription'>
                  <p>Inscription(s)</p>
                  <p>  {{ diamond.Inscription ? diamond.Inscription  : 'N/A' }} </p>
                </div>
                <div class="list rotated_comments">
                  <p class='comment-title'>Comments</p>
                  <p class="text_import blue" v-if="!diamond.CertificateComments.includes('<p>')"> {{ diamond.CertificateComments }} </p>
                  <p class="text_import blue" v-else v-html='diamond.CertificateComments'> </p>
                </div>
              </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db, storage, functions, auth } from "@/config/firebaseInit";
import Swal from "sweetalert2";
import Vue from "vue";
import VueHtml2Canvas from "vue-html2canvas";
import VueHtmlToPaper from "vue-html-to-paper";
import printJS from "print-js";
import firebase from "firebase";
import VueQr from "vue-qr";


const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
  ],
};

Vue.use(VueHtmlToPaper, options);
Vue.use(VueHtml2Canvas);
Vue.use(require("vue-moment"));
export default {
  name: "home",
  components: {VueQr},

  data() {
    return {
      printing: null,
      ratio: null,
      printLoader: null,
      generated: false,
      pamphlet_diamond: null,
      canvas: null,
      diamond: null,
      imperfection: null,
      savedDiamonds: [],
      connect_clicked: false,
      connect_diamond: null,
      tableData: [],
      getAllDiamonds: functions.httpsCallable("getAllDiamonds"),
      saveImperfectionsToDiamond: functions.httpsCallable(
        "saveImperfectionsToDiamond"
      ),
      saveCertificateThroughDiamondsAPI: functions.httpsCallable(
        "saveCertificateThroughDiamondsAPI"
      ),
      colors: {
        Standard: {
          key: "",
          name: "",
          value: [
            "Z",
            "Y",
            "X",
            "W",
            "V",
            "U",
            "T",
            "S",
            "R",
            "Q",
            "P",
            "O",
            "N",
            "M",
            "L",
            "K",
            "J",
            "I",
            "H",
            "G",
            "F",
            "E",
            "D",
          ],
        },
        Blue: {
          name: "Blue",
          value: [
            "Light",
            "Fancy Light",
            "Fancy",
            "Fancy Intense",
            "Fancy Vivid",
          ],
          key: "_B_",
        },
        Brown: {
          name: "Brown",
          value: ["C1", "C2", "C3", "C4", "C5", "C6", "C7", "C8"],
          key: "_B_",
        },
        Green: {
          name: "Green",
          value: [
            "Faint",
            "Very Light",
            "Light",
            "Fancy Light",
            "Fancy",
            "Fancy Intense",
            "Fancy Vivid",
            "Fancy Deep",
          ],
          key: "_G_",
        },
        Pink: {
          name: "Pink",
          value: [
            "Faint",
            "Very Light",
            "Light",
            "Fancy Light",
            "Fancy",
            "Fancy Intense",
            "Fancy Vivid",
            "Fancy Deep",
          ],
          key: "_P_",
        },
        Yellow: {
          name: "Yellow",
          value: ["Fancy Light", "Fancy", "Intense", "Vivid", "Deep"],
          key: "_Y_",
        },
        Black: {
          name: "Black",
          value: ["A", "AA", "AAA", "AAAA"],
          key: "_B_",
        },
        Red: {
          name: "Red",
          value: [
            "Faint",
            "Fancy",
            "Fancy Dark",
            "Fancy Intense",
            "Fancy Light",
            "Fancy Vivid",
            "Light",
            "Very Light",
          ],
          key: "_R_",
        },
      }
    };
  },
  async created() {
    let loader = this.$loading.show({
      container: this.$refs.loader,
      canCancel: false,
    });

    this.pamphlet_diamond = '1633973684740' 
    let query = await db
      .collection("diamond")
      .where("imperfection.id", "==", this.pamphlet_diamond)
      .get();
    if (!query.size) {
      loader.hide();
      return Swal.fire(
        "",
        "There's an error retrieving data. Please try again",
        "error"
      ).then(() => {
        this.$router.go(-1);
      });
    }

    this.diamond = Object.assign(
      { id: query.docs[0].id },
      query.docs[0].data()
    );
    let imperfection_doc = await db
      .collection("diamond_imperfections")
      .doc(this.diamond.imperfection.id)
      .get();

    if (imperfection_doc.exists) {
      this.imperfection = Object.assign(
        { id: imperfection_doc.id },
        imperfection_doc.data()
      );
      loader.hide();
    } else {
      loader.hide();
      return Swal.fire("", "No Imperfections ", "error").then(() => {
        this.$router.go(-1);
      });
    }
    this.calcResizeRatio(); 
  },
  watch: {
    imperfection() {
      this.$nextTick(() => {
        if (this.imperfection && !this.generated) {
          this.generated = true;
          this.convertImages();
        }
      });
    },
  },
  methods: {
     getDiamondColor(){
      let color = ''
      if (this.diamond.Color.template) {
       if (this.colors[this.diamond.Color.template].value.includes(this.diamond.Color.value)){
            color = this.colors[this.diamond.Color.template].name
          }
      }
      return color
    },
    calcResizeRatio() {
      this.$nextTick(() => {
        let windowHeight = window.innerHeight;
        let windowWidth = window.innerWidth;
        let heightRatio = windowHeight / 3000;
        let widthRatio = windowWidth / 4500;

        if (heightRatio < widthRatio) {
          this.ratio = heightRatio;
        } else this.ratio = widthRatio;
      });
    },
    async convertImages() {
      let loader = this.$loading.show({
        container: this.$refs.loader,
        canCancel: false,
      });

      //Using refs to fetch data url
      let imperfection_img = this.imperfection.url;
      let porportion_img = await this.getImage(
        this.imperfection.proportions_ref
      );

      //Converting download url to blobs
      let imperfection_blob = await this.convertImageToBlob(imperfection_img);
      let proportion_blob = await this.convertImageToBlob(porportion_img);

      //Converting Blobs to DataURL
      let imperfection_url = await this.blobToDataURL(imperfection_blob);
      let proportion_url = await this.blobToDataURL(proportion_blob);

      // this.$refs.characteristics_url.src = imperfection_url;
      // this.$refs.proportion_url.src = proportion_url;  

      this.printLoader = loader;
      // this.print(); 
      loader.hide();
    },
    startPrint(){
      this.printing = true;
      // let loader = this.$loading.show({
      //   container: this.$refs.loader,
      //   canCancel: false,
      // });
      // this.print(loader);
      setTimeout(() => {
        window.print()
      }, 300);

      const decrement = firebase.firestore.FieldValue.increment(-1);
      const increment = firebase.firestore.FieldValue.increment(1);

      let created = new Date().toLocaleString();  
        db.collection("diamond_imperfections")
          .doc(this.imperfection.id)
          .update({ printed: true, print_date: created });  
        db.collection("diamond_imperfection_total")
          .doc(auth.currentUser.uid)
          .update({ drafts: decrement, completed: increment } ); 
      this.saveCertificateThroughDiamondsAPI({
        diamond_id: this.diamond.id,
        CertNum: this.diamond.CertNum,
        certificate_url: downloadURL.url,
      })
        .then((res) => {
          loader.hide();
          return printJS({
            printable: img,
            type: "image",
            showModal: true,
            onPrintDialogClose: this.closedWindow,
          });
        })
        .catch((error) => {
          loader.hide();
          return printJS({
            printable: img,
            type: "image",
            showModal: true,
            onPrintDialogClose: this.closedWindow,
          });
        });
    },
    getImage(ref) {
      return storage
        .refFromURL("gs://diamonds-8cf72.appspot.com/" + ref)
        .getDownloadURL()
        .then(function (url) {
          return url;
        })
        .catch(function (error) {
          alert("Could not load your design. Please reload and try again.");
        });
    },
    async convertImageToBlob(img) { 
      return fetch(img).then((url) => url.blob());
    },
    blobToDataURL(blob, obj) {
      let vm = this;
      return new Promise((fulfill, reject) => {
        let reader = new FileReader();
        reader.onerror = reject;
        reader.onload = (e) => fulfill(reader.result);
        reader.readAsDataURL(blob);
      })
        .then((logo) => {
          return logo;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async dataURLToDownloadUrl(DataURL) {
      var storage = firebase.storage();
      var storageRef = storage.ref("diamond_imperfections/" + Date.now());
      let ref = await storageRef
        .putString(DataURL, "data_url")
        .then(function (snapshot) {
          return snapshot.ref.getDownloadURL().then((downloadURL) => {
            return { ref: snapshot.metadata.fullPath, url: downloadURL };
          });
        });
      return ref;
    },
    async print(loader) {
      const decrement = firebase.firestore.FieldValue.increment(-1);
      const increment = firebase.firestore.FieldValue.increment(1);
      var dataURL = await this.convertToDataURL();
      let img = dataURL.toDataURL("image/jpeg", 0.1);
      let downloadURL = await this.dataURLToDownloadUrl(img);
      return printJS({
            printable: img,
            type: "image",
            showModal: true,
            // onPrintDialogClose: this.closedWindow,
          });
      // let created = new Date().toLocaleString();  
      //   db.collection("diamond_imperfections")
      //     .doc(this.imperfection.id)
      //     .update({ printed: true, print_date: created });  
      //   db.collection("diamond_imperfection_total")
      //     .doc(auth.currentUser.uid)
      //     .update({ drafts: decrement, completed: increment } ); 
      // this.saveCertificateThroughDiamondsAPI({
      //   diamond_id: this.diamond.id,
      //   CertNum: this.diamond.CertNum,
      //   certificate_url: downloadURL.url,
      // })
      //   .then((res) => {
      //     loader.hide();
      //     return printJS({
      //       printable: img,
      //       type: "image",
      //       showModal: true,
      //       onPrintDialogClose: this.closedWindow,
      //     });
      //   })
      //   .catch((error) => {
      //     loader.hide();
      //     return printJS({
      //       printable: img,
      //       type: "image",
      //       showModal: true,
      //       onPrintDialogClose: this.closedWindow,
      //     });
      //   });
    },
    convertToDataURL() {
      const el = this.$refs.canvasimg;
      const options = {
        scrollY: -window.scrollY,
      };
      return this.$html2canvas(el, options);
    },
    closedWindow() {
      this.$store.dispatch("clearPamphlet").then(() => {
        this.$router.go(-1);
      });
    },
    getDiamonds() {
      let vm = this;
      db.collection("diamond_imperfections")
        .get()
        .then(function (querySnapshot) {
          vm.savedDiamonds = [];
          querySnapshot.forEach(function (doc) {
            vm.savedDiamonds.push({
              name: doc.data().name,
              diamond_cut: doc.data().diamond_cut,
              image_ref: doc.data().image_ref,
              imperfections: doc.data().imperfections,
              id: doc.id,
              //   url: doc.data().url,
            });
          });
        });
    },
    viewImage(diamond) {
      let ref = storage.ref(diamond.image_ref);
      ref
        .getDownloadURL()
        .then(function (url) {
          window.open(url, "_blank");
        })
        .catch(function (error) {
          alert(error.code);
        });
    },
    connect(diamond) {
      this.getAllDiamonds(diamond.diamond_cut.name)
        .then((res) => {
          this.tableData = res.data;
          this.connect_clicked = diamond;
        })
        .catch((err) => {
          return Swal.fire("", err.message + " Try again later", "error");
        });
    },
    selectDiamond(diamond, imperfection) {
      let loader = this.$loading.show({
        container: null,
        canCancel: false,
      });

      this.saveImperfectionsToDiamond({
        id: diamond,
        imperfection: imperfection,
      }).then(() => {
        this.connect_clicked = false;
        this.connect_diamond = null;
        loader.hide();
        return Swal.fire("", "Diamond Imperfections Has Been Added", "success");
      });
    },
    remove(diamond) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to undo this",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "DELETE",
        cancelButtonText: "CANCEL",
      }).then((result) => {
        if (result.value) {
          return db
            .collection("diamond_imperfections")
            .doc(diamond.id)
            .delete()
            .then(() => {
              this.getDiamonds();
              Swal.fire(
                "Deleted!",
                `This Diamond has been deleted.`,
                "success"
              );
            });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@page {
  size: landscape;
  margin: 10%;
}

.diamonds.printing {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  padding-top: 0;
  z-index: 999999;
  background-color: white;
}

#pamphlet-holder {
  width:1344px;
  margin: auto;
  margin-top: 75px;
  position: relative;
  box-sizing: border-box;
  &.printing {
    margin-top: 0;
  }
}

.certificate-background {
  background: white;
  width: 100%;
  img {
    width: 100%;
  }
}

canvas {
  position: fixed;
  z-index: -2;
}

.printBtn {
  width: 135px;
  text-align: center;
  padding: 5px;
  position: absolute;
  z-index: 11;
  left: 50%;
  margin-top: 10px;
  
  p {
    font-size: 20px;
    margin-top: 0px;
    font-family: 'Open Sans';
    margin-bottom: 0px;

  }
}

.sections {
  position: absolute;
  top: 0;
  left: 0;
  width: 1344px;
  min-height: 500px;
  .title {
    font-weight: bold;
    font-size: 13px;
    text-transform: uppercase;
    margin-top: 13px;
    margin-bottom: 6px;
  }
  .list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
    p {
      margin: 0;
      font-size: 12px;
    }
    p:nth-child(1) {
      font-weight: normal;
      margin-right: 10px;
    }
    p:nth-child(2) {
      font-weight: bold;
      text-align: right;
      & /deep/ p {
        margin: 0;
        text-align: left !important;
      }
    }
  }
  .comments {
    flex-direction: column;
    font-weight: unset;
    align-items: flex-start;
    margin-top: 20px;
    line-height: 1.2;
    p {
      max-width: 100% !important;
    }
    & /deep/ p {
      margin: 0;
      text-align: left !important;
    }
  }
  .section1 {
    position: absolute;
    top: 155px;
    left: 34px;
    width: 276px;
    .date {
      margin: 0;
      font-size: 13px;
      margin-top: 20px;
    }
    .grading_info {
      position: absolute;
      top: 362px;
      left: 0;
      width: 100%;
    }
    .list {
      p:nth-child(2) {
        font-size: 11px;
        max-width: 130px;
      }
    }
  }
  .section2 {
    position: absolute;
    top: 155px;
    left: 385px;
    width: 283px;
    .CertNum {
      position: absolute;
      top: -102px;
      font-size: 12px;
      text-align: center;
      left: 40px;
      width: 200px;
    }
    .website_text {
      width: 200px;
      position: absolute;
      top: -80px;
      font-size: 12px;
      text-align: center;
      left: 40px;
      opacity: .5;
    }
    .title {
      margin-top: 25px;
    }
    .proportions_image {
      width: 100%;
      max-width: 582px;
      max-height: 550px;
      height: auto;
      margin-bottom: 0px;
      display: flex;
      margin: auto;
      margin-top: 10px;
      background:white;
      max-height: 261px;
      left: 2px;
      position: relative;
    }
    .imperfection_img {
      position: absolute;
      width: 100%;
      top: 362px;
      .img {
        // background: #2b2b2b;
        width: 100%;
        z-index: 2;
        position: relative;
        left: 2px;
      }
      .img_background {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
        filter: none;
      }
      .imperfections_key_holder {
        margin-left: 0;
        padding-left: 15px;
      }
      .imperfection_chosen_text {
        margin-left: 0;
        font-size: 13px;
        width: 100%;
        margin-top: 0;
        margin-bottom: 5px;
      }
      .key_info_holder {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        .imperfections_key {
          margin: 0;
          width: auto;
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          margin-right: 10px;
          margin-bottom: 5px;
          p {
            font-size: 10px;
            margin: 0;
          }
          img {
            height: 20px;
            margin-left: 3px;
          }
        }
      }
    }
  }
  .qr_code {
    position: absolute;
    top: 390px;
    left: 829px;
  }

  .section4 {
    position: absolute;
    top: 100;
    right: 32px;
    width: 236px;
    height: 630px;
    .list {
      margin-bottom: 1px;
      p {
        font-size: 11px !important;
      }
    }
    .date {
      position: relative;
      font-size: 14px;
      margin-top: 20px;
    }
    .imperfections_key_holder {
      margin-left: 0;
    }
  }
  .section_four_rotated {
transform: rotate(
-90deg
);
    width: 72px;
    position: absolute;
    right: 110px;
    bottom: -380px;
    height: 250px;
      .imperfections_key_holder {
        margin-left: 0;
      }
      .date {
        font-size: 6px;
        margin: 0;
        margin-bottom: 5px;
      }
      .list {
        margin-bottom: 2.5px;
        p {
          font-size: 5px;
          font-weight: normal !important;
          &:nth-child(2) {
            font-weight: bold !important;
          }
        }
        &.full-lines {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      .rotated_comments {
        display: flex;
        flex-direction: column;
      }
    }
}

.imperfections_key_noicon {
  margin-left: 0;
  padding-top: 0;
  p {
    font-size: 11px;
    margin-top: 0;
  }
}

.proportions-holder {
  position: relative;
  .proportions-image-data {
    position: absolute;
    z-index: 2;
    width: 100%;
    .girdle {
      font-size: 7px;
      top: 152px;
      position: absolute;
      left: 58px;
      max-width: 77px;
      font-weight: normal;
    }
    .depth {
      font-size: 7px;
      top: 104px;
      position: absolute;
      left: 247px;
      font-weight: normal;
    }
    .table {
      font-size: 7px;
      top: 51px;
      position: absolute;
      left: 51%;
      transform: translateX(-50%);
      font-weight: normal;
    }
  }
}


.proportions-holder.right-side {
  position: relative;
  img{ width: 100%; }
  .proportions-image-data {
    position: absolute;
    z-index: 2;
    width: 100%;
    .girdle {
      font-size: 6px;
      top: 127px;
      left: 47px;
    }
    .depth {
      top: 86px;
      left: 205px;
    }
    .table {
      font-size: 6px;
      top: 42px;
      left: 50.5%;
    }
  }
}
</style>
