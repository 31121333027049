<template>
  <div class="content">
    <div class="md-layout">
      <!-- <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
      >
        <chart-card
          header-animation="false"
          :chart-data="clickedAdsChart.data"
          :chart-options="clickedAdsChart.options"
          :chart-responsive-options="clickedAdsChart.responsiveOptions"
          chart-type="Bar"
          chart-inside-header
          background-color="blue"
        >
          <template slot="content">
            <h4 class="title">Advertisement Clicks</h4>
            <p class="category">Last Campaign Performance</p>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>access_time</md-icon>
              updated 10 days ago
            </div>
          </template>
        </chart-card>
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
      >
        <chart-card
          :chart-data="dataCompletedTasksChart.data"
          :chart-options="dataCompletedTasksChart.options"
          :chart-type="'Line'"
          header-animation="false"
          chart-inside-header
          background-color="green"
        >
          <template slot="content">
            <h4 class="title">Completed Tasks</h4>
            <p class="category">Last Campaign Performance</p>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>access_time</md-icon>
              campaign sent 26 minutes ago
            </div>
          </template>
        </chart-card>
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
      >
        <chart-card
          header-animation="false"
          :chart-data="clickedAdsChart.data"
          :chart-options="clickedAdsChart.options"
          :chart-responsive-options="clickedAdsChart.responsiveOptions"
          chart-type="Bar"
          chart-inside-header
          background-color="blue"
        >
          <template slot="content">
            <h4 class="title">Advertisement Clicks</h4>
            <p class="category">Last Campaign Performance</p>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>access_time</md-icon>
              updated 10 days ago
            </div>
          </template>
        </chart-card>
      </div> -->

      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="blue">
          <template slot="header">
            <md-icon
              ><i
                style="
                  margin-top: 50px;
                  font-size: 90px !important;
                  color: white;
                "
                class="fas fa-ring"
              ></i
            ></md-icon>
          </template>
          <template slot="content">
            <p class="category" style="color: green">Jewelry</p>
            <h3 class="title">
              <animated-number
                v-if="analytics.jewelry"
                :value="analytics.jewelry"
              ></animated-number>
            </h3>
          </template>
          <template slot="footer">
            <md-button @click="goTo('jewelry')">View</md-button>
            <md-button @click="goTo('jewelry', true)">Add Jewelry</md-button>
            <md-button @click="(showPopup = true), (itemType = 'Jewelry')"
              >Add From Diamond Track</md-button
            >
          </template>
        </stats-card>
      </div>

      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="blue">
          <template slot="header">
            <md-icon style="color: white">diamond</md-icon>
          </template>
          <template slot="content">
            <p class="category" style="color: green">Diamonds</p>
            <h3 class="title">
              <animated-number
                v-if="analytics.diamond"
                :value="analytics.diamond"
              ></animated-number>
            </h3>
          </template>

          <template slot="footer">
            <md-button @click="goTo('diamond')">View</md-button>
            <md-button @click="goTo('diamond', true)">Add Diamond</md-button>
            <md-button @click="(showPopup = true), (itemType = 'Diamond')"
              >Add From Diamond Track</md-button
            >
          </template>
        </stats-card>
      </div>

      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="blue">
          <template slot="header">
            <md-icon style="color: white">grade</md-icon>
          </template>
          <template slot="content">
            <p class="category" style="color: green">Imperfections</p>
            <h3 class="title">
              <animated-number
                v-if="analytics.imperfections"
                :value="analytics.imperfections"
              ></animated-number>
            </h3>
          </template>
          <template slot="footer">
            <md-button @click="goTo('imperfections')">View</md-button>
            <md-button @click="goTo('imperfections', true)"
              >Add Imperfections</md-button
            >
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="blue">
          <template slot="header">
            <md-icon style="color: white">group</md-icon>
          </template>
          <template slot="content">
            <div>
              <p class="category" style="color: green">Clients</p>
              <h3 class="title">
                <animated-number
                  v-if="analytics.clients"
                  :value="analytics.clients"
                ></animated-number>
              </h3>
            </div>
            <div>
              <p class="category" style="color: blue">
                Clients with Ad Portal Access
              </p>
              <h3 class="title">
                <animated-number
                  v-if="analytics.clientsWithAdPortalAccess"
                  :value="analytics.clientsWithAdPortalAccess"
                ></animated-number>
              </h3>
            </div>
          </template>

          <template slot="footer">
            <md-button @click="goTo('clients')">View</md-button>
            <md-button @click="goTo('clients', true)">Add Client</md-button>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="blue">
          <template slot="header">
            <md-icon style="color: white">subscriptions</md-icon>
          </template>
          <template slot="content">
            <p class="category" style="color: green">Digital Certificates</p>
            <h3 class="title">
              <animated-number
                v-if="analytics.digital_certificates"
                :value="analytics.digital_certificates"
              ></animated-number>
            </h3>
          </template>
          <template slot="footer">
            <md-button @click="goTo('digital-certificates')">View</md-button>
            <md-button @click="goTo('digital-certificates', true)"
              >Add Digital Certificate</md-button
            >
          </template>
        </stats-card>
      </div>
    </div>
    <!-- <div class="md-layout-item">
      <md-card>
        <md-card-header data-background-color="red">
          <h4 class="title">Incoming New Certificates</h4>
          <p class="category">New Certificates on {{ currentDate }}</p>
        </md-card-header>
        <md-card-content>
          <order-table
            @fetch-data="fetchNewlyAddedItems"
            :tableData="tableData"
            table-header-color="red"
          ></order-table>
        </md-card-content>
      </md-card>
    </div> -->
    <popup
      @fetch-data="fetchNewlyAddedItems"
      @close="showPopup = false"
      :itemType="itemType"
      :showPopup="showPopup"
    />
  </div>
</template>
  
  <script>
import { db } from "@/config/firebaseInit";
import {
  StatsCard,
  ChartCard,
  NavTabsCard,
  AnimatedNumber,
} from "@/components";

import NewOrdersTable from "@/components/NewOrdersTable.vue";
import NavTabsTable from "@/components/NavTabsTable.vue";
import Popup from "@/components/InventoryPopup.vue";
import moment from "moment";

export default {
  components: {
    StatsCard,
    ChartCard,
    NavTabsCard,
    NavTabsTable,
    "order-table": NewOrdersTable,
    AnimatedNumber,
    popup: Popup,
  },
  computed: {
    currentDate() {
      return moment().format("MM/D/yyyy");
    },
  },
  data() {
    return {
      analytics: {
        clientsWithAdPortalAccess: 0,
        digital_certificates: 0,
        diamond: 0,
        jewelry: 0,
        clients: 0,
        imperfections: 0,
      },
      allPageVisits: 0,
      allMatchingEmails: 0,
      allPageSends: 0,
      allReferrals: 0,
      referralCount: 0,
      pageSendCount: 0,
      pageVisitCount: 0,
      showPopup: false,
      itemType: "diamond",
      referralStats: {},
      memoryStats: {},
      pageSendStats: {},
      tableData: [],
      dailySalesChart: {
        data: {
          labels: ["M", "T", "W", "T", "F", "S", "S"],
          series: [[12, 17, 7, 17, 23, 18, 38]],
        },
        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      dataCompletedTasksChart: {
        data: {
          labels: ["12am", "3pm", "6pm", "9pm", "12pm", "3am", "6am", "9am"],
          series: [[230, 750, 450, 300, 280, 240, 200, 190]],
        },

        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      clickedAdsChart: {
        data: {
          labels: [
            "Ja",
            "Fe",
            "Ma",
            "Ap",
            "Mai",
            "Ju",
            "Jul",
            "Au",
            "Se",
            "Oc",
            "No",
            "De",
          ],
          series: [
            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
          ],
        },
        options: {
          axisX: {
            showGrid: true,
          },
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
    };
  },
  async created() {
    await this.getStats();
    // await this.fetchNewlyAddedItems();
  },
  methods: {
    async fetchNewlyAddedItems() {
      let diamonds = await db
        .collection("diamond")
        .where("NewlyAdded", "==", true)
        .orderBy("created", "desc")
        .get();
      let jewelry = await db
        .collection("jewelry")
        .where("NewlyAdded", "==", true)
        .orderBy("created", "desc")
        .get();

      diamonds = diamonds.docs.map((d) =>
        Object.assign({ id: d.id, Type: "Diamond" }, d.data())
      );
      jewelry = jewelry.docs.map((d) =>
        Object.assign({ id: d.id, Type: "Jewelry" }, d.data())
      );

      this.tableData = []
        .concat(diamonds, jewelry)
        .sort((a, b) => (a.created > b.created ? -1 : 1));
    },
    async getStats() {
      for (const key in this.analytics) {
        if (key != "clientsWithAdPortalAccess") {
          let data = await db.doc(`analytics/${key}`).get();
          data = data.data();
          this.analytics[key] = data.total;
          if (key == "clients") {
            this.analytics["clientsWithAdPortalAccess"] =
              data.ad_portal_access_total;
          }
        }
      }
    },
    goTo(url, action) {
      if (action) {
        let ref = db.collection(url).doc();
        return this.$router.push({ path: "/" + url + "/" + ref.id });
      }
      this.$router.push({ path: "/" + url });
    },
  },
};
</script>
  