<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card class="light">
        <md-card-content>
          <div class="search-options">
            <label for="">Search For: </label>
            <v-select
              :options="searchOptions"
              v-model="selectedSearchOption"
              label="name"
              :clearable="false"
            >
            </v-select>
          </div>
          <md-field class="search-bar">
            <div class="search-input">
              <md-input
                type="search"
                id="search-input"
                clearable
                placeholder="Search or Scan records here"
                v-model="searchVal"
                @keypress="evokeFilter($event)"
              ></md-input>
              <md-input
                autofocus
                type="search"
                style="opacity: 0"
                id="barcode-input"
                ref="barcodeInput"
                placeholder="Search or Scan records here"
              ></md-input>
            </div>
          </md-field>
          <md-button @click="handleSearch" class="md-success">Search</md-button>
          <md-button @click="showAddManuallyByPopUp = true" class="md-warning"
            >Add Manually</md-button
          >
        </md-card-content>
      </md-card>

      <md-card>
        <md-card-content>
          <md-table
            v-if="!showSelectableTable"
            md-fixed-header
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar :style="{ marginBottom: '20px' }">
              <md-field>
                <label for="shipping">Per page</label>
                <md-select v-model="pagination.perPage" name="shipping">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                    >{{ item }}</md-option
                  >
                </md-select>
              </md-field>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Intake" md-sort-by="created">{{
                item.created | format_date
              }}</md-table-cell>

              <md-table-cell md-label="Customer">{{
                item.Customer
              }}</md-table-cell>

              <md-table-cell md-label="Order Number" md-sort-by="OrderNum"
                >{{ item.OrderNum }} - {{ item.LineNum }}</md-table-cell
              >

              <md-table-cell
                md-label="Purchase Order"
                md-sort-by="PurchaseOrder"
                >{{ item.PurchaseOrder }}</md-table-cell
              >

              <md-table-cell md-label="Item Type" md-sort-by="JewelryType">{{
                item.JewelryType
              }}</md-table-cell>

              <md-table-cell md-label="Client SKU" md-sort-by="ClientSKU">{{
                item.ClientSKU || "N/A"
              }}</md-table-cell>

              <md-table-cell md-label="Certificate #" md-sort-by="CertNum">{{
                item.CertNum || item.StockNum || "N/A"
              }}</md-table-cell>
            </md-table-row>
          </md-table>

          <SelectableTable
            v-else
            @onSelected="onSelected"
            :tableData="searchedData"
            :tableInfo="selectedTableHeaders"
          >
            <template v-slot:btns>
              <div style="display: flex; flex-direction: column">
                <div>
                  <p class="required">Select The Certs Being Shipped Today</p>
                </div>
                <div>
                  <md-button
                    v-if="selectedItems.length"
                    class="md-primary"
                    @click="addCertsToList"
                    >Add Certs To List</md-button
                  >
                  <md-button
                    class="md-warning"
                    @click="
                      () => {
                        selectedItems = [];
                        showSelectableTable = false;
                      }
                    "
                    >Cancel</md-button
                  >
                </div>
              </div>
            </template>
          </SelectableTable>

          <div v-if="!showSelectableTable" class="btn-row stats_holder">
            <md-button
              class="md-primary md-small"
              @click="showProcessedByPopUp = true"
              >Save Log</md-button
            >
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>

    <modal v-if="showProcessedByPopUp" :allowToCloseModal="true">
      <template slot="header">
        <h3>Enter All Who Processed</h3>
      </template>
      <template slot="body">
        <md-field>
          <md-input @input="upperCaseText" v-model="processedBy"> </md-input>
        </md-field>
      </template>
      <template slot="footer">
        <md-button class="md-success" @click="saveShippingLog">
          Save & Quit
        </md-button>
      </template>
    </modal>

    <modal
      v-if="showAddManuallyByPopUp"
      @close="closeCustomItemModal"
      :allowToCloseModal="true"
    >
      <template slot="header">
        <h3>Enter Required Data</h3>
      </template>
      <template slot="body">
        <div class="md-field noFlex">
          <div class="static"><p>Client</p></div>
          <v-select
            required
            label="name"
            :clearable="false"
            :reduce="
              (client) =>
                Object.assign({
                  id: client.id,
                  name: client.name,
                })
            "
            :options="clients"
            v-model="customItem.Company"
          >
          </v-select>
        </div>
        <md-field ref="JewelryType" class="noFlex">
          <div class="static"><p>Jewelry Type</p></div>
          <v-select
            :clearable="false"
            v-model="customItem.JewelryType"
            required
            :options="jewelry_type"
          >
          </v-select>
        </md-field>
        <md-field>
          <label for="">Customer</label>
          <md-input v-model="customItem.Customer"> </md-input>
        </md-field>
        <md-field>
          <label for="">Order Number</label>
          <md-input v-model="customItem.OrderNum"> </md-input>
        </md-field>
        <md-field>
          <label for="">Line Number</label>
          <md-input v-model="customItem.LineNum"> </md-input>
        </md-field>
        <md-field>
          <label for="">Purchase Number</label>
          <md-input v-model="customItem.PurchaseOrder"> </md-input>
        </md-field>
        <md-field>
          <label for="">SKU</label>
          <md-input v-model="customItem.ClientSKU"> </md-input>
        </md-field>
        <md-field>
          <label for="">Item/Stock #</label>
          <md-input v-model="customItem.StockNum"> </md-input>
          <md-button @click="openLink">Search On DT</md-button>
        </md-field>
      </template>
      <template slot="footer">
        <md-button class="md-success" @click="saveManualItem"> ADD </md-button>
      </template>
    </modal>
  </div>
</template>

<script>
import { db, batch } from "@/config/firebaseInit";
import { Pagination, SelectableTable, Modal } from "@/components";
import Swal from "sweetalert2";

export default {
  components: {
    Pagination,
    Modal,
    SelectableTable,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.pendingShippedCerts;
      return result.reverse();
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pendingShippedCerts.length;
    },
  },
  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      clients: [],
      jewelry_type: [
        "Pendant",
        "Hoops",
        "Necklace",
        "Bracelet",
        "Ring",
        "Rings",
        "Earring",
        "Watch",
        "Solitaire",
        "Brooch",
        "Studs",
        "Watch Bezel",
        "Wedding Set",
      ],
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 100, 250, 500],
        total: 0,
      },
      customItem: {
        Company: null,
        JewelryType: null,
        Customer: null,
        PurchaseOrder: null,
        OrderNum: null,
        LineNum: null,
        ClientSKU: null,
        CustomItem: true,
        StockNum: null,
        created: Date.now(),
      },
      showAddManuallyByPopUp: false,
      searchedData: [],
      selectedItems: [],
      addedCertsFlag: {},
      pendingShippedCerts: [],
      selectedTableHeaders: [
        {
          label: "Customer",
          key: "Customer",
        },
        {
          label: "Order Number",
          key: "OrderNum",
        },
        {
          label: "Line Number",
          key: "LineNum",
        },
        {
          label: "Client SKU",
          key: "ClientSKU",
        },
        {
          label: "Item Type",
          key: "JewelryType",
        },
        {
          label: "Certificate #",
          key: "CertNum",
        },
        {
          label: "Stock #",
          key: "StockNum",
        },
      ],
      showSelectableTable: false,
      showProcessedByPopUp: null,
      processedBy: null,
      searchVal: null,
      selectedSearchOption: {
        name: "Order #",
        key: "OrderNum",
      },
      barcodeValue: "",
      barcode: "",
      searchOptions: [
        {
          name: "Order #",
          key: "OrderNum",
        },
        {
          name: "Cert #",
          key: "CertNum",
        },
        {
          name: "Item #",
          key: "ItemNum",
        },
        {
          name: "PO #",
          key: "PurchaseOrder",
        },
        {
          name: "Tracking #",
          key: "TrackingNumber",
        },
      ],
    };
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.handleBarcodeScanned);
  },
  mounted() {
    window.addEventListener("keydown", this.handleBarcodeScanned);
    this.$nextTick(() => {
      const inputElement = this.$refs.barcodeInput.$el;
      if (inputElement && typeof inputElement.focus === "function") {
        inputElement.focus();
      }
    });
  },
  watch: {
    showAddManuallyByPopUp(v) {
      if (v) {
        if (!this.clients.length) {
          this.$bind("clients", db.collection("companies"));
        }
      }
    },
  },
  methods: {
    upperCaseText() {
      this.processedBy = this.processedBy.toUpperCase();
    },
    openLink() {
      window.open(
        "https://diamondtrackonline.net/ServiceIntake/ServiceOrderSummary/Service%20Order",
        "_blank"
      );
    },
    saveManualItem() {
      this.selectedItems.push(this.customItem);
      this.addCertsToList();
      this.closeCustomItemModal();
    },
    closeCustomItemModal() {
      this.customItem = {
        Company: null,
        JewelryType: null,
        Customer: null,
        PurchaseOrder: null,
        OrderNum: null,
        LineNum: null,
        ClientSKU: null,
        CustomItem: true,
        StockNum: null,
        created: Date.now(),
      };
      this.showAddManuallyByPopUp = false;
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].toString().localeCompare(b[sortBy].toString());
        }
        return b[sortBy].toString().localeCompare(a[sortBy].toString());
      });
    },
    addCertsToList() {
      this.selectedItems.forEach((i) => {
        if (!this.addedCertsFlag[i.CertNum || i.StockNum]) {
          this.pendingShippedCerts.push(i);
        }
        this.addedCertsFlag[i.CertNum || i.StockNum] = true;
      });
      this.selectedItems = [];
      this.showSelectableTable = false;
    },
    saveShippingLog() {
      if (!this.processedBy) {
        return Swal.fire(
          "ERROR!",
          "Please type in the person who was processing.",
          "error"
        );
      }
      const batch = db.batch();
      this.pendingShippedCerts.forEach((item) => {
        if (item.CertNum || item.StockNum) {
          const ref = db.doc(`jewelry/${item.CertNum || item.StockNum}`);
          if (item.CustomItem) {
            batch.set(
              ref,
              Object.assign(item, {
                ShippedOn: Date.now(),
                HasBeenShipped: true,
                ProcessedBy: this.processedBy,
              })
            );
          } else {
            batch.update(ref, {
              ShippedOn: Date.now(),
              HasBeenShipped: true,
              ProcessedBy: this.processedBy,
            });
          }
        }
      });

      return batch.commit().then(() => {
        Swal.fire(
          "Successful",
          "Certs has been added to shipping log",
          "success"
        ).then(() => {
          this.addedCertsFlag = {};
          this.$emit("close");
        });
      });
    },
    onSelected(items) {
      this.selectedItems = items;
    },

    handleBarcodeScanned(e) {
      let query = null;
      if (e.target.id != "search-input") {
        if (e.code == "Enter") {
          if (this.barcode) {
            let value = this.barcode;
            this.barcode = "";
            const queryByOrder = value.includes("-");
            const queryByTrackingNumber = this.handleTrackingNum(value);
            if (queryByOrder || queryByTrackingNumber) {
              if (queryByOrder) {
                value = value.split("-");
                console.log(value);
                query = db
                  .collection("jewelry")
                  .where("HasBeenShipped", "==", false)
                  .where("OrderNum", "==", value[0])
                  .where("LineNum", "==", parseInt(value[1]));
              } else if (queryByTrackingNumber) {
                query = db
                  .collection("jewelry")
                  .where("HasBeenShipped", "==", false)
                  .where("TrackingNum", "==", value);
              }

              this.$bind("selectedItems", query).then((data) => {
                this.addCertsToList();
              });
            }
          }
        }

        if (e.key != "Shift" && e.key != "Enter") {
          this.barcode += e.key;
          // this.interval = setInterval(() => (this.barcode = ""), 20);
        }
      } else {
        let value = e.target.value;
        if (value.includes("-")) {
          value = value.split("-");

          this.selectedSearchOption = {
            name: "Order Number",
            key: "OrderNum",
          };
        } else if (this.handleTrackingNum(value)) {
          this.selectedSearchOption = {
            name: "Tracking Number",
            key: "TrackingNum",
          };
        }
      }
    },
    handleTrackingNum(val) {
      const value = val[0] + val[1];
      return value == "1Z";
    },
    handleSearch() {
      if (this.searchVal) {
        let value = this.searchVal;
        let query;
        if (value.includes("-")) {
          value = value.split("-");
          console.log({ value });
          query = db
            .collection("jewelry")
            .where("HasBeenShipped", "==", false)
            .where("OrderNum", "==", value[0])
            .where("LineNum", "==", parseInt(value[1]));
        } else {
          query = db
            .collection("jewelry")
            .where("HasBeenShipped", "==", false)
            .where(this.selectedSearchOption.key, "==", value);
        }

        this.$bind("searchedData", query).then((data) => {
          if (data.length) {
            this.showSelectableTable = true;
            this.searchVal = null;
            this.selectedItems = [];
            this.searchedData = data;
          } else {
            // Swal.fire("No Results", "No Results Found", "info");
            this.searchVal = null;
          }
        });
      }
    },
    evokeFilter(e) {
      if (e.keyCode == 13) {
        this.handleBarcodeScanned(e);
      }
    },
  },
};
</script>

<style scoped>
.search-options {
  width: 15rem;
  text-align: initial;
  margin-bottom: 5px;
}

.search-options > label {
  color: black;
}

.search-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.search-input {
  display: flex;
  justify-content: end;
  align-items: end;
  width: 100%;
  height: auto;
}
</style>
