import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";

// Dashboard pages
import Dashboard from "@/pages/Dashboard/Dashboard.vue";
// Auth
import Login from "@/pages/Dashboard/Pages/Login.vue";
import ForgotPassword from "@/pages/Dashboard/Pages/ForgotPassword.vue";

// -----------------------CMS Pages--------------------------------------

//Diamonds
import Diamonds from "@/pages/DiamondManagement/Diamonds.vue";
import DiamondDetails from "@/pages/DiamondManagement/DiamondDetails.vue";

//Jewelry
import Jewelry from "@/pages/JewelryManagement/Jewelry.vue";
import JewelryDetails from "@/pages/JewelryManagement/JewelryDetails.vue";
//Users
import Users from "@/pages/Users/Users.vue";
import UserProfile from "@/pages/Users/UserProfile.vue";
//Clients
import Clients from "@/pages/ClientManagement/Clients.vue";
import ClientDetails from "@/pages/ClientManagement/ClientDetails.vue";
import CompanyProfile from "@/pages/ClientManagement/CompanyProfile.vue";
//Imperfections
import Imperfections from "@/pages/DiamondManagement/Imperfections/Imperfections.vue";
import ImperfectionDetails from "@/pages/DiamondManagement/Imperfections/ImperfectionDetails.vue";
import Pamphlet from "@/pages/DiamondManagement/Imperfections/Pamphlet.vue";
//Digital Certificates
import Videos from "@/pages/DigitalCertificates/Videos.vue";
import VideoDetails from "@/pages/DigitalCertificates/VideoDetails.vue";
//Client Digital Certificate QR List
import Details from "@/pages/DigitalCertificatesQR/Details.vue";
import List from "@/pages/DigitalCertificatesQR/List.vue";
//Error Page
import Error404 from "@/pages/Auth/Error404.vue";

// Settings
import Attributes from "@/pages/Attributes/Attributes.vue";
import Reports from "@/pages/ActivityLog/Reports.vue";

import AsyncWorldMap from "@/pages/Dashboard/Maps/WorldMap.vue";
import Analytics from "@/pages/Analytics/Analytics.vue";
//Billing
import Billing from "@/pages/Billing/Billing.vue";


//Shipping Log
import ShippingLog from "@/pages/Shipping Log/ShippingLog.vue"

const clientMenu = {
  path: "/clients",
  component: DashboardLayout,
  name: "Clients",
  redirect: "/",
  children: [
    {
      path: "/",
      name: "Clients",
      components: { default: Clients },
    },
    {
      path: "profile/:company_id",
      name: "Client Profile",
      components: { default: CompanyProfile },
    },
    {
      path: ":company_id",
      name: "Client Details",
      components: { default: ClientDetails },
    },
  ],
  meta: {
    requiresAuth: true,
  },
};

const jewelryMenu = {
  path: "/jewelry",
  component: DashboardLayout,
  name: "Jewelry",
  redirect: "/",
  children: [
    {
      path: "/",
      name: "Jewelry",
      components: { default: Jewelry },
    },
    {
      path: ":jewelry_id",
      name: "Jewelry Details",
      components: { default: JewelryDetails },
    },
  ],
  meta: {
    requiresAuth: true,
  },
};

const diamondsMenu = {
  path: "/diamond",
  component: DashboardLayout,
  name: "Diamonds",
  redirect: "/",
  children: [
    {
      path: "/",
      name: "Diamonds",
      components: { default: Diamonds },
    },
    {
      path: ":diamond_id",
      name: "Diamond Details",
      components: { default: DiamondDetails },
    },
  ],
  meta: {
    requiresAuth: true,
  },
};

const attributes = {
  path: "/attributes",
  component: DashboardLayout,
  name: "Attributes",
  redirect: "/",
  children: [
    {
      path: "/",
      name: "Attributes",
      components: { default: Attributes },
    },
  ],
  meta: {
    requiresAuth: true,
  },
};

const logs = {
  path: "/logs",
  component: DashboardLayout,
  name: "Activity Logs",
  redirect: "/",
  children: [
    {
      path: "/",
      name: "Activity Logs",
      components: { default: Reports },
    },
  ],
  meta: {
    requiresAuth: true,
  },
};

const billing = {
  path: "/billing",
  component: DashboardLayout,
  name: "Billing",
  redirect: "/",
  children: [
    {
      path: "/",
      name: "Billing",
      components: { default: Billing },
    },
  ],
  meta: {
    requiresAuth: true,
  },
};

const register = {
  path: "/user",
  component: DashboardLayout,
  name: "Users",
  redirect: "/",
  children: [
    {
      path: "/",
      name: "Users",
      components: { default: Users },
    },
    {
      path: ":user_id",
      name: "User",
      components: { default: UserProfile },
    },
  ],
  meta: {
    requiresAuth: true,
  },
};

const imperfections = {
  path: "/imperfections",
  component: DashboardLayout,
  name: "Imperfections",
  redirect: "/",
  children: [
    {
      path: "/",
      name: "Imperfections",
      components: { default: Imperfections },
    },
    {
      path: ":id",
      name: "Imperfections",
      components: { default: ImperfectionDetails },
    },
    {
      path: "/pamphlet/:id",
      name: "Pamphlet",
      components: { default: Pamphlet },
    },
  ],
  meta: {
    requiresAuth: true,
  },
};

const analytics = {
  path: "/analytics",
  component: DashboardLayout,
  name: "Analytics",
  redirect: "/",
  children: [
    {
      path: "/",
      name: "Analytics",
      components: { default: Analytics },
    },
  ],
  meta: {
    requiresAuth: true,
  },
};


const shippinglog = {
  path: "/shipping-log",
  component: DashboardLayout,
  name: "Shipping Log",
  redirect: "/",
  children: [
    {
      path: "/",
      name: "Shipping Log",
      components: { default: ShippingLog },
    },
  ],
  meta: {
    requiresAuth: true,
  },
};

const forgotPassword = {
  path: "/",
  redirect: "/",
  component: ForgotPassword,
  children: [
    {
      path: "/forgot-password",
      name: "Forgot Password",
    },
  ],
};

const authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/login/:bypass_code",
      name: "Login",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: Login,
    },
  ],
};

const digitalCertificates = {
  path: "/digital-certificates",
  name: "Digital Certificate Media",
  component: DashboardLayout,
  redirect: "/",
  children: [
    {
      path: "/",
      name: "Digital Certificate Media",
      components: { default: Videos },
    },
    {
      path: ":digital_cert_id",
      name: "Digital Certificate Media Details",
      components: { default: VideoDetails },
    },
  ],
  meta: {
    requiresAuth: true,
  },
};

const certificateQRList = {
  path: "/digital-cert-qr",
  name: "Client Digital Certificate QR List",
  component: DashboardLayout,
  redirect: "/",
  children: [
    {
      path: "/",
      name: "Client Digital Certificate QR List",
      components: { default: List },
    },
    {
      path: ":list_id",
      name: "Client's Digital Certificate QR Details",
      components: { default: Details },
    },
  ],
  meta: {
    requiresAuth: true,
  },
};

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home",
  },
  diamondsMenu,
  jewelryMenu,
  analytics,
  clientMenu,
  digitalCertificates,
  certificateQRList,
  authPages,
  register,
  attributes,
  billing,
  shippinglog,
  logs,
  imperfections,
  forgotPassword,
  {
    path: "/",
    component: DashboardLayout,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
        meta: {
          requiresAuth: true,
        },
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "*",
    name: "Error404",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Error404,
  },
];

export default routes;
