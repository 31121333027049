<template>
  <div class="upload-parent">
    <label>{{ label }} <span v-if="required" style="color: red">*</span></label>
    <div class="upload_holder">
      <transition name="fade">
        <div
          v-if="(!media || !media.url) && !load"
          class="upload_click drop-area"
          @click="$refs.upload.click()"
          @drop="handleDrop"
          @dragover.prevent
        >
          <md-icon>present_to_all</md-icon>
          <p>
            You currently have no media uploaded. Drag and Drop or Click here to
            upload media.
          </p>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="!media && load" class="loading">
          <md-icon class="rotate">autorenew</md-icon>
          <p>loading...</p>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="media && media.url && !load" class="success">
          <img v-if="media_type == 'image'" :src="media.url" />
          <video
            poster=""
            v-if="media_type == 'video'"
            width="320"
            height="240"
            controls
          >
            <source :src="media.url" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <p @click="removeFile" class="text_btn">Remove</p>
        </div>
      </transition>
      <input
        ref="upload"
        type="file"
        :accept="media_type + '/*'"
        class="hide_upload"
        @change="uploadFile"
      />
    </div>
  </div>
</template>

<script>
import { db, storage } from "@/config/firebaseInit";

export default {
  props: ["label", "media", "media_type", "path", "required", "useCustomPath"],
  data() {
    return {
      load: false,
      image: null,
    };
  },
  methods: {
    handleDrop(event) {
      event.preventDefault();
      this.uploadFile({ target: { files: event.dataTransfer.files } });
    },
    uploadFile(e) {
      let vm = this;
      this.upload = null;
      vm.load = null;
      setTimeout(function () {
        vm.load = true;
      }, 500);
      let media = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(media);
      reader.onload = (e) => {
        const refStr = this.useCustomPath
          ? this.path + "/" + Date.now()
          : `${this.path}/${media.name}}`;
        let storageRef = storage.ref(refStr);
        let uploadTask = storageRef.put(media);

        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (error) => {
            vm.logoLoad = false;
            alert("could not upload logo, please try again.");
          },
          (snapshot) => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              let image = {
                url: downloadURL,
                name: media.name,
                type: media.type.split("/")[1],
                ref: refStr,
              };
              this.media = image;
              this.$emit("uploadedFile", {
                media: image,
                media_type: this.media_type,
              });
              vm.load = null;
              setTimeout(function () {
                vm.load = false;
              }, 500);
            });
          }
        );
      };
    },
    removeFile() {
      this.media = null;
      this.$emit("removeFile", null);
    },
  },
};
</script>

<style lang="css" scoped>
.upload_holder {
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: #f7f7f7;
  /* max-width: 400px; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.hide_upload {
  position: absolute;
  z-index: -2;
}

.upload_click,
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.upload_click .md-icon {
  font-size: 53px !important;
  opacity: 0.5;
}

.upload_click p {
  line-height: 1.3;
  max-width: 300px;
  text-align: center;
  opacity: 0.5;
  margin-top: 30px;
  margin-bottom: 0;
}

.rotate {
  font-size: 40px !important;
  -webkit-animation: loading 1s infinite linear;
}

.success {
  width: 10%;
}
.success p {
  text-align: center;
  margin-bottom: 0;
}

.success img {
  width: 100%;
  max-height: 100%;
}

.text_btn {
  text-decoration: underline;
  color: red;
}

@-webkit-keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
