<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-button @click="$router.go(-1)" class="md-alert">back</md-button>
      <md-card>
        <md-card-content>
          <md-field ref="qr-name">
            <label>Title <span class="required">*</span></label>
            <md-input
              type="text"
              v-model="digitalCertificateList.title"
            ></md-input>
            <span class="md-error">Missing Field</span>
          </md-field>

          <div class="static">
            <p>Company <span class="required">*</span></p>
          </div>
          <div class="md-field">
            <div class="v-select-wrapper">
              <v-select
                @option:selected="fetchClientSkus"
                required
                label="name"
                appendToBody
                :clearable="false"
                :options="companies"
                v-model="digitalCertificateList.client"
              >
              </v-select>
            </div>
          </div>

          <div class="panel-container">
            <div class="left-panel">
              <div class="static">
                <p>Search and Select SKU <span class="required">*</span></p>
              </div>
              <div class="md-field">
                <div class="v-select-wrapper">
                  <v-select
                    required
                    label="name"
                    appendToBody
                    multiple
                    :reduce="
                      (d) =>
                        Object.assign({
                          id: d.id,
                          name: d.name,
                          url: d.meta ? d.meta.url : d.url,
                          ref: d.meta ? d.meta.ref : d.ref,
                        })
                    "
                    :options="digitalCertificates"
                    v-model="selectedCertificates"
                  >
                  </v-select>
                </div>
              </div>
            </div>
            <div class="middle-panel">
              <div class="circle">
                <span class="or-text">OR</span>
              </div>
            </div>
            <div class="right-panel">
              <div class="uploader">
                <div>
                  <div class="required">
                    MAKE SURE THE FILE'S NAME MATCHES THE PRODUCT'S SKU BEFORE
                    UPLOAD
                  </div>
                  <Multiple
                    @uploadedFiles="handleUploadedMult"
                    @removeFile="removeMultMedia"
                    label="Upload Multiple Certificate Video(s)"
                    :files="uploadedVideos"
                    :path="`certificate_list/${$route.params.digital_cert_id}`"
                    media_type="video"
                    :required="true"
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedCertificates.length" class="certificate-lists">
            <div
              v-for="cert in selectedCertificates"
              :key="cert.id"
              class="certificates"
            >
              <video autoplay :src="cert.url"></video>
            </div>
          </div>

          <div class="qr-code-container" ref="qrCodeContainer">
            <qr-code-wrapper
              class="qrcode"
              v-if="showPrintBtn"
              render-as="svg"
              :value="`https://certificates.diamondservicesusa.com/digitalcertlist/${this.$route.params.list_id}`"
            ></qr-code-wrapper>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left">
          <md-button class="md-success md-round md-small" @click="validateSave"
            >Save List</md-button
          >

          <md-button
            v-if="showPrintBtn"
            class="md-primary md-round md-small"
            @click="printQRCode"
            >Generate/Print QR Image</md-button
          >
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import { db, storage, functions } from "@/config/firebaseInit";
import Swal from "sweetalert2";
import Multiple from "@/components/Inputs/UploadMultiple.vue";

export default {
  data() {
    return {
      digitalCertificateList: {
        certificates: [],
        client: null,
        title: null,
        createdAt: Date.now(),
        qrCodeGenerated: false,
      },
      companies: [],
      certificates: [],
      upload_progress: null,
      upload_error: null,
      uploadMult: false,
      showPrintBtn: false,
      uploadedVideos: [],
      selectedCertificates: [],
      digitalCertificates: [],
    };
  },
  components: {
    Multiple,
  },

  created() {
    this.fetchCompanies();
    this.getDigitalCertList();
  },
  methods: {
    async fetchCompanies() {
      let companies = await db
        .collection("companies")
        .orderBy("name", "asc")
        .get();
      this.companies = companies.docs.map((d) =>
        Object.assign({
          id: d.id,
          name: d.data().name,
          logo: d.data().images ? d.data().images.url : "",
        })
      );
    },
    async fetchClientSkus(client) {
      let digitalCertificates = await db
        .collection("digital_certificate_videos")
        .where("client.id", "==", client.id)
        .orderBy("createdOn", "desc")
        .get();

      this.digitalCertificates = digitalCertificates.docs.map((d) =>
        Object.assign(d.data(), { id: d.id })
      );
    },
    printQRCode() {
      // Get the QR code element by its ID or class
      //   const qrCodeElement = document.querySelector(".qrcode > svg");
      //   // Create a new window for printing
      //   const printWindow = window.open("", "_blank");
      const qrCodeContainer = this.$refs.qrCodeContainer;
      const qrCodeSvg = qrCodeContainer.querySelector("svg");
      const printWindow = window.open("", "_blank");
      const content = document.createElement("div");
      content.innerHTML = `
              <div style="
                display: flex;
                flex-direction: column;
                margin: 0 auto;
                align-items: center;
                justify-content: center;
                justify-items: center;"
              >
              <h5>${this.digitalCertificateList.title}</h5>
              ${qrCodeSvg.outerHTML}
              </div>`;
      // Set the content of the new window to the QR code image
      printWindow.document.write(content.innerHTML);
      printWindow.document.close();
      // Trigger the print functionality
      printWindow.print();
    },
    closeHelper() {
      if (this.showHelper.multInfo) this.showHelper.multInfo = false;
      if (this.showHelper.skuInfo) this.showHelper.skuInfo = false;
    },
    uploadedMedia(payload) {
      this.digitalCertificateList.meta = payload.media;
    },
    handleUploadedMult(payload) {
      this.uploadedVideos = payload;
      payload.forEach((d) => {
        this.selectedCertificates.push(d);
      });
    },
    async getDigitalCertList() {
      let digitalCertificateList = await db
        .collection("digital_certificate_lists")
        .doc(this.$route.params.list_id)
        .get();
      if (digitalCertificateList.exists) {
        digitalCertificateList = digitalCertificateList.data();
        this.selectedCertificates = digitalCertificateList.certificates;
        this.digitalCertificateList = digitalCertificateList;
        this.fetchClientSkus(digitalCertificateList.client);
        this.showPrintBtn = true;
      }
    },

    removeMultMedia(index, file) {
      const imageRef = storage.ref(file.ref);
      // Delete the file
      imageRef
        .delete()
        .then(() => {
          this.uploadedVideos.splice(index, 1);
        })
        .catch((error) => {
          this.displayError(error);
        });
    },
    displayError(error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log({ errorCode, errorMessage });
      return Swal.fire(
        "Error!",
        `${errorMessage} - code: ${errorCode}. Please try again or contact support.`,
        "error"
      );
    },
    saveList() {
      const id = this.$route.params.list_id;
      const loader = this.$loading.show({
        container: this.$refs.loader,
        canCancel: false,
      });

      let certificates = this.selectedCertificates.map(async (digiCert) => {
        const certInfo = await this.fetchCertificateInfoDoc(digiCert.id);
        digiCert.certInfo = certInfo;
        console.log(certInfo);
        return digiCert;
      });

      Promise.all(certificates).then((res) => {
        console.log(res);
        this.digitalCertificateList.certificates = res;
        return db
          .collection("digital_certificate_lists")
          .doc(id)
          .set(this.digitalCertificateList, { merge: true })
          .then(() => {
            loader.hide();
            this.showPrintBtn = true;
            return Swal.fire(
              "Success",
              "Video List Has Been Created, You can print QR Code Now",
              "success"
            );
          })
          .catch((error) => {
            this.displayError(error);
          });
      });
    },
    fetchCertificateInfoDoc(digiCertID) {
      return db
        .doc(`jewelry/${digiCertID}`)
        .get()
        .then((doc) => {
          const certInfo = {
            caratWeight: null,
            shape: null,
            type: null,
          };
          if (doc.exists) {
            const cert = doc.data();
            certInfo.caratWeight = cert.MainStoneWeight;
            certInfo.shape = cert.MainStoneShape;
            certInfo.type = cert.JewelryType;
          }

          console.log(certInfo);
          return certInfo;
        });
    },
    validateSave() {
      let valid = true;
      if (!this.digitalCertificateList.client) {
        valid = false;
      }

      if (
        !this.selectedCertificates.length ||
        this.selectedCertificates.length < 2
      ) {
        valid = false;
      }

      if (!valid) {
        return Swal.fire(
          "Missing Required Fields",
          `Must fill in all required fields and select/upload a minimum of three video`,
          "error"
        );
      } else {
        this.saveList();
      }
    },
  },

  watch: {},
};
</script>

<style lang="css" scoped>
.panel-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #f0f0f0;
}

.left-panel {
  flex: 1;
}

.middle-panel {
  flex: 0 0 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #c0c0c0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.or-text {
  font-size: 16px;
  font-weight: bold;
}

.right-panel {
  flex: 1;
}

.helper-box {
  position: absolute;
  width: 200px;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 99;
  margin-left: 5.5em;
  text-align: center;
}

.triangle {
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f0f0f0;
}

.content {
  margin-top: 10px;
}
.caution-sign {
  color: red;
}
.helper {
  width: 25px;
  height: 25px;
  display: block;
  text-align: center;
  padding-bottom: 15px;
  border-radius: 50px;
  border: 1px solid green;
  color: white;
  background-color: green;
  margin-top: 12px;
  cursor: pointer;
}

.switch-container {
  display: flex;
  flex-direction: row;
}
.v-select-wrapper {
  width: 100%;
}
.uploader {
  min-width: 500px;
  max-width: 800px;
}
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.certificate-lists {
  width: calc(40%);
  display: flex;
  flex-wrap: wrap;
  border: 0.5px black dashed;
  padding: 20px;
  max-height: 800px;
  position: relative;
  left: 30%;
  top: 30px;
  overflow: auto;
}

.certificates {
  max-width: 100px;
  margin-bottom: 10px;
  margin-right: 15px;
  background-color: #f0f0f0;
  /* Add additional styling for video item */
}
.qr-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Adjust the height to match your label dimensions */
}

.qrcode {
  display: none; /* Hide the URL */
}
@page {
  margin: 0;
}

@media print {
  .qr-code-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
  }
  .qrcode {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
  }
  a[href]:after {
    content: none !important;
  }
  img[src]:after {
    content: none !important;
  }
}
</style>
