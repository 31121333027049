<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-content>
          <md-button
            @click="
              (administratorInit = !administratorInit),
                (updateAdministratorInit = false)
            "
            class="md-primary"
            >Add New Admin</md-button
          >
        </md-card-content>
      </md-card>
      <md-card v-if="administratorInit">
        <md-card-content>
          <form @submit.prevent>
            <md-field>
              <label>Full Name</label>
              <md-input
                required
                v-model="administrator.fullname"
                type="text"
              ></md-input>
            </md-field>
            <md-field>
              <the-mask
                placeholder="Phone"
                id="md-input-00tt9ig"
                class="md-input"
                v-model="administrator.phone"
                :mask="['(###) ###-####']"
              />
            </md-field>

            <md-field>
              <label>Email Address</label>
              <md-input
                required
                v-model="administrator.email"
                type="text"
              ></md-input>
            </md-field>
            <md-field v-if="!updateAdministratorInit">
              <label>Password</label>
              <md-input
                required
                v-model="administrator.password"
                type="text"
              ></md-input>
            </md-field>
          </form>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <md-button
            v-if="!updateAdministratorInit"
            class="md-primary md-round md-small"
            @click="signUp"
            >Save</md-button
          >
          <md-button
            v-else
            class="md-primary md-round md-small"
            @click="update"
            >Save</md-button
          >

          <md-button class="md-primary md-round md-small" @click="cancel()"
            >Cancel</md-button
          >
        </md-card-actions>
      </md-card>
      <md-card v-else>
        <md-card-content>
          <md-table
            md-fixed-header
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="diamonds">Per page</label>
                <md-select v-model="pagination.perPage" name="diamonds">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="counter[item.uid]"
                    :label="item"
                    :value="item"
                    >{{ item }}</md-option
                  >
                </md-select>
              </md-field>
              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Search records"
                  v-model="searchQuery"
                ></md-input>
              </md-field>
            </md-table-toolbar>

            <md-table-row
              slot="md-table-row"
              :style="{
                backgroundColor:
                  item.hasOwnProperty('needs_approval') && item.needs_approval
                    ? 'rgba(253, 207, 207, 0.68)'
                    : 'none',
              }"
              slot-scope="{ item }"
            >
              <md-table-cell md-label="Name" md-sort-by="shape">
                <a @click="goTo(item.uid)">{{ item.fullname }}</a>
              </md-table-cell>
              <md-table-cell md-label="Email" md-sort-by="price">{{
                item.email
              }}</md-table-cell>
              <md-table-cell md-label="Phone" md-sort-by="price">{{
                item.phone
              }}</md-table-cell>

              <md-table-cell
                md-label="Posts"
                md-sort-by="total_skus"
                v-model="counter[item.uid]"
                v-if="!counter[item.uid]"
                ><a @click="goTo(item.uid)">0</a></md-table-cell
              >

              <md-table-cell
                md-label="Posts"
                md-sort-by="total_skus"
                v-model="searchQuery"
                v-if="counter[item.uid]"
              >
                <a @click="goTo(item.uid)">{{
                  counter[item.uid]
                }}</a></md-table-cell
              >

              <md-table-cell md-label="Edit">
                <md-button
                  class="md-just-icon md-warning md-simple"
                  @click.native="handleEdit(item)"
                >
                  <md-icon>edit</md-icon>
                </md-button>
              </md-table-cell>
              <md-table-cell md-label="Delete">
                <md-button
                  class="md-just-icon md-danger md-simple"
                  @click.native="handleDelete(item)"
                >
                  <md-icon>close</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class>
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          ></pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import { Pagination } from "@/components";
import firebase from "firebase";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import router from "@/router";
import { db, functions } from "@/config/firebaseInit";
import axios from "axios";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      administratorInit: false,
      updateAdministratorInit: false,
      currentSort: "name",
      currentSortOrder: "asc",
      counter: {},
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: ["Name", "Email", "Phone", "Edit", "Delete"],
      searchQuery: "",
      propsToSearch: ["firstname", "lastname", "email"],
      tableData: [],
      copyData: [],
      searchedData: [],
      fuseSearch: null,
      administrator: {
        isSuperAdmin: true,
        fullname: "",
        phone: "",
        email: "",
        total_skus: 0,
        password: null,
      },
      inviteEmail: null,
      showDialog: false,
      addUser: functions.httpsCallable("addUser"),
    };
  },
  watch: {
    updateAdministratorInit() {
      if (!this.updateAdministratorInit) {
        this.administrator = {
          fullname: "",
          phone: "",
          email: "",
          password: null,
        };
      }
    },
    searchQuery(value) {
      if (value === "") {
        this.tableData = this.copyData;
        return;
      }
      let res = [];
      let q = value.toLowerCase();
      let result = this.copyData;
      result.filter((d) => {
        let phone = d["phone"].toString();
        let name = d["fullname"].toLowerCase();
        let email = d["email"].toLowerCase();
        if (
          phone.indexOf(q) > -1 ||
          name.indexOf(q) > -1 ||
          email.indexOf(q) > -1
        ) {
          res.push(d);
          return;
        }
      });
      this.tableData = res;
    },
  },

  async created() {
    await this.updateTable();
  },

  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },

  methods: {
    async updateTable() {
      await this.$bind("tableData", db.collection("administrator"));
      await this.tableData.forEach(async (user) => {
        await this.getUserPosts(user.uid).then(() => {
          this.tableData = this.tableData.concat([]);
        });
      });
      await (this.copyData = this.tableData);
    },
    async getUserPosts(uid) {
      var counter = 0;
      await db
        .collection("diamond")
        .where("userId", "==", uid)
        .get()
        .then((snap) => {
          counter += snap.size;
          return counter;
        })
        .catch((e) => {
          return e;
        });
      await db
        .collection("jewelry")
        .where("userId", "==", uid)
        .get()
        .then((snap) => {
          counter += snap.size;
          return counter;
        })
        .catch((e) => {
          return e;
        });
      this.counter[uid] = counter;
      return counter;
    },
    handleDelete(item) {
      const admin = db.collection("administrator").doc(item.id);
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
        .then((result) => {
          if (result.value) {
            admin.delete();
            Swal.fire("Deleted!", "The data has been deleted.", "success");
          }
        })
        .then(async () => {
          await this.updateTable();
        });
    },
    goTo(id) {
      this.$router.push(`/user/${id}`);
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (
          (this.currentSort === "created") &
          (this.currentSortOrder === "desc")
        ) {
          return a[sortBy] - b[sortBy];
        }

        if (
          (this.currentSort === "created") &
          (this.currentSortOrder === "asc")
        ) {
          return b[sortBy] - a[sortBy];
        }

        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    handleEdit(administrator) {
      this.administrator = administrator;
      this.administratorInit = true;
      this.updateAdministratorInit = true;
    },
    cancel() {
      this.administratorInit = false;
      this.updateAdministratorInit = false;
    },
    update() {
      let administratorRef = db
        .collection("administrator")
        .doc(this.administrator.id);
      let administrator = this.administrator;
      return administratorRef.set(administrator, { merge: true }).then(() => {
        this.updateAdministratorInit = false;
        this.administratorInit = false;
        this.administrator = {
          fullname: "",
          email: "",
          total_skus: 0,
          password: null,
        };
        setTimeout(function () {
          return Swal.fire("Success", "Administrator Updated", "success");
        }, 500);
      });
    },
    signUp() {
      let vm = this;
      if (this.validate()) {
        this.addUser({
          email: this.administrator.email,
          emailVerified: true,
          password: this.administrator.password,
          displayName: this.administrator.fullname,
        })
          .then((res) => {
            let administratorRef = db.doc(`administrator/${res.data}`);
            let administrator = this.administrator;
            administrator.uid = res.data;
            delete administrator.password;
            return administratorRef.set(administrator);
          })
          .then(
            async function () {
              vm.$parent.flash = true;
              vm.administratorInit = false;
              vm.administrator = {
                fullname: "",
                phone: "",
                email: "",
                total_skus: 0,
                password: null,
              };
              Swal.fire("Success", "Administrator Created", "success");
              await vm.updateTable();
            },
            function (err) {
              return Swal.fire("Error", err.message, "warning");
            }
          )
          .catch((err) => {
            return Swal.fire("Error", err.message, "warning");
          });
      } else {
        return Swal.fire("Error", "Missing Required Fields", "warning");
      }
    },
    validate() {
      let valid = true;
      for (let key in this.administrator) {
        if (key !== "phone" && !this.administrator[key]) {
          if (key === "total_skus") {
            continue;
          }
          valid = false;
        }
      }
      return valid;
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.invite-modal {
  width: 500px;
}

.invite-wrapper {
  width: 75%;
  margin: 0 auto;
}
a {
  cursor: pointer;
}
</style>
