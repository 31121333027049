<template>
  <div class="md-layout">
    <p>
      Key: <strong>Main 4:</strong> <span>Shape</span> + <span>Weight</span> +
      <span>Color</span> + <span>Clarity</span>
    </p>
    <div class="t-list">
      <ul v-for="t in templates" :key="t.id">
        <li>
          <strong> Template# {{ t.id }} - {{ t.name }}:</strong> "{{ t.info }}"
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "info-screen",
  data() {
    return {
      templates: [
        {
          name: "Everything Round Center + Sides",
          id: 1,
          info: "Round Center Diamond + Side Stones",
        },
        {
          name: "Main Stones (No Center/Sides)",
          id: 2,
          info: "Main Stones with Side Stones but No Center Stone",
        },
        {
          name: "Main Stone Minimalist",
          id: 3,
          info: "Main Stone + Sides Stones + Measurements and the Main 4",
        },
        {
          name: "Multiple Center Stones",
          id: 4,
          info: "Multiple Center Stones and all information",
        },
        {
          name: "Solitaire Minimalist",
          id: 5,
          info: "Single Stone with Main 4",
        },
        {
          name: "Round Center + Sides (No Cut)",
          id: 6,
          info: "Round Center + Sides and No Cut",
        },
        {
          name: "Main Stone Minimalist (No Measurements)",
          id: 7,
          info: "Main Stone + Main 4 + No Measurements",
        },
        {
          name: "Main Stone (No Sides)",
          id: 8,
          info: "Main Stone Only No Side Stones",
        },
        {
          name: "Main Stone (No Side & Cut)",
          id: 9,
          info: "Main Stone Only",
        },
        {
          name: "Main Stone (1Color & 2Clarity)",
          id: 10,
          info: "Main Stone with One Color and Two Clarity + Main 4. No Side Stones or Cut",
        },
        {
          name: "Main Stone (2Color & 1Clarity)",
          id: 11,
          info: "Main Stone with One Clarity and Two Colors + Main 4. No Side Stones or Cut",
        },
        {
          name: "Main Black Stone Minimalist ",
          id: 12,
          info: "Main Black Stone + Main 4 + No Measurements",
        },
        {
          name: "Multiple Center Stones (No Cut)",
          id: 13,
          info: "Multiple Center Stones but No Cut",
        },
        {
          name: "No Diamond (No Color, No Clarity)",
          id: 14,
          info: "Does not have any diamonds in the jewelry (Perfect for Pendants)",
        },
        {
          name: "Gem Stone as Primary Stone",
          id: 15,
          info: "Gem stone is the main primary stone while Diamonds would be secondary side stones",
        },
        {
          name: "Diamond Primary Stone and Gem Secondary Stone",
          id: 16,
          info: "Diamond is the main primary stone while Gem Stone would be secondary side stones",
        },
        {
          name: "Only Gem Stones",
          id: 17,
          info: "Only Gem Stones are included",
        },
        {
          name: "Metal Only",
          id: 19,
          info: "Only Metal Jewelry Like Gold, Sterling Steel, etc",
        },
        {
          name: "Gem Stones as Primary and Sidestones",
          id: 20,
          info: "Gem Stones as Primary and Sidestones",
        },
        {
          name: "Mixed Diamonds",
          id: 21,
          info: "Mixed Diamonds",
        },
        {
          name: "Colored Diamonds + Main 4 and Colored SideStones",
          id: 22,
          info: "Used for Colored Diamonds Like Black, Blue, Pink, ect. Also Includes Sidestones"
        },
        {
          name: "Expensive Colored Diamonds",
          id: 23,
          info: "Used for Expensive Colored Diamonds Like Black, Blue, Pink, ect. Also Includes Sidestones"
        },
      ],
    };
  },
};
</script>

<style scoped>
.t-list {
  text-align: left;
}
span {
  text-decoration: underline;
}
</style>
