<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card class="light">
        <md-card-content>
          <md-field class="search-bar">
            <label>Search by Cert #, Company Name, Jewelry Name</label>
            <md-input
              type="search"
              clearable
              placeholder="Search records by Cert#"
              @input="handleSearch($event)"
            ></md-input>
          </md-field>
          <div class="filters">
            <div class="filter">
              <p>Start From:</p>
              <md-datepicker
                @input="validateDateRange('startDate')"
                v-model="filterBy.startDate"
              />
            </div>
            <div class="filter">
              <p>End To:</p>
              <md-datepicker
                @input="validateDateRange('endDate')"
                v-model="filterBy.endDate"
              />
            </div>
            <!-- <div class="filter">
              <p>Filter By Template</p>
              <v-select
                :appendToBody="true"
                :deselectFromDropdown="true"
                v-model="filterBy.templates"
                :options="templates"
                multiple
                label="name"
                :reduce="(t) => t.id"
                :closeOnSelect="false"
              >
                <template v-slot:option="option">
                  <input
                    type="checkbox"
                    :value="option.id"
                    v-model="filterBy.templates"
                  />
                  {{ option.name }}
                </template>
              </v-select>
            </div> -->
            <div class="filter">
              <p>Filter By User</p>
              <v-select
                :appendToBody="true"
                :deselectFromDropdown="true"
                v-model="filterBy.users"
                :options="users"
                :reduce="(u) => u.fullname"
                multiple
                label="fullname"
                :closeOnSelect="false"
              >
                <template v-slot:option="option">
                  <input
                    type="checkbox"
                    :value="option.fullname"
                    v-model="filterBy.users"
                  />
                  {{ option.fullname }}
                </template>
              </v-select>
            </div>

            <div
              class="filter filter-btn"
              style="
                    width: 200px;
                    bottom: 5px;
                    position: absolute;
                    right: 0;
                "
            >
              <md-button
                style="background-color: red !important;"
                class="text-btn"
                @click="clearFilters"
                >Clear Filters</md-button
              >
            </div>
          </div>
        </md-card-content>
      </md-card>

      <md-card>
        <md-card-content>
          <!-- <div class="btn-row stats_holder">
            <md-button class="md-primary md-round md-small" @click="exportCSV"
              >Export</md-button
            >
          </div> -->
          <reports :logs.sync="logs"> </reports>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import LogsDisplay from "./LogsDisplay.vue";
import { Pagination } from "@/components";
import Fuse from "fuse.js";
import { db, batch } from "@/config/firebaseInit";
import Swal from "sweetalert2";
import Popup from "@/components/InventoryPopup.vue";
import moment from "moment";

export default {
  components: {
    reports: LogsDisplay,
  },
  data() {
    return {
      logs: [],
      users: [],
      unfilteredLogs: { users_activities: [] },
      searchVal: "",
      filterBy: {
        users: [],
        startDate: "",
        endDate: "",
      },
    };
  },
  firestore() {
    return {
      unfilteredLogs: db.doc("reports/logs"),
      users: db.collection("administrator"),
    };
  },
  watch: {
    filterBy: {
      deep: true,
      async handler() {
        let unfilteredItems = this.unfilteredLogs.users_activities;
        if (
          !this.filterBy.users.length &&
          !this.filterBy.startDate &&
          !this.filterBy.endDate
        ) {
          this.logs = unfilteredItems;
          return false;
        }
        if (this.filterBy.startDate && this.filterBy.endDate) {
          let startDate = new Date(this.filterBy.startDate).getTime();
          let endDate = new Date(this.filterBy.endDate).setHours(24, 0, 0, 0);

          unfilteredItems = unfilteredItems
            .filter((i) => i.created >= startDate && i.created <= endDate)
            .sort((a, b) => (a.created > b.created ? -1 : 1));
        }

        this.logs = unfilteredItems.filter((item) => {
          let user = item.user.fullname;
          return (
            this.filterBy.users.includes(user) || !this.filterBy.users.length
          );
        });
      },
    },
    unfilteredLogs() {
      this.logs = this.unfilteredLogs.users_activities;
    },
  },
  methods: {
    clearFilters() {
      for (const key in this.filterBy) {
        if (key === "startDate" || key === "endDate") {
          this.filterBy[key] = null;
        } else {
          this.filterBy[key] = [];
        }
      }
      this.searchVal = null;
    },
    validateDateRange(key) {
      if (moment(this.filterBy.startDate).isAfter(this.filterBy.endDate)) {
        return Swal.fire(
          "Warning",
          "End date cannot be before start date.",
          "warning"
        ).then(() => {
          this.filterBy.startDate = null;
          this.filterBy.endDate = null;
        });
      }
      if (key === "startDate") {
        this.filterBy.endDate = this.filterBy.startDate;
      }
    },
    handleSearch(e) {
      const value = e;
      if (!value) {
        return (this.logs = this.unfilteredLogs.users_activities);
      }
      this.logs = this.unfilteredLogs.users_activities.filter((i) => {
        return i.product.cert.includes(value);
      });
    },
  },
};
</script>

<style></style>
