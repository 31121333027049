<template>
  <div class="ckeditor">
    <label>{{label}}</label>
    <ckeditor :editor="editor" v-model="dataToEmit" :config="editorConfig" @keyup="updateData"></ckeditor>
    <sub v-if="limitedText">{{charCount}} of {{limitedCount}} Max Characters</sub>
    <label style="color:red" v-if="feedback">{{feedback}}</label>
  </div>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  name: "CKEditor",
  components: {
    ckeditor: CKEditor.component,
  },
  props: ["label", "inputData", "limitedText", "limitedCount"],
  data() {
    return {
      editor: ClassicEditor,
      dataToEmit: null,
      editorConfig: {},
      charCount: 0,
      feedback: null,
    };
  },
  created() {
    this.dataToEmit = this.inputData;
  },
  watch: {
    dataToEmit() {
      this.charCount = this.dataToEmit
        .replace(/,/g, "")
        .replace(/<\/?[^>]+(>|$)/g, "")
        .replace(/&nbsp;/gi, "")
        .replace(/,/g, "").length;
      this.updateData();
    },
    inputData() {
      this.dataToEmit = this.inputData;
    },
  },
  methods: {
    updateData() {
      if (this.limitedText) {
        if (this.charCount <= this.limitedCount) {
          this.$emit("updateData", this.dataToEmit);
        } else {
          this.feedback = "Can't exceed the max amount of characters";
          setTimeout(() => {
            this.feedback = null;
          }, 1500);
          this.dataToEmit = this.dataToEmit
            .replace(/,/g, "")
            .replace(/<\/?[^>]+(>|$)/g, "")
            .replace(/&nbsp;/gi, "")
            .replace(/,/g, "")
            .substring(0, this.limitedCount);
        }
      } else {
        this.$emit("updateData", this.dataToEmit);
      }
    },
  },
};
</script>

<style>
.ckeditor {
  width: 500px;
}
</style>
