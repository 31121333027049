<template>
  <div class="md-layout">
    <div class="attr">
      <!-- <AttributesCard
        :attributes="jewelry_templates"
        v-on:emitAttr="updateAttr(jewelry_templates)"
      />
      <AttributesCard
        :attributes="diamond_templates"
        v-on:emitAttr="updateAttr(diamond_templates)"
      /> -->
      <AttributesCard
        :currentUser="currentUser"
        :attributes="timerTickerBeforeAd"
        v-on:emitAttr="updateAttr(timerTickerBeforeAd)"
      />
      <AttributesCard
        :currentUser="currentUser"
        :attributes="cut"
        v-on:emitAttr="updateAttr(cut)"
      />
      <AttributesCard
        :currentUser="currentUser"
        :attributes="stone_types"
        v-on:emitAttr="updateAttr(stone_types)"
      />
      <AttributesCard
        :currentUser="currentUser"
        :attributes="jewelry_type"
        v-on:emitAttr="updateAttr(jewelry_type)"
      />
      <AttributesCard
        :currentUser="currentUser"
        :attributes="colors"
        v-on:emitAttr="updateAttr(colors)"
      />
      <AttributesCard
        :currentUser="currentUser"
        :attributes="metal"
        v-on:emitAttr="updateAttr(metal)"
      />
      <AttributesCard
        :currentUser="currentUser"
        :attributes="polish"
        v-on:emitAttr="updateAttr(polish)"
      />
      <AttributesCard
        :currentUser="currentUser"
        :attributes="shapes"
        v-on:emitAttr="updateAttr(shapes)"
      />
      <AttributesCard
        :currentUser="currentUser"
        :attributes="fluorescence"
        v-on:emitAttr="updateAttr(fluorescence)"
      />
      <AttributesCard
        :currentUser="currentUser"
        :attributes="girdle"
        v-on:emitAttr="updateAttr(girdle)"
      />
      <AttributesCard
        :currentUser="currentUser"
        :attributes="clarity"
        v-on:emitAttr="updateAttr(clarity)"
      />
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import AttributesCard from "./AttributesCard";
// import DevicesForm from "./DevicesForm";
import { db, functions, auth } from "@/config/firebaseInit";

export default {
  name: "Attributes",
  components: {
    AttributesCard,
    // DevicesForm,
  },
  data() {
    return {
      active_attribute: false,
      timerTickerBeforeAd: 0,
      attributes: {},
      jewelry_type: [],
      clarity: [],
      colors: [],
      metal: [],
      polish: [],
      stone_types: [],
      cut: [],
      shapes: [],
      // jewelry_templates: [],
      // diamond_templates: [],
      fluorescence: [],
      girdle: [],
      collection: "attributes",
      currentUser: {},
    };
  },
  methods: {
    updateAttr(attribute) {
      db.collection(this.collection)
        .doc(attribute.id)
        .update({
          name: attribute.name,
          values: attribute.values,
        })
        .then(() => {
          return Swal.fire(
            "Successful Update",
            "Item has been updated succesfully",
            "success"
          );
        })
        .catch((err) => {
          console.error("Error: ", err);
        });
    },
    setAttributes() {
      // let att = this.$store.state.attributes;
      let att = this.attributes;

      for (const key in att) {
        let el = att[key];
        switch (key) {
          case "colors":
            this.colors = el;
            break;
          case "clarity":
            this.clarity = el;
            break;
          case "cut":
            this.cut = el;
            break;
          case "fluorescence":
            this.fluorescence = el;
            break;
          case "girdle":
            this.girdle = el;
            break;
          case "jewelry_type":
            this.jewelry_type = el;
            break;
          case "metal":
            this.metal = el;
            break;
          case "polish":
            this.polish = el;
            break;
          case "shapes":
            this.shapes = el;
            break;
          case "stone_types":
            this.stone_types = el;
            break;
          case "timerTickerBeforeAd":
            this.timerTickerBeforeAd = el;
            break;
          // case "diamond_templates":
          //   this.diamond_templates = el;
          //   break;
          // case "jewelry_templates":
          //   this.jewelry_templates = el;
          //   break;
        }
      }
    },
    async getAttributes() {
      let attributes = await db.collection(this.collection).get();
      attributes.forEach((d) => {
        let data = Object.assign({ id: d.id }, d.data());
        if (data.type !== "Number") {
          data.values.sort((a, b) => (a < b ? -1 : 1));
        }
        this.attributes[d.id] = data;
      });
      this.setAttributes();
    },
    getLoggedInUser() {
      this.currentUser = auth.currentUser;
    },
  },
  created() {
    this.getAttributes();
    this.getLoggedInUser();
  },
};
</script>

<style scoped>
.attr {
  margin-left: 1%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}
</style>
