<template>
  <md-card>
    <md-card-content>
      <div class="log-container">
        <div class="log" v-for="log in logs" :key="log.id">
          <h5>{{ log.created | format_date_time }}</h5>
          <p v-html="log.message"></p>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  props: {
    logs: {
      type: Array,
      default: [],
    },
  },
};
</script>

<style scoped>
.log-container {
  width: 100%;
  height: 98%;
  overflow: scroll;
}
.log {
  width: 98%;
  border-bottom: 0.8px dashed black;
}
</style>
